import { of as observableOf, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { mergeMap, merge, catchError } from 'rxjs/operators';
import { ResultSet } from '../models/result-set';

import { SocketService } from './socket.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UserManager {
  currentUser: User;

  userSubject: Subject<User>;

  constructor(private http: HttpClient, private router: Router) {}

  /**
   * Gets the current user and saves it to the currentUser object.
   *
   * If the user has already been downloaded and stored in the current user object,
   * the API is used to retrieve the current user information.
   *
   * Using a local variable  means that on permission/group change, the user only
   * need refresh the page to receive the updates.
   *
   * @returns Observable<User>
   */
  createUser(user: User) {
    const data = {};
    const keys = Object.getOwnPropertyNames(user);

    keys.forEach((key) => {
      if (key === 'corporations') {
        data['corporations'] = user.corporations.map(function (corporation) {
          return corporation.id;
        });
      } else {
        data[key] = user[key];
      }
    });
    return this.http.post<User>(environment.apiBaseUrl + 'users/', data);
  }

  getUser() {
    if (this.currentUser) {
      return observableOf(this.currentUser);
    }

    if (!this.userSubject) {
      this.userSubject = new Subject<User>();

      this.http.get<User>(environment.apiBaseUrl + 'me/').subscribe(
        (res) => {
          const user = Object.create(User.prototype);
          this.currentUser = Object.assign(user, res);
          this.userSubject.next(this.currentUser);
          this.userSubject = null;
        },
        (err) => {
          this.userSubject.error(err);
          this.userSubject = null;
        }
      );
    }

    return this.userSubject;
  }

  /**
   * Determines if the current user is logged in.
   *
   * @return Observable<boolean>
   */
  isUserLoggedIn() {
    return this.getUser().pipe(
      mergeMap((_) => {
        return observableOf(true);
      }),
      catchError((err) => {
        console.log(err);
        return observableOf(false);
      })
    );
  }

  /**
   * Determines if the current user has a permission.
   *
   * Usually used by an auth guard.
   *
   * @param permission - name of the permission
   * @returns Observable<boolean>
   */
  userHasPermission(permission) {
    return this.getUser().pipe(
      mergeMap((user) => {
        return observableOf(user.hasPermission(permission));
      })
    );
  }

  updateUser(user: User) {
    const newUser = Object.create(User.prototype);
    this.currentUser = Object.assign(newUser, user);
  }

  search(value: string, is_staff = false, order?: string) {
    let httpParams = new HttpParams().set('search', `${value}`);

    if (order) {
      httpParams = httpParams.append('order', order);
    }

    if (is_staff) {
      httpParams = httpParams.append('is_staff', 'True');
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'users/', { params: httpParams });
  }

  checkDupEmail(email) {
    const httpParams = new HttpParams().set('email', `${email}`);
    return this.http.get<any>(environment.apiBaseUrl + 'users/check_dup_email/', { params: httpParams });
  }

  sendInvitation(user) {
    return this.http.post(`${environment.apiBaseUrl}accounts/send-invitation/`, user);
  }

  checkUserCompanyAssociation(email) {
    const httpParams = new HttpParams().set('email', `${email}`);
    return this.http.get<any>(environment.apiBaseUrl + 'users/check_user_company_association/', { params: httpParams });
  }

  getSpecificGroup(group) {
    const httpParams = new HttpParams().set('group_name', `${group}`);
    return this.http.get<any>(environment.apiBaseUrl + 'groups/get_specific_group/', { params: httpParams });
  }

  getCorporationUsers(corporationId) {
    const httpParams = new HttpParams().set('corporation_id', `${corporationId}`);
    return this.http.get<any>(environment.apiBaseUrl + 'users/get_corporation_users/', { params: httpParams });
  }

  getStartupUsers(startupId) {
    const httpParams = new HttpParams().set('startup_id', `${startupId}`);
    return this.http.get<any>(environment.apiBaseUrl + 'users/get_startup_users/', { params: httpParams });
  }

  register(user) {
    return this.http.post<User>(environment.apiBaseUrl + 'register/', user);
  }

  verifyUser(user) {
    return this.http.patch<User>(environment.apiBaseUrl + 'users/' + user.id + '/verify/', {});
  }
}
