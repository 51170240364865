import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SearchExplorerMiddleware } from '../../services/search-explorer.middleware';
import { Observable } from 'rxjs';
import { RecentSearch, RecentView } from '../../models/search-explorer';

@Component({
  selector: 'playbook-search-explorer-recent',
  templateUrl: './search-explorer-recent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExplorerRecentComponent implements OnInit {
  @Output() navigation = new EventEmitter();
  @Output() search = new EventEmitter();
  recentSearch$: Observable<RecentSearch[]>;
  recentViews$: Observable<RecentView[]>;
  showRecentlyVisitClear = false;
  showRecentSearchClear = false;

  private modelIcons = {
    collection: 'icon__icons8-placeholder_thumnail_spreadsheet',
    corporation: 'icon__icons8-building_with_top_view_2',
    dealflow: 'icon__icons8-business_network',
    event: 'icon__icons8-planner',
    investor: 'icon icon__icons8-expensive_2',
    startup: 'icon icon__icons8-layers',
  };

  constructor(private searchExplorerMiddleware: SearchExplorerMiddleware) {}

  removeRecentSearch() {
    this.searchExplorerMiddleware.removeRecentSearch();
  }

  removeRecentView() {
    this.searchExplorerMiddleware.removeRecentView();
  }

  ngOnInit() {
    this.recentViews$ = this.searchExplorerMiddleware.recentViews;
    this.recentSearch$ = this.searchExplorerMiddleware.recentSearch;
  }

  getModelIcon(item: RecentView) {
    const classes = {};
    classes[this.modelIcons[item.object_type]] = true;
    return classes;
  }

  getModelLink(item: RecentView) {
    return ['/', ...this.buildUrl(item.object_type), item.source_object.id];
  }

  isDiscover(object_type: string) {
    return ['startup', 'corporation', 'investor'].includes(object_type);
  }

  buildUrl(object_type: string): string[] {
    const section = `${object_type}${object_type === 'user' || object_type === 'dealflow' ? '' : 's'}`;

    if (this.isDiscover(object_type)) {
      return ['discover', section];
    }

    return [section];
  }
}
