import { Query } from '@datorama/akita';
import { SearchExplorerState, SearchExplorerStore } from './search-explorer.store';
import { Injectable } from '@angular/core';
import { SearchItem, SearchItemBadge, SearchTab } from '../models/search-explorer';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Collection, Corporation, Dealflow, Event, Investor, Startup, User } from '@playbook/models';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SearchExplorerQuery extends Query<SearchExplorerState> {
  constructor(protected store: SearchExplorerStore) {
    super(store);
  }

  tabs(): Observable<SearchTab[]> {
    return this.select().pipe(
      switchMap((state) => {
        const keys = Object.keys(state).filter((key) => state[key].results);
        const keysWithResults = keys.filter((key: string) => state[key].results.length > 0);
        const tabs: SearchTab[] = [];
        for (const key of keysWithResults) {
          tabs.push({
            name: `${key.charAt(0).toUpperCase() + key.slice(1)}`,
            total: state[key].count,
            key,
          });
        }
        return of<SearchTab[]>(tabs);
      })
    );
  }

  search() {
    return this.select((state) => state.search);
  }

  filterEnabled() {
    return this.select((state) => state.ui.filterEnabled);
  }

  currentTab() {
    return this.select().pipe(
      switchMap((state) =>
        state.ui.selectedTab
          ? of({
              name: `${state.ui.selectedTab.charAt(0).toUpperCase() + state.ui.selectedTab.slice(1)}`,
              total: state[state.ui.selectedTab].count,
              key: state.ui.selectedTab,
            })
          : of(null)
      )
    );
  }

  selectLoadingMore() {
    return this.select((state) => state.ui.loadingMore);
  }

  results(): Observable<SearchItem[]> {
    return this.select().pipe(
      switchMap((state: SearchExplorerState) => {
        if (!state.ui.selectedTab && !state.ui.query) {
          return of(null);
        }
        let results: SearchItem[] | null = null;
        switch (state.ui.selectedTab) {
          case 'dealflows':
            results = [...state.dealflows.results.map(this.dealflowTransformer)];
            break;

          case 'startups':
            results = [...state.startups.results.map(this.startupTransformer)];
            break;

          case 'users':
            results = [...state.users.results.map(this.userTransformer)];
            break;

          case 'corporations':
            results = [...state.corporations.results.map(this.corporationTransformer)];
            break;

          case 'vcs':
            results = [...state.vcs.results.map(this.vcTransformer)];
            break;

          case 'collections':
            results = [...state.collections.results.map(this.collectionTransformer)];
            break;

          case 'events':
            results = [...state.events.results.map(this.eventTransformer)];
            break;

          case 'investors':
            results = [...state.investors.results.map(this.investorTransformer)];
            break;

          default:
            break;
        }
        return of(results || []);
      })
    );
  }

  recentViews() {
    return this.select((state) => state.historial.recent_views);
  }

  recentSearch() {
    return this.select((state) => state.historial.recent_searches);
  }

  userTransformer = (user: User) => ({
    id: user.id,
    avatar: (user.avatar && (user.avatar.thumb || user.avatar.url)) || '/assets/images/user_placeholder.jpg',
    title: `${user.first_name} ${user.last_name}`,
    description: user.position || '',
    url: `/user/${user.id}`,
  });

  startupTransformer = (startup: Startup) => ({
    id: startup.id,
    avatar: (startup.logo && (startup.logo.thumb || startup.logo.url)) || '/assets/playbook-icon.png',
    title: startup.company_name,
    description: startup.one_liner || startup.description,
    badges: this.getStartupTabs(startup),
    url: `/discover/startups/${startup.id}`,
    extras: {
      hype_score: startup.hype_score,
      average_feedback: startup.average_feedback ? startup.average_feedback : -1,
      stage: startup.stage && startup.stage.stage_name ? startup.stage.stage_name : 'No stage set.',
      money_raised: startup.money_raised_usd,
    },
  });

  dealflowTransformer = (dealflow: Dealflow) => ({
    id: dealflow.id,
    title: dealflow.corporation_name,
    description: `${new DatePipe('en-US').transform(dealflow.start_date, 'longDate')} ${
      dealflow.dealflow_days > 1 ? dealflow.dealflow_days : ''
    }`,
    url: `/dealflow/${dealflow.id}`,
  });

  corporationTransformer = (corporation: Corporation) => ({
    id: corporation.id,
    avatar: corporation.logo && (corporation.logo.thumb || corporation.logo.url),
    title: corporation.corporation_name,
    description: corporation.description,
    badges: this.getCorporationTabs(corporation),
    url: `/discover/corporations/${corporation.id}`,
  });

  vcTransformer = (corporation: Corporation) => ({
    id: corporation.id,
    avatar: corporation.logo && (corporation.logo.thumb || corporation.logo.url),
    title: corporation.corporation_name,
    description: corporation.description,
    badges: this.getCorporationTabs(corporation),
    url: `/discover/vcs/${corporation.id}`,
  });

  collectionTransformer = (collection: Collection) => ({
    id: collection.id,
    title: collection.collection_name,
    description: collection.description,
    badges: this.getCollectionTabs(collection),
    url: `/collections/${collection.id}`,
  });

  eventTransformer = (event: Event): SearchItem => ({
    id: event.id,
    title: event.event_name,
    description: event.description,
    url: `/events/${event.id}`,
  });

  investorTransformer = (investor: Investor): SearchItem => ({
    id: investor.id,
    avatar: investor.logo && (investor.logo.thumb || investor.logo.url),
    title: investor.investor_name,
    description: investor.description,
    url: `/discover/investors/${investor.id}`,
  });

  private getUserDescription(user: User) {
    if (user.is_staff) {
      return 'Staff member';
    } else if (user.startups && user.startups.length > 0) {
      return user.startups[0].company_name;
    } else if (user.corporations && user.corporations.length > 0) {
      return user.corporations[0].corporation_name;
    } else {
      return '';
    }
  }

  private getStartupTabs(startup: Startup) {
    const tabs: SearchItemBadge[] = [];

    if (startup.hq_city || startup.hq_country) {
      tabs.push({
        description: `${startup.hq_city ? startup.hq_city + ', ' : ''} ${startup.hq_country || ''}`.trim(),
      });
    }

    if (startup.verticals.length > 0) {
      const vertical = startup.verticals[0];
      tabs.push({
        color: vertical.color,
        description: vertical.vertical_name,
      });
    }
    return tabs;
  }

  private getCorporationTabs(corporation: Corporation) {
    const tabs: SearchItemBadge[] = [];

    if (corporation.verticals.length > 0) {
      const vertical = corporation.verticals[0];
      tabs.push({
        color: vertical.color,
        description: vertical.vertical_name,
      });
    }
    return tabs;
  }

  private getCollectionTabs(collection: Collection) {
    const tabs = [];
    if (collection.tags.length > 0) {
      const tag = collection.tags[0];
      tabs.push({
        description: tag.tag_name,
      });
    }
    return tabs;
  }
}
