import {
  Component,
  Input,
  HostListener,
  ViewChild,
  AfterContentInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'playbook-single-object-chooser',
  templateUrl: './single-object-chooser.component.html',
  styleUrls: ['./single-object-chooser.component.scss'],
})
export class SingleObjectChooserComponent implements AfterContentInit, OnChanges {
  private stopProp = false;
  showResults = false;

  private set filteredObjects(elements: any[]) {
    this.filteredObjectsSubject.next(elements);
  }

  get filteredObjects$(): Observable<any[]> {
    return this.filteredObjectsSubject.asObservable();
  }

  filteredObjectsSubject = new BehaviorSubject<any[]>([]);
  searchTimeout = null;
  filtering = false;

  @Input() objects: any[] = [];
  @Input() displayValue = 'name';
  @Input() selectedObject: any = null;
  @Input() error = false;
  @Input() searchable = false;
  @Input() showSelected = true;
  @Input() disabled = false;
  @Input() displayObject = false;
  @Input() disableReset = false;
  @Input() placeholder: any;
  @ViewChild('objectSearch', { static: true }) objectSearch;
  @Output() search = new EventEmitter<any>();
  @Output() objectSelected = new EventEmitter<any>();
  @Output() addNewUser = new EventEmitter<any>();
  @Input() addNewPerson = false;
  @Input() searchSelector = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objects && !!changes.objects.currentValue) {
      if (!this.filtering) {
        this.filteredObjects = changes.objects.currentValue;
      } else {
        this.filterObjects(this.objectSearch.nativeElement.value);
      }
    }

    if (!!changes.selectedObject) {
      if (
        changes.selectedObject.previousValue !== undefined &&
        changes.selectedObject.previousValue !== '' &&
        changes.selectedObject.previousValue !== changes.selectedObject.currentValue
      ) {
        if (changes.selectedObject.currentValue[this.displayValue]) {
          this.objectSearch.nativeElement.value = changes.selectedObject.currentValue[this.displayValue];
        }
      }
    }
  }

  ngAfterContentInit() {
    if (!!this.selectedObject) {
      if (this.displayValue === 'user') {
        this.objectSearch.nativeElement.value =
          this.selectedObject.first_name + ' ' + this.selectedObject.last_name + ' - ' + this.selectedObject.email;
      } else {
        this.objectSearch.nativeElement.value = this.selectedObject[this.displayValue];
      }
    }

    this.filterObjects('');

    if (!!this.objects) {
      this.filteredObjects = this.objects;
    }
  }

  resetSearch() {
    if (!this.disableReset) {
      this.objectSearch.nativeElement.value = '';
    }

    this.objectSearch.nativeElement.value = '';
    this.filterObjects('');

    if (!this.disableReset) {
      this.objectSearch.nativeElement.value = '';
    }
  }

  filterObjects(value) {
    this.filtering = value.length > 0;

    if (this.searchable) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.search.emit(value);
        this.searchTimeout = null;
      }, 250);
    }

    if (this.objects) {
      this.filteredObjects = this.objects.filter((filter) => {
        if (value === '') {
          return true;
        }
        if (this.displayValue === 'user') {
          const filterlist = filter['first_name'] + ' ' + filter['last_name'] + ' ' + filter['email'];
          return filterlist.toLowerCase().indexOf(value.toLowerCase()) > -1;
        } else {
          return (filter[this.displayValue] || '').toLowerCase().indexOf(value.toLowerCase()) > -1;
        }
      });
    }
  }

  selectObject(obj) {
    this.selectedObject = obj;
    if (this.showSelected) {
      if (this.displayValue === 'user') {
        this.objectSearch.nativeElement.value = obj.first_name + ' ' + obj.last_name + ' - ' + obj.email;
      } else {
        this.objectSearch.nativeElement.value = obj[this.displayValue];
      }
    } else {
      this.resetSearch();
    }
    this.objectSelected.emit(obj);
    this.showResults = false;
  }

  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }

    this.showResults = false;
  }

  @HostListener('click', ['$event'])
  clicked(event) {
    this.stopProp = event.currentTarget.contains(event.srcElement);
  }

  addNew() {
    this.addNewUser.emit();
  }
}
