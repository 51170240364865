import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { PipeModule } from '../../pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { NotificationBodyComponent } from './components/notification-body/notification-body.component';
import { NotificationDropdownComponent } from './components/notification-dropdown/notification-dropdown.component';
import { NotificationService } from './notifications.service';
import { NotificationResolver } from './resolvers/notification.resolver';
import { CoreModule } from '../core/core.module';
import { NotificationSkeletonComponent } from './components/notification-skeleton/notification-skeleton.component';

@NgModule({
  declarations: [NotificationDropdownComponent, NotificationBodyComponent, NotificationSkeletonComponent],
  imports: [CommonModule, SharedModule, RouterModule, PipeModule, MomentModule, CoreModule],
  exports: [NotificationDropdownComponent],
  providers: [NotificationService, NotificationResolver],
})
export class NotificationsModule {}
