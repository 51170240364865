import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material';
import { GestureConfig } from '@angular/material/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ability, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { CalendarModule, CalendarUtils, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgsRevealModule } from 'ng-scrollreveal';
import { DragulaModule } from 'ng2-dragula';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoggedInGuard } from './guards/logged-in.guard';
import { NotLoggedInGuard } from './guards/not-logged-in.guard';
import { HttpErrorsInterceptorProvider } from './interceptors/http-errors.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptors';
import { CacheModule } from './modules/cache/cache.module';
import { CacheService } from './modules/cache/services/cache.service';
import { GlobalSearchModule } from './modules/global-search/global-search.module';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { AppInitializerService } from './services/app-initializer.service';
import { BodyService } from './services/body.service';
import { IntroductionModalService } from './services/introduction-modal.service';
import { GlobalTitleService } from './services/title.service';
import { TokenService } from './services/token.service';
import { UserManager } from './services/user-mananger.service';
import { ToasterModule } from './shared/modules/toaster/toaster.module';
import { SharedModule } from './shared/shared.module';
import { PlatformModule } from '@angular/cdk/platform';
import { RelationshipSetupModule } from '@playbook/modules/relationship-tracking/modules/relationship-setup/relationship-setup.module';
import { SheetModuleConfig } from '@playbook/models/sheet-module-config';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { CookieService } from 'ngx-cookie-service';

export function getUserDataIfLoggedIn(appInitializerService: AppInitializerService) {
  return () => appInitializerService.loadUser();
}

export const SHEET_MODULE_CONFIG = new InjectionToken<SheetModuleConfig>('sheet_module_config');

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    CacheModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    LoadingBarHttpClientModule,
    LoadingBarModule.forRoot(),
    DragulaModule.forRoot(),
    GlobalSearchModule,
    NotificationsModule,
    ToasterModule.forRoot(),
    NgxMasonryModule,
    NgsRevealModule.forRoot(),
    NgxSpinnerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    MarkdownModule.forRoot(),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    LayoutModule,
    AbilityModule,
    PlatformModule,
    RelationshipSetupModule,
  ],
  providers: [
    NotLoggedInGuard,
    LoggedInGuard,
    CalendarUtils,
    UserManager,
    TokenService,
    CacheService,
    GlobalTitleService,
    BodyService,
    IntroductionModalService,
    HttpErrorsInterceptorProvider,
    TokenService,
    AppInitializerService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: getUserDataIfLoggedIn,
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'pb-dialog-panel',
        backdropClass: 'pb-dialog-backdrop',
        hasBackdrop: true,
      } as MatDialogConfig,
    },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    {
      provide: SHEET_MODULE_CONFIG,
      useValue: { title: 'Smart Sheets', creation: true, path: 'sheets' },
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
