import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TokenService {
  /**
   * Access token refresh observable. Private and initialised by constructor.
   *
   * @private
   * @type {Subject<string>}
   * @memberof TokenService
   */
  private _refreshSubject: Subject<string> = new Subject();

  /**
   * Returns the read-only refresh obvserver. Subscribe to get new access token.
   *
   * @returns Observable<string>
   * @memberof TokenService
   */
  accessToken() {
    return this._refreshSubject;
  }

  /**
   * Call when the token is refreshed. Notifies subscribers of the new token.
   *
   * @param {string} accessToken
   * @memberof TokenService
   */
  refreshed(accessToken: string) {
    this._refreshSubject.next(accessToken);
  }
}
