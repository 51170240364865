import { NgModule } from '@angular/core';
import { MaxWordsPipe } from './max-words.pipe';
import { VerticalColorTagsPipe } from './vertical-color-tags';
import { LuminousPipe } from './luminous';
import { PermissionNamePipe } from './permission-name.pipe';
import { DarkenLightenPipe } from './darken';
import { DealflowStatusPipe } from './dealflow-status';
import { OperationsCountPipe } from './operations-count';
import { BizCountPipe } from './biz-count';
import { VenturesCountPipe } from './ventures-count';
import { RoomCheckPipe } from './room-check';
import { LinkCheckPipe } from './virtual-link';
import { SafePipe } from './safe';
import { Nl2brPipe } from './nl2br';
import { FilesizePipe } from './filesize';
import { HrefPipe } from './href.pipe';
import { SimpleHrefPipe } from './simple-href.pipe';
import { DomainPipe } from './domain.pipe';
import { AttendeeNamePipe } from './attendee-name.pipe';
import { SentReceivedPipe } from './sent-received.pipe';
import { EmailNamePipe } from './email-name.pipe';
import { FullNamePipe } from './full-name.pipe';
import { CollectionActivityTypePipe } from './collection-activity-type.pipe';
import { ICAStatusPipe } from './ica-status';
import { ICARoundPipe } from './ica-round';
import { ICAShareTypePipe } from './ica-share-type';
import { NumberSuffixPipe } from '@playbook/pipes/number-suffix.pipe';

@NgModule({
  imports: [],
  declarations: [
    MaxWordsPipe,
    VerticalColorTagsPipe,
    LuminousPipe,
    PermissionNamePipe,
    DarkenLightenPipe,
    DealflowStatusPipe,
    RoomCheckPipe,
    LinkCheckPipe,
    OperationsCountPipe,
    BizCountPipe,
    VenturesCountPipe,
    SafePipe,
    Nl2brPipe,
    FilesizePipe,
    HrefPipe,
    SimpleHrefPipe,
    DomainPipe,
    AttendeeNamePipe,
    SentReceivedPipe,
    EmailNamePipe,
    FullNamePipe,
    CollectionActivityTypePipe,
    ICAStatusPipe,
    ICARoundPipe,
    ICAShareTypePipe,
    NumberSuffixPipe,
  ],
  exports: [
    MaxWordsPipe,
    VerticalColorTagsPipe,
    LuminousPipe,
    PermissionNamePipe,
    DarkenLightenPipe,
    DealflowStatusPipe,
    RoomCheckPipe,
    LinkCheckPipe,
    OperationsCountPipe,
    BizCountPipe,
    VenturesCountPipe,
    SafePipe,
    Nl2brPipe,
    FilesizePipe,
    HrefPipe,
    SimpleHrefPipe,
    DomainPipe,
    AttendeeNamePipe,
    SentReceivedPipe,
    EmailNamePipe,
    FullNamePipe,
    CollectionActivityTypePipe,
    ICAStatusPipe,
    ICARoundPipe,
    ICAShareTypePipe,
    NumberSuffixPipe,
  ],
})
export class PipeModule {
  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
