import { Injectable } from '@angular/core';
import { Stage } from '@playbook/models';
import { map } from 'rxjs/operators';
import { StageQuery } from '../state/stage.state';
import { StageRemote } from './stage.remote';
import { StageStore } from '../state/stage.store';

@Injectable({ providedIn: 'root' })
export class StageService {
  constructor(private stageRemote: StageRemote, private stageStore: StageStore, private stageQuery: StageQuery) {}

  fetchAll() {
    this.stageRemote
      .fetch()
      .pipe(map((data) => data.results))
      .subscribe((stages) => this.stageStore.set(stages));
  }

  getStages() {
    return this.stageQuery.selectAll();
  }
  getStage(id: number) {
    return this.stageQuery.getEntity(id);
  }

  get stages() {
    return this.stageQuery.getAll();
  }

  search(stageName: string) {
    return this.stageQuery.selectAll({
      filterBy: (entity) => entity.stage_name.toLowerCase().includes(stageName.toLowerCase()),
    });
  }
}
