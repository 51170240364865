import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule, MatSnackBarModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AbilityModule } from '@casl/angular';
import { DirectivesModule } from 'app/directives/directives.module';
import { SlideToggleModule } from '../../components/slide-toggle/slide-toggle.module';
import { CollectionDetailComponent } from '../collections/components/collection-detail/collection-detail.component';
import { SelectModule } from '../select/select.module';

@NgModule({
  declarations: [],
  imports: [OverlayModule, DirectivesModule, MatDialogModule],
  exports: [
    SlideToggleModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    OverlayModule,
    DirectivesModule,
    MatMenuModule,
    MatSidenavModule,
    PlatformModule,
    OverlayModule,
    MatTooltipModule,
    AbilityModule,
    TextFieldModule,
    SelectModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
  ],
})
export class CoreModule {}
