import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user';

@Component({
  selector: 'playbook-user-follow-card',
  templateUrl: './user-follow-card.component.html',
  styleUrls: ['./user-follow-card.component.scss'],
})
export class UserFollowCardComponent {
  @Input() user: User;
  @Output() userFollowed = new EventEmitter<User>();

  constructor(private userService: UserService) {}

  followUser() {
    this.userService.followUser(this.user.id).subscribe((res) => {
      this.user.follows = true;
      this.userFollowed.emit(this.user);
    });
  }

  unfollowUser() {
    this.userService.unfollowUser(this.user.id).subscribe((res) => {
      this.user.follows = false;
      this.userFollowed.emit(this.user);
    });
  }

  toggleFollow() {
    if (this.user.follows) {
      this.unfollowUser();
    } else if (!this.user.follows) {
      this.followUser();
    }
  }
}
