export enum SocketMessageTypes {
  Notification = 'N',
  ConversationMessage = 'C',
  Thread = 'T',
  Refresh = 'R',
  Cache = 'K',
  Remove = 'D',
  CollectionStartupCreated = 'CS',
  CollectionStartupRemoved = 'CSR',
  SMART_SHEET_SELECTED_CELL = 'SMART_SHEET_SELECTED_CELL',
  SMART_SHEET_UPDATED_CELL = 'SMART_SHEET_UPDATED_CELL',
  SMART_SHEET_USER_DISCONNECTED = 'SMART_SHEET_USER_DISCONNECTED',
  SMART_SHEET_USER_CONNECTED = 'SMART_SHEET_USER_CONNECTED',
  SMART_SHEET_USER_ACK = 'SMART_SHEET_USER_ACK',
}

export class SocketMessage {
  type: SocketMessageTypes;
  object: any;
  sender?: number;
}
