import { Injectable } from '@angular/core';
import { Tag } from '@playbook/models';
import { map } from 'rxjs/operators';
import { TagsQuery } from '../state/tag.query';
import { TagsStore } from '../state/tag.store';
import { TagsRemote } from './tag.remote';
import { Order } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class TagsService {
  constructor(private tagsRemote: TagsRemote, private tagsStore: TagsStore, private tagsQuery: TagsQuery) {}

  fetchAll() {
    this.tagsRemote
      .fetch()
      .pipe(map((data) => data.results))
      .subscribe((tags) => this.tagsStore.set(tags));
  }

  getTags() {
    return this.tagsQuery.selectAll();
  }

  selectTag(id: number) {
    return this.tagsQuery.selectEntity(id);
  }

  get tags(): Tag[] {
    return this.tagsQuery.getAll();
  }

  getTagByName(tagName: string) {
    return this.tags.find((tag) => tag.tag_name.toLowerCase().includes(tagName));
  }

  search(tag: string, order = Order.ASC) {
    return this.tagsQuery.selectAll({
      filterBy: (entity) => entity.tag_name.toLowerCase().includes(tag.toLowerCase()),
      sortBy: 'tag_name',
      sortByOrder: order,
    });
  }

  addOrUpdateTag(tag: Tag) {
    this.tagsStore.upsert(tag.id, tag);
  }

  deleteTag(tagId: number) {
    this.tagsStore.remove(tagId);
  }

  getTagById(tagId: number) {
    return this.tags.find((tag) => tag.id === tagId);
  }
}
