import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[playbookInfiniteScroll]',
})
export class InfiniteScrollDirective implements AfterViewInit, OnDestroy {
  @Input() scrollThreshold = 85;
  @Input() disabled = false;
  @Output() scrollThresholdReached = new EventEmitter<boolean>();
  mainElement: HTMLElement;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.mainElement = document.querySelector('main');
    this.mainElement.addEventListener('scroll', (evt: MouseEvent) => {
      this.didScroll(evt);
    });
  }

  ngOnDestroy() {
    this.mainElement.removeEventListener('scroll', null);
  }

  didScroll(evt: MouseEvent) {
    if (this.disabled === true) {
      return;
    }

    const elHeightWithOffset = this.el.nativeElement.offsetTop + this.el.nativeElement.clientHeight;
    const threshold = (this.scrollThreshold / 100) * elHeightWithOffset;
    const scrollTotal = (evt.target as HTMLElement).scrollTop + (evt.target as HTMLElement).clientHeight + 64;
    if (scrollTotal < threshold) {
      return;
    }
    this.scrollThresholdReached.emit(true);
  }
}
