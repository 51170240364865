import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RelationshipSetupComponent } from '@playbook/modules/relationship-tracking/modules/relationship-setup/components/relationship-setup/relationship-setup.component';
import { Corporation } from '@playbook/models';

@Injectable({ providedIn: 'root' })
export class RelationshipSetupService {
  constructor(private dialog: MatDialog) {}

  open(corporation?: Corporation): MatDialogRef<RelationshipSetupComponent> {
    return this.dialog.open(RelationshipSetupComponent, {
      closeOnNavigation: true,
      height: '100%',
      maxHeight: '100%',
      width: '85%',
      maxWidth: '900px',
      minHeight: '50px',
      panelClass: 'pb-dialog-panel',
      backdropClass: 'pb-dialog-backdrop',
      position: {
        top: '50px',
      },
      data: corporation,
    });
  }
}
