import { BehaviorSubject } from 'rxjs';

export class ExtendedInfoService {
  private data = new BehaviorSubject(null);

  listen() {
    return this.data.asObservable();
  }

  update(instance) {
    this.data.next(instance);
  }
}
