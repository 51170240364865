import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'playbook-score-slider',
  styleUrls: ['./score-slider.component.scss'],
  templateUrl: 'score-slider.component.html',
})
export class ScoreSliderComponent {
  @Input() value: number;
}
