import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResultSet } from '@playbook/models';

@Injectable()
export class GeoService {
  constructor(private http: HttpClient) {}

  search(search: string) {
    let httpParams = new HttpParams().set('search', search);
    httpParams = httpParams.append('limit', '20');

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'geo/', { params: httpParams });
  }

  getAddress(search: string) {
    search = search.replace(' ', '+');
    const httpParams = new HttpParams().set('address', search);

    return this.http.get<any>(environment.apiBaseUrl + 'geo/autocomplete/', { params: httpParams });
  }
}
