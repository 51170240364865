import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchItem, SearchTab } from '../../models/search-explorer';

@Component({
  selector: 'playbook-search-explorer-results-item',
  templateUrl: './search-explorer-results-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExplorerResultsItemComponent {
  @Input() item: SearchItem;
  @Input() minHeight: number;
  @Input() currentTab: SearchTab;
  @Input() singleMode = false;
  @Output() navigation = new EventEmitter();

  linkClicked() {
    this.navigation.emit();
  }

  constructor(private host: ElementRef<SearchExplorerResultsItemComponent>) {}
}
