import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { OverlayModule } from '@angular/cdk/overlay';
import { PipeModule } from '@playbook/pipes/pipes.module';
import { RelationshipSetupComponent } from './components/relationship-setup/relationship-setup.component';
import { RelationshipSetupRoutingModule } from './relationship-setup-routing.module';
import { SharedModule } from '@playbook/shared/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { RelationshipSetupService } from './services/relationship-setup.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CoreModule } from '@playbook/modules/core/core.module';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    RelationshipSetupRoutingModule,
    OverlayModule,
    PipeModule,
    SharedModule,
    MatStepperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CoreModule,
  ],
  declarations: [RelationshipSetupComponent],
  exports: [RelationshipSetupComponent],
  providers: [RelationshipSetupService, { provide: MAT_DIALOG_DATA, useValue: null }],
})
export class RelationshipSetupModule {}
