import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'playbook-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() iconSize: string = '28px';
  @Input() containerSize: string = '48px';
  @Input() borderRadius: string = '9px';
  @Input() backgroundColor: string;
  @Input() clickable: boolean;

  @Output() click = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  iconClick(): void {
    this.click.emit();
  }
}
