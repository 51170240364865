import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '@playbook/models';
import { UserService } from 'app/modules/user/services/user.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class NotLoggedInGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.currentUser$.pipe(
      switchMap((user: User) => {
        if (user) {
          return of(true);
        }
        this.router.navigate(['auth'], { queryParams: { state: state.url !== '/' ? btoa(state.url) : null } });
        return of(false);
      })
    );
  }
}
