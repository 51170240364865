import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatChipInputEvent,
} from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'playbook-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteInputComponent implements OnInit, ControlValueAccessor {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() options: string[] = [];
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required = false;
  formControl: FormControl = new FormControl([]);
  inputControl: FormControl = new FormControl('');

  optionCtrl = new FormControl();
  filteredOptions$: Observable<string[]>;
  @ViewChild('optionInput', { static: false }) optionInput: ElementRef<HTMLInputElement>;
  @ViewChild('optionAutocomplete', { static: false }) optionAutocomplete: MatAutocomplete;
  @ViewChild('optionAutoCompleteTrigger', { static: false }) optionAutocompleteTrigger: MatAutocompleteTrigger;

  private onChange: Function;
  private onTouched: Function;
  private disabled: boolean;

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  ngOnInit() {
    this.filteredOptions$ = this.inputControl.valueChanges.pipe(
      startWith(null),
      map((option: string | null) => this.filterOptions(option || ''))
    );

    this.formControl.valueChanges.subscribe((val) => {
      this.resetInput();
      this.filterOptions(this.inputControl.value);
      this.onChange(val);
    });
  }

  writeValue(obj: string[]): void {
    this.formControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addOption(event: MatChipInputEvent): void {
    if (!event || !event.value) {
      return;
    }

    const input = event.input;
    const option = event.value.trim();

    if (!this.formControl.value.some((v: string) => v === option) && this.options.some((v) => v === option)) {
      this.formControl.value.push(option.trim());
      this.formControl.updateValueAndValidity();
      if (input) {
        input.value = '';
      }
    }
  }

  selectedOption(event: MatAutocompleteSelectedEvent): void {
    this.formControl.value.push(event.option.viewValue);
    this.formControl.updateValueAndValidity();
    setTimeout(() => {
      this.optionAutocompleteTrigger.openPanel();
    }, 100);
  }

  private resetInput(): void {
    if (this.optionInput) {
      this.optionInput.nativeElement.value = '';
    }
    this.inputControl.setValue(null);
  }

  removeOption(option: string): void {
    this.formControl.patchValue(this.formControl.value.filter((v: string) => v != option));
  }

  private filterOptions(value: string): string[] {
    if (!value) {
      value = '';
    }
    const filterValue = value.toLowerCase();

    return this.options
      .filter((v) => !!v && v.toLowerCase().indexOf(filterValue) === 0)
      .filter((v) => !(this.formControl.value && this.formControl.value.some((option) => option === v)));
  }
}
