import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TagsService } from 'app/modules/tags/services/tag.service';
import { VerticalService } from 'app/modules/verticals/services/vertical.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { SearchExplorerMiddleware } from '../../services/search-explorer.middleware';
import { FormControl } from '@angular/forms';
import { map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { slideMotion } from '../../../core/animations/animation-consts';
import { CountryService } from '../../../country/services/country.service';
import { StageService } from '../../../stages/services/stage.service';
import { endOfQuarter, endOfYear, startOfQuarter, startOfYear, subMonths } from 'date-fns';

interface Filter {
  value: number | string | object;
  name: string;
  color?: string;
  key: string;
}

interface ToggleFilter {
  key: string;
  description: string;
  value: boolean;
  location: 'body' | 'filters';
}

interface SelectFilter<T = Filter[]> {
  key: string;
  title: string;
  listOfItems: T;
  mode: 'single' | 'multiple';
  condition: 'OR' | 'AND';
  allowToChangeCondition: boolean;
  select_key?: string;
}

@Component({
  selector: 'playbook-search-explorer-filters',
  templateUrl: './search-explorer-filters.component.html',
  styles: [
    `
      :host {
        width: 220px;
      }

      input {
        margin-bottom: 0;
        border-radius: 0;
        padding: 4px 12px;
        box-shadow: none;
        background-color: #f6f5f4;
        font-size: 13px;
        height: 42px;
        border: 0;
      }

      input:focus {
        border: 0;
        box-shadow: none;
        background-color: #f6f5f4;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideMotion],
})
export class SearchExplorerFiltersComponent implements OnInit, AfterViewInit {
  @ViewChild(CdkConnectedOverlay, { static: true }) cdkConnectedOverlay: CdkConnectedOverlay;
  @ViewChild('filterEl', { static: false }) filterRef: ElementRef<HTMLInputElement>;
  filterSelectorOpened = false;
  dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';
  origin: CdkOverlayOrigin;
  listOfItem$ = new BehaviorSubject<Filter[]>(null);
  listOfItemFiltered$: Observable<Filter[]>;
  listOfItemSelected$ = new BehaviorSubject<Filter[]>([]);
  searchPlaceHolder$: Observable<string>;
  filterControl = new FormControl('');
  filterSelectorHeader: string | null = null;
  toggleFilters = new Map<string, ToggleFilter>();
  selectFilters = new Map<string, SelectFilter>();
  listOfItemSelectedMap = new Map<string, BehaviorSubject<any[]>>();
  currentSelectFilter$ = new BehaviorSubject<SelectFilter>(null);

  constructor(
    private cdr: ChangeDetectorRef,
    private tagsService: TagsService,
    private verticalService: VerticalService,
    private countryService: CountryService,
    private stageService: StageService,
    private searchExplorerMiddleware: SearchExplorerMiddleware
  ) {}

  openSelector(origin: CdkOverlayOrigin, selectFilter: SelectFilter) {
    this.origin = origin;
    this.currentSelectFilter$.next(selectFilter);
    this.listOfItem$.next(selectFilter.listOfItems);
    this.searchPlaceHolder$ = of(`Search ${selectFilter.title.toLowerCase()}`);
    const listOfItemSelected = this.listOfItemSelectedMap.get(selectFilter.key).getValue();
    this.listOfItemSelected$.next(listOfItemSelected);
    this.filterSelectorHeader = `Select ${selectFilter.select_key || selectFilter.key}`;
    this.setOpenState(true);
    if (selectFilter.mode === 'multiple') {
      setTimeout(() => {
        this.filterRef.nativeElement.focus();
      }, 100);
    }
  }

  setOpenState(value: boolean) {
    if (this.filterSelectorOpened !== value) {
      this.filterSelectorOpened = value;
      this.cdr.markForCheck();
      this.updateCdkConnectedOverlayStatus();
      this.filterControl.setValue('');
    }
  }

  onPositionChange(position: ConnectedOverlayPositionChange) {
    this.dropDownPosition = position.connectionPair.originY;
  }

  onSlideToggleChange() {
    this.refreshFilters();
  }

  refreshFilters() {
    const filters = [];

    for (const [key, value] of this.listOfItemSelectedMap) {
      const values = value.getValue().map((f) => f.value);
      if (values.length > 0) {
        const selectFilter = this.selectFilters.get(key);
        if (selectFilter.mode === 'single') {
          filters.push({
            term: key,
            value: values[0],
            type: 'range',
          });
        } else {
          filters.push({
            term: key,
            values,
            condition: selectFilter.condition,
          });
        }
      }
    }

    this.searchExplorerMiddleware.store.setLoading(true);

    this.searchExplorerMiddleware.store.updateSearch({
      payload: {
        ...(this.toggleFilters.get('only_on_title') && {
          only_on_title: this.toggleFilters.get('only_on_title').value,
        }),
        filters: [
          ...filters,
          ...Array.from(this.toggleFilters.values())
            .filter((toggleFilter) => toggleFilter.location === 'filters' && toggleFilter.value)
            .map((toggleFilter) => ({
              term: toggleFilter.key,
              values: toggleFilter.value,
              condition: 'OR',
            })),
        ],
      },
    });
  }

  ngOnInit(): void {
    this.onFilterControlChange();
    this.initializeFilters();
  }

  updateFilters(item: Filter) {
    const currentSelectorFilter = this.currentSelectFilter$.getValue();
    if (currentSelectorFilter.mode === 'multiple') {
      const items = this.listOfItemSelectedMap.get(item.key).getValue();
      this.listOfItemSelectedMap.get(item.key).next([...items, item]);
    } else {
      this.listOfItemSelectedMap.get(item.key).next([item]);
    }

    this.setOpenState(false);
    this.refreshFilters();
  }

  updateCondition(selectKey: string) {
    const selectFilter = this.selectFilters.get(selectKey);
    this.selectFilters.set(selectKey, {
      ...selectFilter,
      condition: selectFilter.condition === 'OR' ? 'AND' : 'OR',
    });
    this.refreshFilters();
  }

  removeFilter(item: Filter) {
    const items = this.listOfItemSelectedMap.get(item.key).getValue();
    this.listOfItemSelectedMap.set(item.key, new BehaviorSubject(items.filter((it) => it.value !== item.value)));
    this.refreshFilters();
  }

  canShowSelecFilter(key: string) {
    return this.listOfItemSelectedMap.get(key).getValue().length === 0;
  }

  get canShowSelectFilters() {
    return Array.from(this.listOfItemSelectedMap.values()).filter((v) => v.getValue().length === 0).length > 0;
  }

  ngAfterViewInit(): void {
    this.updateCdkConnectedOverlayStatus();
  }

  private updateCdkConnectedOverlayStatus() {
    if (this.cdkConnectedOverlay.overlayRef) {
      this.cdkConnectedOverlay.overlayRef.updatePosition();
    }
  }

  private onFilterControlChange() {
    this.listOfItemFiltered$ = combineLatest([
      this.filterControl.valueChanges.pipe(startWith('')),
      this.listOfItem$,
    ]).pipe(
      withLatestFrom(this.listOfItemSelected$),
      map(([[query, listOfItem], listOfItemSelected]) => ({
        query,
        listOfItem,
        listOfItemIdsSelected: listOfItemSelected.map((item) => item.value),
      })),
      switchMap(({ query, listOfItem, listOfItemIdsSelected }) => {
        listOfItem = listOfItem.filter(
          (item) =>
            item.name.toLowerCase().includes(query.toLowerCase()) &&
            !listOfItemIdsSelected.some((id) => id === item.value)
        );
        return of(listOfItem);
      })
    );
  }

  private initializeFilters() {
    const { models } = this.searchExplorerMiddleware.store.getSearch();

    this.selectFilters.set('tag', {
      key: 'tag',
      title: 'Tag',
      listOfItems: this.tagsService.tags.map((tag) => ({
        value: tag.id,
        name: tag.tag_name,
        color: (tag.vertical && tag.vertical.color) || '#6c6cd7',
        key: 'tag',
      })),
      mode: 'multiple',
      condition: 'OR',
      allowToChangeCondition: true,
    });

    this.selectFilters.set('vertical', {
      key: 'vertical',
      title: 'Vertical',
      listOfItems: this.verticalService.verticals.map((vertical) => ({
        value: vertical.id,
        name: vertical.vertical_name,
        color: vertical.color || '#6c6cd7',
        key: 'vertical',
      })),
      mode: 'multiple',
      condition: 'OR',
      allowToChangeCondition: true,
    });

    this.listOfItemSelectedMap.set('tag', new BehaviorSubject([]));
    this.listOfItemSelectedMap.set('vertical', new BehaviorSubject([]));

    if (models.length > 1) {
      this.toggleFilters.set('only_on_title', {
        key: 'only_on_title',
        description: 'Only match titles',
        value: false,
        location: 'body',
      });
    } else {
      if (models[0] === 'startups') {
        this.toggleFilters.set('portfolio', {
          key: 'portfolio',
          description: 'Plug and Play Portfolio',
          value: false,
          location: 'filters',
        });

        this.selectFilters.set('country', {
          key: 'country',
          title: 'Country',
          listOfItems: this.countryService.countries.map((country) => ({
            value: country.country_code,
            name: country.country_name,
            key: 'country',
          })),
          mode: 'multiple',
          condition: 'OR',
          allowToChangeCondition: false,
        });

        this.selectFilters.set('stage', {
          key: 'stage',
          title: 'Stage',
          listOfItems: this.stageService.stages.map((stage) => ({
            value: stage.id,
            name: stage.stage_name,
            key: 'stage',
          })),
          mode: 'multiple',
          condition: 'OR',
          allowToChangeCondition: false,
        });

        this.initializeLatestUpdatedFilter();
        this.initializeDealflowFeedbackFilter();

        this.listOfItemSelectedMap.set('country', new BehaviorSubject([]));
        this.listOfItemSelectedMap.set('stage', new BehaviorSubject([]));
        this.listOfItemSelectedMap.set('updated', new BehaviorSubject([]));
        this.listOfItemSelectedMap.set('feedback', new BehaviorSubject([]));
      }
    }
  }

  initializeLatestUpdatedFilter() {
    const date = new Date();

    const latestUpdateFilters: SelectFilter = {
      key: 'updated',
      mode: 'single',
      title: 'Latest Update',
      condition: 'OR',
      allowToChangeCondition: false,
      listOfItems: [
        {
          key: 'updated',
          name: 'Last month',
          value: {
            gte: subMonths(date, 1),
            lte: date,
          },
        },
        {
          key: 'updated',
          name: 'Last quarter',
          value: {
            gte: startOfQuarter(date),
            lte: endOfQuarter(date),
          },
        },
        {
          key: 'updated',
          name: 'Last year',
          value: {
            gte: startOfYear(date),
            lte: endOfYear(date),
          },
        },
      ],
    };
    this.selectFilters.set('updated', latestUpdateFilters);
  }

  initializeDealflowFeedbackFilter() {
    const dealflowFeedbackFilter: SelectFilter = {
      key: 'feedback',
      mode: 'single',
      title: 'Star rating',
      select_key: 'star rating',
      condition: 'OR',
      listOfItems: [
        {
          key: 'feedback',
          name: '>= 1',
          value: {
            gte: 1,
          },
        },
        {
          key: 'feedback',
          name: '>= 2',
          value: {
            gte: 2,
          },
        },
        {
          key: 'feedback',
          name: '>= 3',
          value: {
            gte: 3,
          },
        },
        {
          key: 'feedback',
          name: '>= 4',
          value: {
            gte: 4,
          },
        },
        {
          key: 'feedback',
          name: '>= 5',
          value: {
            gte: 5,
          },
        },
      ],
      allowToChangeCondition: false,
    };
    this.selectFilters.set('feedback', dealflowFeedbackFilter);
  }
}
