import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Tag } from 'app/models/tag';
import { TagsStore, TagState } from './tag.store';

@Injectable({ providedIn: 'root' })
export class TagsQuery extends QueryEntity<TagState, Tag, number> {
  constructor(protected store: TagsStore) {
    super(store);
  }
}
