import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operationsCount',
})
export class OperationsCountPipe implements PipeTransform {
  transform(attendee: any[]): any {
    return attendee.filter((obj) => {
      if (obj.role) {
        if (obj.role === 'O' && obj.is_staff) {
          return obj;
        }
      }
    });
  }
}
