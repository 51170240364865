import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Order } from '@datorama/akita';
import { Notification } from '@playbook/models/notification';
import { slideMotion } from '@playbook/modules/core/animations/animation-consts';
import { UserService } from '@playbook/modules/user/services/user.service';
import { ResponsiveService } from '@playbook/services/responsive.service';
import { AutoClearComponent } from '@playbook/shared/components/base/auto-clear.component';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../../../../environments/environment';
import { SocketMessageTypes } from '../../../../models/socket-message';
import { NotificationCountService } from '../../../../services/notification-count.service';
import { NotificationSoundService } from '../../../../services/notification-sound.service';
import { NotificationService } from '../../services/notification.service';

// TODO: Need a refactor
@Component({
  selector: 'playbook-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideMotion],
})
export class NotificationDropdownComponent extends AutoClearComponent implements OnInit, OnDestroy {
  @ViewChild(CdkOverlayOrigin, { static: true, read: ElementRef }) originElement: ElementRef<HTMLDivElement>;
  @ViewChild(CdkConnectedOverlay, { static: false }) cdkConnectedOverlay: CdkConnectedOverlay;
  opened = false;
  dropDownPosition: 'top' | 'center' | 'bottom' = 'center';
  isMobile$: Observable<boolean>;
  pendingNotifications$: Observable<number>;
  loading$: Observable<boolean>;
  finalReached$: Observable<boolean>;
  isOpen = false;
  unseen = 0;
  notifications$: Observable<Notification[]>;
  interval = null;
  @Input() userInteraction = true;
  private stopProp = false;
  oldNotifications$: Observable<Notification[]>;

  constructor(
    private notificationService: NotificationService,
    private notificationSoundService: NotificationSoundService,
    private notificationCountService: NotificationCountService,
    private router: Router,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private responsiveService: ResponsiveService
  ) {
    super();
  }

  setOpenState(value: boolean) {
    if (this.opened !== value) {
      this.opened = value;
      this.cdr.markForCheck();
    }

    if (this.notificationService.unseen() > 0) {
      this.notificationService.seen();
    }
  }

  onPositionChange(position: ConnectedOverlayPositionChange) {
    this.dropDownPosition = position.connectionPair.originY;
  }

  onScroll(evt: MouseEvent) {
    const { clientHeight, scrollTop, scrollHeight } = evt.target as HTMLDivElement;
    const maxScroll = scrollHeight - clientHeight;
    const isLoading = this.notificationService.query.getValue().loading;
    const { finalReached } = this.notificationService.store.getUI();
    if (!isLoading && !finalReached && (scrollTop * 100) / maxScroll > 98) {
      this.notificationService.loadMore();
    }
  }

  ngOnInit() {
    this.notificationService.initializeNotifications();
    this.isMobile$ = this.responsiveService.isMobile$;
    this.pendingNotifications$ = this.notificationService.pendingNotifications();
    this.notifications$ = this.notificationService.query.selectAll({
      filterBy: ({ content_object }) => !!content_object,
      sortBy: 'date',
      sortByOrder: Order.DESC,
    });
    this.oldNotifications$ = this.notificationService.query.selectAll();
    this.finalReached$ = this.notificationService.query.finalReached();

    this.router.events.subscribe(() => {
      this.isOpen = false;
    });

    this.userService
      .token()
      .pipe(
        switchMap((token) => webSocket(`${environment.socketUrl}?bearer=${token}`)),
        map((payload) => {
          if (payload['type'] === SocketMessageTypes.Refresh) {
            return null;
          }
          if (payload['type'] === SocketMessageTypes.Remove) {
            this.removeNotification(payload['data']);
            return null;
          }
          return payload;
        }),
        filter((payload) => {
          if (payload === null) {
            return false;
          }

          return payload['type'] === SocketMessageTypes.Notification;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.notificationSoundService.playSound();
          // this.notifications.splice(0, 0, payload['object']);
          this.unseen += 1;

          if (this.userInteraction) {
            this.notificationCountService.updateCount(1, 'Notification Dropdown');
          }
        },
        (err) => console.log(err),
        () => null
      );
  }

  removeNotification(notificationId: number) {
    this.notificationService.removeNotification(notificationId);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  markAllRead() {
    this.notificationService.markAllAsRead();
  }

  openDropdown() {
    this.isOpen = !this.isOpen;
    if (this.unseen > 0) {
      this.notificationService.seen();
      this.notificationCountService.updateCount(this.unseen * -1, 'Notification Dropdown');
      this.unseen = 0;
    }
  }

  @HostListener('body:click', ['$event'])
  bodyClicked() {
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }
    this.isOpen = false;
  }

  @HostListener('click', ['$event'])
  clicked() {
    this.stopProp = true;
  }
}
