import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface StepData {
  previousButtonText?: string;
  nextButtonText?: string;
  hidePreviousButton?: boolean;
  hideNextButton?: boolean;
  preventUserSelection?: boolean;
}

@Component({
  selector: 'playbook-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper implements OnInit {
  @Output() complete = new EventEmitter<void>();
  @Input() navigationDisabled = false;
  @Input() stepsData: Map<number, StepData> = new Map<number, StepData>();

  ngOnInit() {
    this.selectionChange.subscribe(() => (document.getElementById('stepper-top').scrollTop = 0));
  }

  selectStep(step: CdkStep, stepIndex: number): void {
    if (this.stepsData.has(stepIndex) && this.stepsData.get(stepIndex).preventUserSelection) {
      return;
    }
    if (this.navigationDisabled) {
      return;
    }
    step.select();
  }
}
