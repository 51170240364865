import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Collection } from '../../../models/collection';
import { User } from '../../../models/user';
import { CollectionService } from '../../../services/collections.service';

@Component({
  selector: 'playbook-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss'],
})
export class CollectionCardComponent {
  @Input() collection: Collection;
  @Input() user: User;
  @Input() card: String;
  @Input() suggestedUsers: User;
  @Input() extraCollection: Number;
  @Output() refreshSuggestedUsers = new EventEmitter<string>();

  constructor(private collectionService: CollectionService) {}

  likeCollection() {
    this.collectionService.like(this.collection.id).subscribe((res) => {
      this.collection.liked = true;
      this.collection.likes_count++;
    });
  }

  unlikeCollection() {
    this.collectionService.unlike(this.collection.id).subscribe((res) => {
      this.collection.liked = false;
      this.collection.likes_count--;
    });
  }

  likeUnlikeCollection() {
    if (this.collection.liked) {
      this.unlikeCollection();
    } else {
      this.likeCollection();
    }
  }
}
