import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Cached } from '../modules/cache/decorators/cached.decorator';
import { Corporation, Startup, ResultSet } from '@playbook/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StartupService {
  constructor(private http: HttpClient) {}

  getAll(page?: number, limit?: number, order?: string) {
    let offset;
    let httpParams = new HttpParams();

    if (limit) {
      offset = page * 40;
      httpParams = httpParams.append('limit', `${limit}`);
    } else {
      offset = page * limit;
      httpParams = httpParams.append('limit', '40');
    }

    if (page) {
      httpParams = httpParams.append('offset', `${offset}`);
      httpParams = httpParams.append('page', `${page}`);
    }

    if (order) {
      httpParams = httpParams.append('order', `${order}`);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/', { params: httpParams });
  }

  getLimit(limit: number) {
    const httpParams = new HttpParams().set('limit', `${limit}`);
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/', { params: httpParams });
  }

  @Cached('startup')
  get(id: number) {
    return this.http.get<Startup>(environment.apiBaseUrl + 'startups/' + id + '/');
  }

  delete(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'startups/' + id + '/');
  }

  searchFilter(predicates: object, sort: string) {
    let httpParams = new HttpParams();

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];

      if (value == null || value.length === 0) {
        return;
      }

      httpParams = httpParams.append(key, `${predicates[key]}`);
    });

    if (sort && sort.length > 0) {
      httpParams = httpParams.append('ordering', sort);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/search/', { params: httpParams });
  }

  searchDomain(predicates: object, sort: string) {
    let httpParams = new HttpParams();

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];

      if (value == null || value.length === 0) {
        return;
      }

      httpParams = httpParams.append(key, `${predicates[key]}`);
    });

    if (sort && sort.length > 0) {
      httpParams = httpParams.append('ordering', sort);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/search_domain/', { params: httpParams });
  }

  create(startup: any) {
    const data = {};
    const keys = Object.getOwnPropertyNames(startup);

    keys.forEach((key) => {
      if (key === 'verticals') {
        data['verticals'] = startup.verticals.map(function (vertical) {
          return vertical.id;
        });
      } else if (key === 'tags') {
        data['tags'] = startup.tags.map(function (tag) {
          return tag.id;
        });
      } else if (key === 'stage') {
        if (typeof startup.stage !== 'undefined') {
          data['stage'] = startup.stage.id;
        }
      } else if (key === 'account_managers') {
        data['account_managers'] = startup.account_managers.map(function (user) {
          return user.id;
        });
      } else if (key === 'interested_offices') {
        data['interested_offices'] = startup.interested_offices.map(function (office) {
          return office.id;
        });
      } else if (key === 'website') {
        data['website'] = startup.website.toLowerCase();
      } else if (key === 'domain_name') {
        data['domain_name'] = startup.domain_name.toLowerCase();
      } else if (key === 'description') {
        data['description'] = startup.description.toLowerCase();
      } else {
        if (!!startup[key]) {
          data[key] = startup[key];
        }
      }
    });

    return this.http.post<Startup>(environment.apiBaseUrl + 'startups/', data);
  }

  search(query: string): Observable<Startup[]> {
    return this.http
      .post<Startup[]>(`${environment.apiUrl}v2/search/startups/`, {
        order_by: 'relevance',
        query,
        max_results: 50,
      })
      .pipe(map((payload) => payload['results']));
  }
}
