import { Observable } from 'rxjs';
import { CacheService, CacheReturnTypes } from '../services/cache.service';
import { map } from 'rxjs/operators';

export function Cached(key: string, expiry?: number): any | Observable<any> {
  return function (
    target: Function,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<any>
  ): any | Observable<any> {
    const method: Function = descriptor.value;

    descriptor.value = function (...args: Array<any>): any | Observable<any> {
      const keyArgs = [propertyKey].concat(args);
      const cache = CacheService.getInstance();

      if (cache.exists(key, keyArgs)) {
        const cachedValue = cache.get(key, keyArgs);

        if (cachedValue !== false) {
          return cachedValue;
        }
      }

      const value = method.apply(this, args);

      if (!!value.subscribe) {
        return value.pipe(
          map((res: any) => {
            cache.set(key, keyArgs, res, CacheReturnTypes.Observable, expiry);

            return res;
          })
        );
      }

      cache.set(key, keyArgs, value);

      return value;
    };

    return descriptor;
  };
}
