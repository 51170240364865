import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'domain',
})
export class DomainPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    let result: any;

    const regex = /^(?:https?:\/\/)?((?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+))/gim;
    result = regex.exec(value);

    if (result[1]) {
      return result[1];
    }

    return value;
  }
}
