import { Component, Output, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { SaveSearchService } from '../../../services/save-search.service';
import { OverlayComponent } from '../overlay/overlay.component';
import { ToasterService } from 'app/shared/modules/toaster/services/toaster.service';

@Component({
  selector: 'playbook-saved-searches',
  styleUrls: ['./saved-search.component.scss'],
  templateUrl: 'saved-search.component.html',
  providers: [SaveSearchService, ToasterService],
})
export class SavedSearchComponent implements OnInit {
  savedSearches = [];
  currentSavedSearch: any = {};

  @Input() searchType = 'startups';
  @Output() savedSearch = new EventEmitter<any>();
  @Output() deleteSearch = new EventEmitter<any>();
  @ViewChild('deleteSavedSearchModal', { static: true }) deleteSavedSearchModal: OverlayComponent;
  @ViewChild('savedSearchesModal', { static: true }) savedSearchesModal: OverlayComponent;

  constructor(private saveSearchService: SaveSearchService, private toastService: ToasterService) {}

  ngOnInit() {
    this.saveSearchService.getAll(this.searchType).subscribe((res) => {
      this.savedSearches = res.results.map((search) => {
        search.editing = false;
        return search;
      });
    });
  }

  showDeleteSavedSearch(search) {
    this.currentSavedSearch = search;
    this.deleteSavedSearchModal.overlayOpen = true;
  }

  deleteSavedSearch() {
    this.saveSearchService.delete(this.currentSavedSearch.id).subscribe(
      (res) => {
        const savedSearchIndex = this.savedSearches.indexOf(this.currentSavedSearch);
        this.savedSearches.splice(savedSearchIndex, 1);
        this.deleteSavedSearchModal.overlayOpen = false;
        this.currentSavedSearch = {};
        this.deleteSearch.emit();
      },
      (_) => {}
    );
  }

  applySavedSearch(savedSearch) {
    this.savedSearch.emit(savedSearch);
  }

  openNameEdit(event, savedSearch) {
    event.stopPropagation();
    savedSearch.editing = true;
  }

  saveTitle(event, savedSearch) {
    if (event.key !== 'Enter') {
      return;
    }

    this.saveSearchService.updateName(savedSearch.id, savedSearch.name).subscribe((res) => {
      savedSearch.editing = false;
      this.toastService.pop('success', 'Name updated', 'The name of the saved search was updated.');
    });
  }

  getSortTitle(filterName: string) {
    let output = '';
    let desc = false;

    if (filterName[0] === '-') {
      output = filterName.slice(1);
      desc = true;
    } else {
      output = filterName;
    }

    if (desc === true) {
      output += ' (descending)';
    }

    return output.split('_').join(' ');
  }
}
