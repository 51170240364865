import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ica_status',
})
export class ICAStatusPipe implements PipeTransform {
  icaStatus = ['Ventures', 'Legal', 'Complete', 'Declined'];

  transform(id: number): string {
    return this.icaStatus[id];
  }
}
