import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { OverlayComponent } from '../overlay/overlay.component';
import { StaffService } from '../../../services/staff.service';
import { User } from '../../../models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'playbook-staff-picker-overlay',
  templateUrl: './staff-picker-overlay.component.html',
  styleUrls: ['./staff-picker-overlay.component.scss'],
  providers: [StaffService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffPickerOverlayComponent implements OnInit {
  private searchSubscription: Subscription;
  filterText: string;

  @Output() saveSelected = new EventEmitter<User[]>();
  @Output() saveEdited = new EventEmitter<User[]>();

  @ViewChild('staffPickerOverlay', { static: true }) staffPickerOverlay: OverlayComponent;

  maxPages = 1;
  currentPage = 0;
  totalStaffUsers = 0;
  perPage = 40;

  staff_users: User[];

  selected: Map<number, User> = new Map<number, User>();

  mode: string;
  edit_item: any;

  constructor(private staffService: StaffService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  open(selected?: any, mode = 'create', edit_item?: any): void {
    if (selected) {
      if (mode && mode === 'edit') {
        if (edit_item) {
          this.edit_item = edit_item;
        }
      }
      selected.forEach((user) => {
        this.selected.set(user.id, user);
      });
    }

    this.mode = mode;
    this.staffPickerOverlay.overlayOpen = true;
    this.cdr.detectChanges();
  }

  private clear(): void {
    this.selected.clear();
    this.edit_item = undefined;
    this.staff_users = undefined;
    this.filterText = '';
  }

  close(): void {
    this.staffPickerOverlay.overlayOpen = false;
    this.clear();
  }

  save(): void {
    const selected = Array.from(this.selected.values());
    if (this.edit_item) {
      this.saveEdited.emit(selected);
    } else {
      this.saveSelected.emit(selected);
    }
    this.close();
  }

  isSelected(user: User): boolean {
    return this.selected.has(user.id);
  }

  selectItem(user: User): void {
    if (!this.isSelected(user)) {
      this.selected.set(user.id, user);
    } else {
      this.selected.delete(user.id);
    }
    this.cdr.detectChanges();
  }

  getSelectedList() {
    return Array.from(this.selected.values());
  }

  calculatePages(result) {
    this.totalStaffUsers = result.count;
    this.maxPages = Math.ceil(result.count / this.perPage);
  }

  changePage(options) {
    this.perPage = options[1];
    this.staffService.search(this.filterText, options[0], options[1]).subscribe((result) => {
      this.staff_users = result.results;
      this.calculatePages(result);
      this.currentPage = options[0];
      this.cdr.detectChanges();
    });
  }

  searchPredicate(text) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.staffService.search(text).subscribe((staff_users) => {
      this.staff_users = staff_users.results;
      this.cdr.detectChanges();
    });
  }

  showResults() {
    return !this.staff_users.every(this.isSelected.bind(this));
  }
}
