import { Component, Output, Input, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'playbook-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() currentPage: number;
  @Input() totalItems: number;
  @Input() totalPages = 0;
  @Input() perPage = 40;
  @Input() perPageOptions = [40, 80, 100];
  @Input() style = {};
  @Output() selected = new EventEmitter<Array<number>>();
  startOfPage = 0;
  endOfPage = 0;
  showPerPageList = false;

  perPageUpdated(perPage) {
    this.selected.emit([0, perPage]);
    this.showPerPageList = false;
  }

  nextPage() {
    if (this.hasNextPage() === false) {
      return;
    }

    this.selected.emit([this.currentPage + 1, this.perPage]);
  }

  previousPage() {
    if (this.hasPreviousPage() === false) {
      return;
    }

    this.selected.emit([this.currentPage - 1, this.perPage]);
  }

  hasPreviousPage() {
    return this.currentPage > 0;
  }

  hasNextPage() {
    return this.totalPages > 0 && this.currentPage < this.totalPages - 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.totalPages = Math.ceil(this.totalItems / this.perPage);
    this.startOfPage = this.perPage * this.currentPage + 1;
    this.endOfPage = this.perPage * (this.currentPage + 1);

    if (this.endOfPage > this.totalItems) {
      this.endOfPage = this.totalItems;
    }
  }
}
