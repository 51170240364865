import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ica_share_type',
})
export class ICAShareTypePipe implements PipeTransform {
  icaShareType = ['Pre-money', 'Post-money'];

  transform(id: number): string {
    return this.icaShareType[id];
  }
}
