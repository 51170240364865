import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../../models/user';

@Component({
  selector: 'playbook-user-result-card',
  templateUrl: './user-result-card.component.html',
  styleUrls: ['./user-result-card.component.scss'],
})
export class UserResultCardComponent implements OnInit {
  @Input() user: User;

  constructor() {}

  ngOnInit() {}
}
