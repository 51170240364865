import { Corporation } from './corporation';
import { Vertical } from './vertical';
import { Tag } from './tag';
import { User } from './user';
import { Office } from './office';
import { Startup } from './startup';
import { DealflowAgenda } from './dealflow-agenda';

export enum DealflowStatus {
  'created' = 0,
  'sourcing' = 1,
  'reviewing' = 2,
  'scheduling' = 3,
  'ready' = 4,
  'feedback' = 5,
  'completed' = 6,
  'canceled' = 100,
  'declined' = 200,
}

export class Dealflow {
  id: number;
  agenda: DealflowAgenda[];
  agenda_interval: number;
  corporation: Corporation;
  group_name: string;
  created: Date;
  dealflow_status: DealflowStatus;
  end_date: any;
  location_address: string;
  office: Office;
  purpose: string;
  start_date: any;
  startups: Startup[];
  tags: Tag[] = [];
  updated: Date;
  updated_by: User;
  permissions: string;
  vertical: Vertical;
  virtual: number;
  virtual_link: string;
  dealflow_days: number;
  startup_count: number;
  tech_interests: string;
  chosen_room: string;
  startTime: any;
  endTime: any;
  timezone: any;
  corporation_name?: string;
  destination_info: any;
  virtual_login: any;
  email_alerts: any;
  meeting_info: any;
  dealflow_date: any;
}
