import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationService } from '../../notifications.service';
import { UserManager } from '../../../../services/user-mananger.service';
import { Notification, NotificationType } from '@playbook/models/notification';

@Component({
  selector: 'playbook-notification-body',
  templateUrl: './notification-body.component.html',
  styleUrls: ['./notification-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBodyComponent {
  @Input() notifications = [];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  constructor(private notificationService: NotificationService, public userManager: UserManager) {}

  trackByValue(_: number, item: Notification) {
    return item.id;
  }

  markAsRead(notification: any) {
    if (notification.read === true) {
      return;
    }

    this.notificationService.markAsRead(notification.id).subscribe((res) => {
      notification.read = true;
    });
  }

  checkMultiDay(dealflow) {
    if (dealflow.start_date !== dealflow.end_date) {
      return true;
    }
  }

  getMultiDay(dealflow) {
    const startDate = this.addTimeZoneOffset(dealflow.start_date, dealflow);
    const endDate = this.addTimeZoneOffset(dealflow.end_date, dealflow);
    return this.formatDateShow(startDate) + ' - ' + this.formatDateShow(endDate) + ', ' + startDate.getFullYear();
  }

  addTimeZoneOffset(time, dealflow) {
    let newDate = time;
    if (!(time instanceof Date)) {
      newDate = new Date(time);
    }
    const localTime = newDate.getTime();
    const localOffset = (dealflow.destination_info.rawOffset + dealflow.destination_info.dstOffset) * 1000;
    newDate = new Date(localTime + localOffset);
    return newDate;
  }

  formatDateShow(date: Date) {
    const monthNum = date.getMonth();
    return this.months[monthNum] + ' ' + this.formatTimeProperty(date.getDate());
  }

  formatTimeProperty(timeProp) {
    if (timeProp.toString().length === 1) {
      timeProp = '0' + timeProp;
    }

    return timeProp;
  }

  getDfName(dealflow) {
    let dfName = dealflow.corporation;
    if (dealflow.group_name) {
      dfName += ' (' + dealflow.group_name + ')';
    }
    return dfName;
  }

  getUrl(notification: Notification) {
    switch (notification.notification_type) {
      case NotificationType.USER_FOLLOWED:
        return `/user/${notification.content_object.id}`;

      case NotificationType.DEALFLOW_ATTENDEE_ADDED:
      case NotificationType.DEALFLOW_REVIEW:
      case NotificationType.DEALFLOW_INVITATION:
      case NotificationType.DEALFLOW_STATE_UPDATED:
      case NotificationType.STARTUPS_REVIEWED:
      case NotificationType.STARTUP_CONFIRM:
      case NotificationType.STARTUP_UNCONFIRM:
      case NotificationType.STARTUP_DECLINE:
        return `/dealflow/${notification.content_object.id}`;

      case NotificationType.COLLECTION_COLLABORATOR_ADDED:
        return `/collections/${notification.content_object.id}`;

      case NotificationType.BALLOT_VOTER_ADDED:
      case NotificationType.BALLOT_CREATED:
        return `/collections/${20}/ballot`;

      case NotificationType.CORPORATION_UPDATED:
      case NotificationType.CORPORATION_TEAM_ADDED:
        return `/discover/corporations/${notification.content_object.id}`;

      case NotificationType.STARTUP_UPDATED:
      case NotificationType.STARTUP_TEAM_ADDED:
      case NotificationType.STARTUP_PORTFOLIO_MANAGER_ADDED:
        return `/discover/startups/${notification.content_object.id}`;

      case NotificationType.STARTUP_ACCOUNT_MANAGER_REQUEST:
      case NotificationType.NOTE_TAG_STARTUP:
        return `/discover/startups/${notification.content_object.startup.id}`;

      case NotificationType.CORPORATION_ACCOUNT_MANAGER_REQUEST:
      case NotificationType.NOTE_TAG_CORPORATION:
        return `/discover/corporations/${notification.content_object.corporation.id}`;

      case NotificationType.EVENT_CREATED:
      case NotificationType.EVENT_INVITED:
        return `/events/${notification.content_object.id}`;

      case NotificationType.OFFICE_HOUR_CREATED:
      case NotificationType.OFFICE_HOUR_INVITED:
        return `/office-hour/${notification.content_object.id}`;

      case NotificationType.SMART_ALERT_TRIGGER:
        return `/smart-alerts/${notification.content_object.id}`;

      case NotificationType.SMART_ALERT_STARTUP_TRIGGER:
        return `/smart-alerts/${notification.content_object.smart_alert_id}`;

      default:
        return null;
    }
  }
}
