import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SearchTab } from '../../models/search-explorer';

@Component({
  selector: 'playbook-search-explorer-tabs',
  templateUrl: './search-explorer-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExplorerTabsComponent implements OnChanges {
  @Input() tabs: SearchTab[];
  @Output() change = new EventEmitter<SearchTab>();
  selectedTab: SearchTab;

  isSelected(tab: SearchTab) {
    return tab && tab.key === this.selectedTab.key;
  }

  selectTab(tab: SearchTab) {
    this.change.emit(tab);
    this.selectedTab = tab;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { tabs } = changes;
    if (tabs && tabs.currentValue && !this.selectedTab) {
      this.selectedTab = this.tabs[0];
    }
  }
}
