import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Country } from '@playbook/models';
import { CountryState, CountryStore } from './country.store';

@Injectable({ providedIn: 'root' })
export class CountryQuery extends QueryEntity<CountryState, Country, number> {
  constructor(protected store: CountryStore) {
    super(store);
  }
}
