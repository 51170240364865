import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { User } from '@playbook/models';
import { UserService } from 'app/modules/user/services/user.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate() {
    return this.userService.currentUser$.pipe(
      switchMap((user: User) => {
        if (user) {
          this.router.navigate(['/']);
          return of(false);
        }
        return of(true);
      })
    );
  }
}
