import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchDto, SearchItem, SearchTab } from '../../models/search-explorer';
import { SearchExplorerMiddleware } from '../../services/search-explorer.middleware';
import { slideInOut } from '../../../core/animations/animation-consts';
import { takeUntil } from 'rxjs/operators';
import { PlaybookDialog } from '../../../../shared/components/playbook-dialog/playbook-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchExplorerToolbarComponent } from '../search-explorer-toolbar/search-explorer-toolbar.component';

@Component({
  selector: 'playbook-search-explorer',
  templateUrl: './search-explorer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        flex-grow: 1;
        min-height: 0px;
        transform: translateZ(0px);
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }
    `,
  ],
  animations: [slideInOut(220)],
})
export class SearchExplorerComponent extends PlaybookDialog<SearchExplorerComponent> implements OnInit, OnDestroy {
  @ViewChild(SearchExplorerToolbarComponent, { static: false }) toolbar: SearchExplorerToolbarComponent;
  loadingMoreResults$: Observable<boolean>;
  tabs$: Observable<SearchTab[]>;
  currentTab$: Observable<SearchTab>;
  listOfItems$: Observable<SearchItem[]>;
  filterEnabled$: Observable<boolean>;
  filterSidebarState: 'in' | 'out' = 'out';
  singleMode$ = new BehaviorSubject(true);

  constructor(
    private searchExplorerMiddleware: SearchExplorerMiddleware,
    public dialogRef: MatDialogRef<SearchExplorerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialogRef);
  }

  tabChange(tab: SearchTab) {
    this.searchExplorerMiddleware.updateResults(tab);
  }

  loadMore() {
    this.searchExplorerMiddleware.loadMore();
  }

  toggleFilter() {
    this.filterSidebarState = this.filterEnabled$ ? 'in' : 'out';
  }

  onNavigation() {
    this.dialogRef.close();
  }

  onRecentSearch(value: SearchDto) {
    this.toolbar.searchControl.setValue(value.payload.query);
    this.searchExplorerMiddleware.store.updateSearch({ ...value });
  }

  ngOnInit(): void {
    this.tabs$ = this.searchExplorerMiddleware.tabs;
    this.currentTab$ = this.searchExplorerMiddleware.currentTab;
    this.listOfItems$ = this.searchExplorerMiddleware.results;
    this.filterEnabled$ = this.searchExplorerMiddleware.filterEnabled;
    this.loadingMoreResults$ = this.searchExplorerMiddleware.loadingMore;

    let models = null;

    if (this.data && this.data.models) {
      models = this.data.models;
      this.singleMode$.next(false);
    }

    this.searchExplorerMiddleware
      .initializeSearch(models)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.searchExplorerMiddleware.store.setLoading(false);
      });
  }

  ngOnDestroy() {
    this.searchExplorerMiddleware.resetUI();
    super.ngOnDestroy();
  }
}
