import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  DoCheck,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Dealflow } from '../../../models/dealflow';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { MonthViewDay, CalendarEvent } from 'calendar-utils';
import { WindowService } from 'ng-scrollreveal';

@Component({
  selector: 'playbook-calendar-container',
  templateUrl: './calendar-container.component.html',
  styleUrls: ['./calendar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarContainerComponent implements OnInit, OnChanges {
  @Input() dealflows: any[] = [];
  @Output() curViewDate = new EventEmitter<any>();
  events$: Observable<Array<CalendarEvent<{ dealflow: Dealflow }>>>;

  constructor(private router: Router) {}
  allDealflows: any[] = [];
  filteredDealflows: Dealflow;
  dealflowsUpdate: SimpleChange;
  viewDate: Date = new Date();
  view: string;
  dealflowChanges: Boolean = false;
  events: CalendarEvent[] = [];
  eventTooltip: any[] = [];
  eventId: number;
  eventName: string;
  eventStart: Date = new Date();
  eventEnd: Date = new Date();
  eventStartTime: Date = new Date();
  eventEndTime: Date = new Date();
  eventType: string;
  eventColor: '';
  eventColorSecondary: '';
  colors: any = {
    vertical_color: {
      primary: '',
      secondary: '',
    },
  };

  ngOnInit() {
    this.view = 'month';
    this.createEvents(this.dealflows);
  }

  show(event) {
    // console.log('showing: ', event);
  }

  createDealflowList(dealflows: any) {
    if (dealflows.length > 1) {
      const data = dealflows.filter((element) => {
        return element !== undefined;
      });
      for (let i = 0; i < data.length; i++) {
        this.allDealflows.push(data[i]);
      }
    } else {
      this.allDealflows.push(dealflows[0]);
    }
    this.createEvents(this.allDealflows);
  }

  newDate(date) {
    const dateParsed = date.split('-');
    const newDate = new Date(dateParsed[0], dateParsed[1] - 1, dateParsed[2], 0, 0, 0, 0);
    return newDate;
  }

  currentDate() {
    this.viewDate = new Date();
    this.sendViewDate();
  }

  createEvents(event: any[]) {
    this.events = [];
    this.eventTooltip = [];
    if (event.length > 1) {
      event.forEach((element) => {
        this.eventId = element.id;
        this.eventName = element.corporation.corporation_name;
        this.eventStart = startOfDay(this.newDate(element.start_date));
        this.eventEnd = endOfDay(this.newDate(element.end_date));
        if (element.vertical.length !== 0) {
          this.eventColor = element.vertical[0].color;
        } else {
          this.eventColor = element.corporation.verticals[0].color;
        }
        this.colors = {
          vertical_color: {
            primary: this.eventColor,
            secondary: this.colorBrightness(this.eventColor, 0.75),
          },
        };
        this.events.push({
          id: this.eventId,
          title: this.eventName,
          start: this.eventStart,
          end: this.eventEnd,
          color: this.colors.vertical_color,
        });
      });
    }
    if (event.length === 1) {
      this.eventId = event[0].id;
      this.eventName = event[0].corporation.corporation_name;
      this.eventStart = startOfDay(new Date(event[0].start_date));
      this.eventEnd = endOfDay(new Date(event[0].end_date));
      this.eventColor = event[0].vertical[0].color;
      this.colors = {
        vertical_color: {
          primary: this.eventColor,
          secondary: this.colorBrightness(this.eventColor, 0.75),
        },
      };
      this.events.push({
        id: this.eventId,
        title: this.eventName,
        start: this.eventStart,
        end: this.eventEnd,
        color: this.colors.vertical_color,
      });
      const tooltipInfo =
        this.eventStart.toDateString() +
        ' - ' +
        this.eventEnd.toDateString() +
        '<br/>' +
        this.eventStartTime.toTimeString() +
        ' - ' +
        this.eventEndTime.toTimeString();
      this.eventTooltip.push({
        id: this.eventId,
        tooltip: tooltipInfo,
      });
    }
  }

  eventClicked(event: CalendarEvent<{ dealflow: Dealflow }>): void {
    const url = '/dealflow/' + event.id;
    window.open(url, '_blank');
  }

  /* tslint:disable */
  colorBrightness(color, percent) {
    const f = parseInt(color.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  }
  /* tslint:enable */

  ngOnChanges(changes: SimpleChanges) {
    this.dealflowsUpdate = changes.dealflows;
    if (
      this.dealflowsUpdate.previousValue !== undefined &&
      this.dealflowsUpdate.currentValue !== this.dealflowsUpdate.previousValue
    ) {
      this.allDealflows = this.dealflowsUpdate.currentValue;
      this.createEvents(this.allDealflows);
    }
  }

  switchToWeekView(day) {
    this.view = 'week';
    this.viewDate = day;
  }

  sendViewDate() {
    this.curViewDate.emit(this.viewDate);
  }
}
