import { Tag } from './tag';

export class Investor {
  id: number;
  investor_name: string;
  country: string;
  city: string;
  tags: Tag[];
  description: string;
  domain: string;
  investor_type: string;
  one_liner: string;
  logo: any;
  updated: Date;
  founded_on: Date;
  investment_count: number;
  rank: number;
  article: string;
  image: string;
  cb_uuid: string;
  website: string;
}
