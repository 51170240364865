import {
  Component,
  Input,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'playbook-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, OnChanges {
  showResults = false;
  private stopProp = false;
  @Input() objects: any[];
  @Input() objectsId: any[];
  @Input() displayValue = 'name';
  @Input() selectedObjects: any[] = [];
  @Input() selectedObject: any;
  @Input() selecting: String;
  @Output() search = new EventEmitter<string>();
  @ViewChild('objectSearch', { static: false }) objectSearch;
  @Output() returnSingle = new EventEmitter<any>();

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.objects.sort();
    if (changes.objects && this.selecting !== 'single') {
      this.filterObjects();
    }
  }

  filterObjects() {
    this.objects.sort();
    if (this.displayValue === '') {
      const objects = this.selectedObjects.map((obj) => {
        return obj;
      });
      this.objects = this.objects.filter((object) => {
        return !objects.includes(object);
      });
    } else {
      const objectIds = this.selectedObjects.map((obj) => {
        return obj.id;
      });
      this.objects = this.objects.filter((object) => {
        return !objectIds.includes(object.id);
      });
    }
  }

  emitSearch(value) {
    this.search.emit(value);
  }

  clearSearch() {
    this.objectSearch.nativeElement.value = '';
  }

  selectObject(object) {
    if (this.selecting === 'multiple') {
      this.selectedObjects.push(object);
      this.filterObjects();
      this.clearSearch();
      this.emitSearch('');
      this.showResults = false;
    } else {
      this.returnSingle.emit(object);
      if (this.displayValue === '') {
        this.objectSearch.nativeElement.value = object;
      } else {
        this.objectSearch.nativeElement.value = object[this.displayValue];
      }
      this.showResults = false;
    }
  }

  deselectObject(object) {
    this.objects.push(object);
    const objectIndex = this.selectedObjects.indexOf(object);
    this.selectedObjects.splice(objectIndex, 1);
    this.filterObjects();
  }

  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }

    this.showResults = false;
  }

  @HostListener('click', ['$event'])
  clicked(event) {
    this.stopProp = event.currentTarget.contains(event.srcElement);
  }
}
