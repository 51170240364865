import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Stage } from '@playbook/models';
import { StageState, StageStore } from './stage.store';

@Injectable({ providedIn: 'root' })
export class StageQuery extends QueryEntity<StageState, Stage, number> {
  constructor(protected store: StageStore) {
    super(store);
  }
}
