import { Component, Output, Input, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { min } from 'rxjs/operators';

@Component({
  selector: 'playbook-date-range-search',
  templateUrl: 'date-range-search.component.html',
  styleUrls: ['./date-range-search.component.scss'],
})
export class DateRangeSearchComponent {
  private stopProp = false;
  startDate: Date;
  endDate: Date;
  minDate: Date;
  searchOpen = false;
  owlElement = 'owl-dt';
  @Input() customPadding = false;

  @Output() datesPicked = new EventEmitter<any>();

  constructor() {}

  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    if (event.srcElement.className) {
      if (
        event.srcElement.className.includes(this.owlElement) ||
        event.srcElement.className.includes(this.owlElement)
      ) {
        return;
      }
    }
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }

    this.searchOpen = false;
  }

  @HostListener('click', ['$event'])
  clicked(event) {
    this.stopProp = event.currentTarget.contains(event.srcElement);
  }

  setStartAt(type) {
    if (type === 'start' && this.startDate) {
      return this.startDate;
    } else if (type === 'end') {
      if (this.endDate) {
        return this.endDate;
      } else if (this.startDate) {
        return this.startDate;
      }
    }
    return new Date();
  }

  getMinDate(date) {
    if (date) {
      this.minDate = date.value;
    } else {
      this.minDate = null;
    }
  }

  removeDate() {
    this.startDate = null;
    this.endDate = null;
    this.getMinDate(null);
    this.datesPicked.emit({ startDate: this.startDate, endDate: this.endDate });
  }

  dateChanged(field, value) {
    if (field === 'start') {
      this.startDate = value.value;
    }
    if (field === 'end') {
      this.endDate = value.value;
    }
    this.datesPicked.emit({ startDate: this.startDate, endDate: this.endDate });
  }
}
