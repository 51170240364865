export const environment = {
  apiUrl: 'https://sandbox.api.playbook.vc/',
  apiBaseUrl: 'https://sandbox.api.playbook.vc/v1/',
  socketUrl: 'wss://sandbox.api.playbook.vc/v1/ws/socket/',
  production: true,
  hereApiKey: 'yefJsiuHNhtEPIL6DDP4hA',
  hereAppId: 'Gl6lW9nPO88oks6Yp6Da',
  hmr: false,
  cookieName: '_pb_sandbox',
  firebase: {
    apiKey: 'AIzaSyCHi6M8ryhzVxBOmNtyvE7CjbZ_UkPWy-8',
    authDomain: 'playbook-admin-sandbox.firebaseapp.com',
    databaseURL: 'https://playbook-admin-sandbox.firebaseio.com',
    projectId: 'playbook-admin-sandbox',
    storageBucket: 'playbook-admin-sandbox.appspot.com',
    messagingSenderId: '254083993719',
    appId: '1:254083993719:web:7ab7e288da86803278b672',
    measurementId: 'G-YY56JYSJZY',
  },
  authentication: {
    url: 'http://sandbox.auth.playbook.vc',
    clientId: '4600a57b-bd7e-4c74-a230-4dffbde00e93',
    redirectUri: 'https://sandbox.playbook.vc',
  },
  appVersion: 'bd953f9c-sandbox',
};
