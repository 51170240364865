import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AutoClearComponent } from 'app/shared/components/base/auto-clear.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GlobalSearchResults } from '../../../../models/global-search-result';
import { GlobalSearchService } from '../../global-search.service';
import { Ability } from '@casl/ability';
import { SearchExplorerService } from '../../../search/services/search-explorer.service';

@Component({
  selector: 'playbook-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent extends AutoClearComponent implements OnInit {
  isOpen = false;
  isClean = true;
  query = new FormControl('', Validators.required);
  results$ = new Subject<GlobalSearchResults>();
  loading$ = new BehaviorSubject<boolean>(false);
  empty$ = new BehaviorSubject<boolean>(false);

  @Input() buttonStyle = '';

  constructor(
    private searchService: GlobalSearchService,
    private router: Router,
    private ability: Ability,
    private searchExplorerService: SearchExplorerService
  ) {
    super();
  }

  resetQuery() {
    this.query.reset('');
    this.results$.next(null);
    this.isClean = true;
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      this.closeSearch();
    });
    this.onQueryChange();
  }

  openSearchDialog() {
    this.searchExplorerService.open();
  }

  setOpenState(value: boolean) {
    this.isOpen = value;
  }

  closeSearch() {
    this.setOpenState(false);
    this.results$.next(null);
    this.empty$.next(false);
    this.isClean = true;
  }

  private onQueryChange() {
    this.query.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe((query) => {
      if (query) {
        this.performSearch(query);
      }
    });
  }

  trackById(index: number, instance: any): number {
    return instance.id;
  }

  performSearch(query: string) {
    this.empty$.next(false);
    this.isClean = query.length === 0;
    this.results$.next(null);

    this.loading$.next(true);
    this.searchService
      .search(query)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.results$.next(result);
        this.loading$.next(false);
        this.empty$.next(Object.keys(result).every((key) => result[key].length === 0));
      });
  }
}
