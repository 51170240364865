import { Component, OnInit, Input } from '@angular/core';
import { Collection } from '../../../../models/collection';

@Component({
  selector: 'playbook-collection-result-card',
  templateUrl: './collection-result-card.component.html',
  styleUrls: ['./collection-result-card.component.scss'],
})
export class CollectionResultCardComponent implements OnInit {
  @Input() collection: Collection;

  constructor() {}

  ngOnInit() {}
}
