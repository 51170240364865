import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResultSet } from '../models/result-set';
import { Corporation } from '../models/corporation';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CorporationService {
  constructor(private http: HttpClient) {}

  getAll(page?: number) {
    let httpParams = new HttpParams();
    const offset = page * 40;

    if (page) {
      httpParams = httpParams.append('limit', '40');
      httpParams = httpParams.append('offset', String(offset));
      httpParams = httpParams.append('page', String(page));
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/', { params: httpParams });
  }

  get(id: number) {
    return this.http.get<Corporation>(environment.apiBaseUrl + 'corporations/' + id + '/');
  }

  delete(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'corporations/' + id + '/');
  }

  create(corporation: Corporation) {
    const data = {};
    const keys = Object.getOwnPropertyNames(corporation);

    keys.forEach((key) => {
      if (key === 'verticals') {
        data['verticals'] = corporation.verticals.map(function (vertical) {
          return vertical.id;
        });
      } else if (key === 'tags') {
        data['tags'] = corporation.tags.map(function (tag) {
          return tag.id;
        });
      } else if (key === 'account_managers') {
        data['account_managers'] = corporation.account_managers.map(function (user) {
          return user.id;
        });
      } else {
        data[key] = corporation[key];
      }
    });

    return this.http.post<Corporation>(environment.apiBaseUrl + 'corporations/', data);
  }

  edit(originalCorporation: Corporation, corporation: Corporation, id: number) {
    const newValues = {};
    const keys = Object.getOwnPropertyNames(corporation);

    if (!!corporation.logo && !!corporation.logo.id) {
      corporation.logo = corporation.logo.id;
    }

    newValues['verticals'] = corporation.verticals.map(function (vertical) {
      return vertical.id;
    });
    newValues['tags'] = corporation.tags.map(function (tag) {
      return tag.id;
    });
    newValues['account_managers'] = corporation.account_managers.map(function (user) {
      return user.id;
    });

    keys.forEach((key) => {
      if (key === 'verticals') {
        return;
      }
      if (key === 'tags') {
        return;
      }
      if (key === 'account_managers') {
        return;
      }

      if (corporation[key] !== originalCorporation[key]) {
        if (!!corporation[key]) {
          if (!corporation[key].id) {
            newValues[key] = corporation[key];
            return;
          }

          if (corporation[key].id !== corporation[key].id) {
            newValues[key] = corporation[key].id;
            return;
          }
        }
      }
    });

    return this.http.patch<Corporation>(environment.apiBaseUrl + 'corporations/' + corporation.id + '/', newValues);
  }

  searchFilter(predicates: object, sort: string) {
    let httpParams = new HttpParams();

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];

      if (value == null || value.length === 0) {
        return;
      }

      httpParams = httpParams.append(key, String(predicates[key]));
    });

    if (sort && sort.length > 0) {
      httpParams = httpParams.append('ordering', sort);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/search/', { params: httpParams });
  }

  follow(id: number) {
    return this.http.post(environment.apiBaseUrl + 'corporations/' + id + '/follow', {});
  }

  getCustomTags(id: number) {
    return this.http.get<any>(environment.apiBaseUrl + 'corporations/' + id + '/custom_tags/');
  }

  search(query: string) {
    return this.http
      .post<Corporation[]>(`${environment.apiUrl}v2/search/`, {
        models: ['corporations'],
        payload: { order_by: 'relevance', query, max_results: 50 },
      })
      .pipe(map((payload) => payload['corporations']['results']));
  }
}
