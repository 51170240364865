import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CacheService} from './services/cache.service';

export * from './decorators/cached.decorator';
export * from './decorators/invalidate-cache.decorator';

@NgModule({})
export class CacheModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CacheModule,
      providers: [CacheService],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule?: CacheModule, private readonly cache?: CacheService) {
    if (parentModule) {
      throw new Error('Cache module already loaded');
    }
  }
}
