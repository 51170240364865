export class CollectionFilter {
  collection_type: 'all' | 'collection' | 'ballot' = 'all';
  owner: 'all' | 'yours' = 'all';
  batch_only = false;
  verticals: number[] = [];
  people: string[] = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
      if (data.batch_only === 'true' || data.batch_only === 'false') {
        this.batch_only = data.batch_only === 'true';
      }
      if (this.verticals instanceof Array) {
        this.verticals = this.verticals.map((v) => Number(v));
      } else if (this.verticals) {
        this.verticals = [Number(this.verticals)];
      }

      if (!(this.people instanceof Array)) {
        this.people = [this.people];
      }
    }
  }

  toQueryParams() {
    const newFilters = Object.assign({}, this);
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key] == null || (newFilters[key] instanceof Array && newFilters[key].length === 0)) {
        delete newFilters[key];
      }
    });
    return newFilters;
  }
}
