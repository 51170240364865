import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'playbook-settings-sidebar',
  templateUrl: 'settings-sidebar.component.html',
  styleUrls: ['./settings-sidebar.component.scss'],
})
export class SettingsSidebarComponent {
  get resetLink() {
    const { url, redirectUri, clientId } = environment.authentication;
    return `${url}/reset?clientId=${clientId}&redirectUri=${redirectUri}`;
  }
}
