import { Group } from './group';
import { Corporation } from './corporation';
import { Vertical } from './vertical';
import { Office } from './office';
import { Tag } from './tag';
import { Country } from './country';
import { Startup } from './startup';
import { RecentSearch, RecentView } from '../modules/search/models/search-explorer';
import { Workspace } from '@playbook/modules/today/model/today';

export class User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  groups: Group[] | number;
  profile_color: string;
  avatar: any;
  position: string;
  permissions: string[];
  is_superuser: boolean;
  corporations: Corporation[];
  startups: Startup[];
  corporations_managed: Corporation[];
  follows: boolean;
  collection_count: number;
  follower_count: number;
  verified: boolean;
  verticals: Vertical[];
  offices: Office[];
  last_login: string;
  first_login: string;
  date_joined: string;
  auto_add_dealflow: boolean;
  phone_number: string;
  is_staff: boolean;
  city: string;
  country: Country;
  biography: string;
  twitter_profile: string;
  linkedin_profile: string;
  tags: Tag[];
  full_name?: string;
  features: string[];
  recent_searches: RecentSearch[];
  recent_views: RecentView[];
  relationshipTrackerId: number;
  token?: string;
  user_workspace: Workspace;

  hasPermission(permission) {
    const hasPermission = this.permissions.includes(permission);
    return hasPermission;
  }
}
