import { Component, Output, EventEmitter, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { OverlayComponent } from '../overlay/overlay.component';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { UserManager } from '../../../services/user-mananger.service';
import { DomainsService } from '../../../services/domains.service';
import { GlobalSearchService } from '../../../modules/global-search/global-search.service';
import { StartupService } from '../../../modules/startups/startups.service';
import { Startup } from '../../../models/startup';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterService } from 'app/shared/modules/toaster/services/toaster.service';

@Component({
  selector: 'playbook-startup-invite-overlay',
  templateUrl: './startup-invite-overlay.component.html',
  styleUrls: ['./startup-invite-overlay.component.scss'],
  providers: [StartupService],
})
export class StartupInviteOverlayComponent implements OnInit {
  startupFormData: FormGroup;
  startupUserData: FormGroup;
  dupCompany = false;
  dupNewUser = false;
  domainEmailMismatch = false;
  startupInvited = false;
  hideSuggestions = false;
  commonDomain = false;
  suggestedStartups: Startup[] = [];
  suggestedStartupTimeout = null;
  domainValidator = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
  domainErrors = [false, false];

  @ViewChild('inviteStartupOverlay', { static: true }) inviteStartupOverlay: OverlayComponent;

  constructor(
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private userManager: UserManager,
    private userService: UserManager,
    private startupService: StartupService,
    private domainService: DomainsService,
    private globalSearchService: GlobalSearchService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.hide();
  }

  openOverlay() {
    this.startupInvited = false;
    this.initForm();
    this.inviteStartupOverlay.overlayOpen = true;
  }

  initForm() {
    this.startupFormData = this.fb.group({
      company_name: ['', [Validators.required]],
      website: [
        '',
        [Validators.required, Validators.pattern('^(?:https?://)?(?:[^@/\n]+@)?(?:www.)?([^:/?\n]+[.][^:/?\n]+)$')],
      ],
      domain_name: [''],
      sub_domains: this.fb.array([new FormControl(null, Validators.pattern(this.domainValidator))]),
      interested_offices: this.fb.array([]),
      awaiting_completion: true,
    });

    const phoneRegex = new RegExp(/([0-9\+\(\)\s-]{10,20})/);

    this.startupUserData = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      phone_number: ['', Validators.pattern(phoneRegex)],
      position: [''],
    });
  }

  inviteStartup() {
    if (this.startupFormData.valid) {
      if (this.startupUserData.valid) {
        this.spinner.show();
        this.formatWebsite();
      } else {
        /*
                Object.keys(this.startupUserData.controls).forEach(key => {
                    const controlErrors = this.startupUserData.get(key).errors;
                    if (controlErrors != null) {
                            Object.keys(controlErrors).forEach(keyError => {
                            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                            });
                        }
                });
                */
        this.toasterService.pop('error', 'Invalid user form', 'Please fill out all required fields for user');
      }
    } else {
      this.toasterService.pop('error', 'Invalid startup form', 'Please fill out all required fields for startup');
    }
  }

  formatWebsite() {
    let websiteSplit;
    this.startupFormData.value.website = this.startupFormData.value.website.toLowerCase().trim();
    if (this.startupFormData.value.website.includes('http')) {
      if (!this.startupFormData.value.website.includes('www')) {
        websiteSplit = this.startupFormData.value.website.split('//');
        this.startupFormData.value.website = websiteSplit[0] + '//www.' + websiteSplit[1];
      }
    } else {
      if (!this.startupFormData.value.website.includes('www')) {
        this.startupFormData.value.website = 'http://www.' + this.startupFormData.value.website;
      } else {
        this.startupFormData.value.website = 'http://' + this.startupFormData.value.website;
      }
    }

    this.addDomainName();
  }

  addDomainName() {
    let url = this.startupFormData.value.website.toLowerCase().trim();

    url = url.replace('https://', '');
    url = url.replace('http://', '');
    url = url.replace('www.', '');

    const index = url.indexOf('/');
    if (index !== -1) {
      url = url.substr(0, url.indexOf('/'));
    }
    this.startupFormData.value.domain_name = url.trim();

    const common = /(yahoo|gmail|hotmail|googlemail|aol|outlook)/.test(this.startupFormData.value.domain_name);

    if (common) {
      this.commonDomain = true;
    }

    this.checkDomainEmail();
  }

  matchDomainstoEmail(emailDomain) {
    let found = false;
    if (this.startupFormData.value.domain_name.trim().toLowerCase() === emailDomain) {
      found = true;
    }
    const subDomains = this.startupFormData.value.sub_domains.filter((domain) => {
      if (!domain) {
        return false;
      }
      return domain.trim().length > 0;
    });

    if (subDomains.length > 0 && subDomains.includes(emailDomain) === true) {
      found = true;
    }
    return found;
  }

  checkDomainEmail() {
    this.startupUserData.value.email = this.startupUserData.value.email.toLowerCase();
    const emailSplit = this.startupUserData.value.email.split('@');

    if (!this.matchDomainstoEmail(emailSplit[1])) {
      this.spinner.hide();
      this.domainEmailMismatch = true;
    } else {
      this.domainEmailMismatch = false;
      this.checkDupCompany();
    }
  }

  checkNextSubdomain($event, control) {
    if (control.invalid) {
      $event.stopPropagation();
      return;
    }

    if ($event.code === 'Enter') {
      (this.startupFormData.controls.sub_domains as FormArray).push(
        new FormControl(null, Validators.pattern(this.domainValidator))
      );
      this.domainErrors.push(false);

      $event.stopPropagation();
      return false;
    }
  }

  checkDupCompany() {
    const domainName = this.startupFormData.value.domain_name.toLowerCase().trim();
    this.globalSearchService.lookupReturn(domainName).subscribe((res) => {
      if (res.startups) {
        if (res.startups.length > 0) {
          this.spinner.hide();
          this.dupCompany = true;
        } else {
          this.dupCompany = false;
          this.checkDupEmail();
        }
      } else if (res.corporations) {
        if (res.corporations.length > 0) {
          this.spinner.hide();
          this.dupCompany = true;
        } else {
          this.dupCompany = false;
          this.checkDupEmail();
        }
      } else {
        this.dupCompany = false;
        this.checkDupEmail();
      }
    });
  }

  checkDupEmail() {
    this.userService.checkUserCompanyAssociation(this.startupUserData.value.email).subscribe((data) => {
      if (!data.found) {
        this.dupNewUser = false;
        this.addStartup();
      } else {
        this.spinner.hide();
        this.dupNewUser = true;
      }
    });
  }

  addStartup() {
    const value = this.startupFormData.value;
    value.account_managers = [this.userManager.currentUser.id];
    value.team_members = [this.startupUserData.value];
    value.domains = this.addDomains(value);

    this.startupService.create(value).subscribe((data) => {
      this.startupInvited = true;
      this.initForm();
      this.spinner.hide();
    });
  }

  addDomains(form) {
    const domains = [];

    domains.push({ domain_name: form.domain_name, primary: true });

    form.sub_domains.forEach((element) => {
      if (element && element !== '') {
        domains.push({ domain_name: element, id: null, primary: false });
      }
    });

    return domains;
  }

  checkStartupExists() {
    if (this.hideSuggestions) {
      return;
    }

    if (this.suggestedStartupTimeout) {
      clearTimeout(this.suggestedStartupTimeout);
    }
    this.suggestedStartups = [];

    this.suggestedStartupTimeout = setTimeout(() => {
      let url = this.startupFormData.value.website.toLowerCase().trim();
      let extractDomain = new RegExp(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+\.[^:\/?\n]+)/g);
      let matches = extractDomain.exec(url);
      this.domainService.searchDomain({ domain_name: matches[1] }, '-created').subscribe((res) => {
        if (res.length > 0) {
          this.domainErrors[0] = true;
        } else {
          this.domainErrors[0] = false;
        }
        this.suggestedStartups = res.slice(0, 10);
        this.domainErrors[1] = false;
        if (this.startupFormData.value.sub_domains.length > 0) {
          this.startupFormData.value.sub_domains.forEach((element) => {
            if (element) {
              url = element.toLowerCase().trim();
              extractDomain = new RegExp(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+\.[^:\/?\n]+)/g);
              matches = extractDomain.exec(url);
              this.domainService.searchDomain({ domain_name: matches[1] }, '-created').subscribe((data) => {
                if (data.length > 0) {
                  this.domainErrors[1] = true;
                }
                data.slice(0, 10).forEach((each) => {
                  this.suggestedStartups.push(each);
                });
                this.suggestedStartupTimeout = null;
              });
            }
          });
        } else {
          this.suggestedStartupTimeout = null;
        }
      });
    }, 500);
  }

  removeSubdomain(index: number) {
    const control = <FormArray>this.startupFormData.controls['sub_domains'];
    control.removeAt(index);
    this.checkStartupExists();
  }
}
