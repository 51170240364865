import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NotificationSoundService {
  private _notification: Subject<any> = new Subject();

  constructor() {}

  get onNotification() {
    return this._notification;
  }

  playSound() {
    this._notification.next();
  }
}
