import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '../../../models/user';

@Component({
  selector: 'playbook-user-avatar',
  styleUrls: ['./user-avatar.component.scss'],
  templateUrl: 'user-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() user: User;
  @Input() useBorder = false;
  @Input() borderColor: string = null;
  @Input() useLarge = false;
  @Input() useHuge = false;
  @Input() useLargish = false;
  @Input() useSmall = false;
  @Input() useDealflow = false;
  @Input() useCard = false;
  @Input() allowHover = false;

  getAvatarStyle() {
    const styles = {};

    if (this.useBorder && this.borderColor) {
      styles['border'] = `2px solid ${this.borderColor}`;
    }

    return styles;
  }
}
