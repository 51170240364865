import { Component, Input } from '@angular/core';

@Component({
  selector: 'playbook-step-header-progress',
  templateUrl: './step-header-progress.component.html',
  styleUrls: ['./step-header-progress.component.scss'],
})
export class StepHeaderProgressComponent {
  @Input() isFirst = false;
  @Input() isLast = false;
  @Input() completed = false;
  @Input() selected = false;
  @Input() label: string;
}
