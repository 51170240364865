import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxWords',
})
export class MaxWordsPipe implements PipeTransform {
  transform(value: any, maxLength?: number, trail?: string): any {
    if (!value) {
      return '';
    }

    if (!maxLength) {
      maxLength = 20;
    }

    if (!trail) {
      trail = '...';
    }

    return value.length > maxLength ? value.split(' ').slice(0, maxLength).join(' ') + trail : value;
  }
}
