import { Component, OnInit, Input } from '@angular/core';
import { Dealflow } from '../../../../models/dealflow';

@Component({
  selector: 'playbook-dealflow-result-card',
  templateUrl: './dealflow-result-card.component.html',
  styleUrls: ['./dealflow-result-card.component.scss'],
})
export class DealflowResultCardComponent implements OnInit {
  @Input() dealflow: Dealflow;

  constructor() {}

  ngOnInit() {}

  getStartTime() {
    let timeParsed = this.dealflow.agenda[0].start_time.split('T');
    timeParsed = timeParsed[1].split(':');
    const startTime = new Date(this.dealflow.agenda[0].start_time);
    startTime.setHours(timeParsed[0]);
    startTime.setMinutes(timeParsed[1]);
    return this.addTimeZoneOffset(startTime);
  }

  getEndTime() {
    let timeParsed = this.dealflow.agenda[this.dealflow.agenda.length - 1].end_time.split('T');
    timeParsed = timeParsed[1].split(':');
    const endTime = new Date(this.dealflow.agenda[this.dealflow.agenda.length - 1].end_time);
    endTime.setHours(timeParsed[0]);
    endTime.setMinutes(timeParsed[1]);
    return this.addTimeZoneOffset(endTime);
  }

  addTimeZoneOffset(time) {
    let newDate = time;
    if (!(time instanceof Date)) {
      newDate = new Date(time);
    }
    const localTime = newDate.getTime();
    const localOffset = (this.dealflow.destination_info.rawOffset + this.dealflow.destination_info.dstOffset) * 1000;
    newDate = new Date(localTime + localOffset);
    return newDate;
  }
}
