import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailName',
})
export class EmailNamePipe implements PipeTransform {
  transform(emailField: string[]): any {
    if (emailField === null) {
      return '';
    }
    return emailField
      .map((email) => {
        const regex = RegExp(/(\"?([\w\s\(\)-]+)\"?)?\s\<?([\w-_\.]+@[\w\.]{2,})\>?/gim);
        const matches = regex.exec(email);

        if (matches === null) {
          return email;
        }

        if (!!matches[2]) {
          return matches[2].trim();
        }

        return matches[3].trim();
      })
      .join(', ');
  }
}
