import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'playbook-select-search',
  template: `
    <input
      #searchElement
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      (focus)="onFocusedChange(true)"
      (blur)="onFocusedChange(false)"
      class="focus:outline-none w-full text-sm"
      type="text"
    />
    <span #mirrorElement class="invisible absolute top-0 left-0 text-sm"></span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements AfterViewInit {
  @HostBinding('class.relative') relative = true;
  @HostBinding('class.my-1') margin = true;
  @ViewChild('searchElement', { static: true }) searchElement: ElementRef<HTMLInputElement>;
  @ViewChild('mirrorElement', { static: false }) mirrorElement: ElementRef<HTMLSpanElement>;
  @Output() readonly valueChange = new EventEmitter<string>();
  @Output() readonly focused = new EventEmitter<unknown>();
  value = '';

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  onValueChange(value: string): void {
    const inputDOM = this.searchElement.nativeElement;
    inputDOM.value = value;
    this.value = value;
    this.syncMirrorWidth();
    this.valueChange.next(this.value);
  }

  syncMirrorWidth(): void {
    const mirrorDOM = this.mirrorElement!.nativeElement;
    const hostDOM = this.elementRef.nativeElement;
    const inputDOM = this.searchElement.nativeElement;
    this.renderer.removeStyle(hostDOM, 'width');
    mirrorDOM.innerHTML = `${inputDOM.value}&nbsp;`;
    this.renderer.setStyle(hostDOM, 'width', `${mirrorDOM.scrollWidth}px`);
  }

  focus() {
    this.searchElement.nativeElement.focus();
  }

  ngAfterViewInit() {
    this.syncMirrorWidth();
  }

  onFocusedChange(value: boolean) {
    this.focused.next(value);
  }
}
