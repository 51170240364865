import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'playbook-tab-group-progressbar',
  styleUrls: ['./tab-group-progressbar.component.scss'],
  templateUrl: 'tab-group-progressbar.component.html',
})
export class TabGroupProgressbarComponent implements AfterContentInit {
  @Input() fullWidth = false;
  @Input() showLabels = true;
  @Input() onlyActiveStatus = false;
  @Input() label = '';
  @Input() navigationBackgroundColor = 'none';
  @Input() isModal = false;
  @Input() isModal1 = false;
  @Input() displayStyle = null;
  @Input() topRound = false;
  @Input() currentTab = 0;
  @Input() dealflow: any;
  @Input() dealflowDisplay = false;
  @Input() changeBlock = false;
  @Input() clickable = true;
  @Input() set activeTab(tabIndex: number) {
    if (typeof tabIndex === 'number') {
      this.currentTab = tabIndex;
    }
  }

  @Input() showToolbar = true;

  @Output() tabChange = new EventEmitter<string>();
  @Output() tabNotAllowed = new EventEmitter<string>();
  @Output() checkChanges = new EventEmitter<any>();

  currentTabName: any;

  shouldDisplay = false;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  ngAfterContentInit() {
    if (this.tabs.length === 0) {
      return;
    }

    this.tabs.first.shouldDisplay = true;
  }

  checkChange(tab, index) {
    if (this.dealflow) {
      const data = { tab: tab, index: index, currentTab: this.currentTab, currentTabName: this.currentTabName };
      this.checkChanges.emit(data);
    } else if (this.clickable) {
      this.showTab(tab, index);
    }
  }

  showTab(tab, index) {
    if (this.dealflowDisplay) {
      if (!this.changeBlock) {
        if (this.dealflow || tab.label === 'Essentials') {
          this.tabs.forEach((targetTab) => {
            if (targetTab.label === tab.label) {
              targetTab.shouldDisplay = true;
            } else {
              targetTab.shouldDisplay = false;
            }
          });

          this.tabChange.emit(tab.label);
          this.currentTabName = tab.label;
          this.currentTab = index;
        } else {
          this.tabNotAllowed.emit();
        }
      }
    } else {
      this.tabs.forEach((targetTab) => {
        if (targetTab.label === tab.label) {
          targetTab.shouldDisplay = true;
        } else {
          targetTab.shouldDisplay = false;
        }
      });
      this.currentTabName = tab.label;
      this.currentTab = index;
    }
  }

  getProgress() {
    if (this.fullWidth) {
      return (this.currentTab / (this.tabs.length - 1)) * 100;
    } else {
      let percent = (this.currentTab / (this.tabs.length - 1)) * 80;
      if (percent === 0) {
        percent += 80 / this.tabs.length / 2;
      }

      if (percent > 80) {
        percent = 80;
      }

      return percent;
    }
  }

  getLeft(status) {
    if (this.fullWidth) {
      const percent = (status / (this.tabs.length - 1)) * 100;
      if (percent >= 100) {
        return percent - 1.5;
      }
      return percent;
    } else {
      return (status / (this.tabs.length - 1)) * 100;
    }
  }

  updateDealflow(dealflow) {
    this.dealflow = dealflow;
  }
}
