import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';

@Pipe({
  name: 'sentReceived',
})
export class SentReceivedPipe implements PipeTransform {
  transform(email: string, fromField: string[]): any {
    const isSender = fromField.reduce((prev, curr) => {
      if (curr.includes(email)) {
        prev = true;
      }

      return prev;
    }, false);

    return isSender ? 'sent' : 'received';
  }
}
