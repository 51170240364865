import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'playbook-slider-vote',
  styleUrls: ['./slider-vote.component.scss'],
  templateUrl: 'slider-vote.component.html',
})
export class SliderVoteComponent implements AfterContentInit, OnChanges {
  saving = false;
  currentScore = '';

  @Input() value: number = null;
  @Input() criteria;
  @Output() voteCast = new EventEmitter<any>();

  ngAfterContentInit() {
    this.changeLabel(this.value);

    if (this.value === null) {
      this.value = -1;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changeLabel(changes.value.currentValue);

    if (this.value === null) {
      this.value = -1;
    }
  }

  changeLabel(value) {
    if (value === null || value === -1) {
      this.currentScore = 'You have not voted yet.';
      return;
    }

    this.currentScore = this.criteria.criteria_options.score_labels[value];
  }

  saveVote(event: any) {
    this.value = event.currentTarget.value;
    this.changeLabel(this.value);
    this.voteCast.emit(this.value);
  }

  showSliderValue(event: any) {
    this.currentScore = this.criteria.criteria_options.score_labels[event.currentTarget.value];
  }
}
