import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { ToasterComponent } from '../components/toaster.component';
import { v4 } from 'uuid';
import { Toast } from '../model/toast';

@Injectable()
export class ToasterService {
  toastCollection = new Map<string, MatSnackBarRef<ToasterComponent>>();
  constructor(private snackBar: MatSnackBar) {}

  pop(type: string, title: string, body?: string): Toast {
    const toastId = v4();
    const data = { toastId, type, title, body };
    const ref = this.snackBar.openFromComponent(ToasterComponent, { data });
    this.toastCollection.set(toastId, ref);
    return data;
  }

  clear(toastId: string) {
    if (this.toastCollection.has(toastId)) {
      const ref = this.toastCollection.get(toastId);
      ref.dismiss();
    }
  }
}
