import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Ability, AbilityBuilder } from '@casl/ability';
import { User } from 'app/models/user';
import { UserManager } from 'app/services/user-mananger.service';
import { environment } from 'environments/environment';
import { map, tap } from 'rxjs/operators';
import { SearchExplorerStore } from '../../search/state/search-explorer.store';
import { UserQuery } from '../state/user.query';
import { UserStore } from '../state/user.store';
import { UserRemote } from './user.remote';
import { CookieService } from 'ngx-cookie-service';

// TODO: Once everything is refactored remove userManager implementation
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private userRemote: UserRemote,
    private userStore: UserStore,
    private userQuery: UserQuery,
    private userManager: UserManager,
    private afAuth: AngularFireAuth,
    private ability: Ability,
    private searchStore: SearchExplorerStore,
    private cookieService: CookieService
  ) {}

  get store() {
    return this.userStore;
  }

  getUser() {
    return this.userRemote.getUser().pipe(
      map((user) => ({ full_name: `${user.first_name} ${user.last_name}`, ...user } as User)),
      map((user) => {
        user.recent_views = user.recent_views.filter((e) => !!e);
        return user;
      }),
      tap((user) => {
        this.userStore.update({
          ...user,
          token: this.cookieService.get(environment.cookieName),
        });
        this.userManager.updateUser(user);
        this.updateAbility(user);
        const { recent_searches, recent_views } = user;
        this.setSearchHistorial(recent_searches, recent_views);
      })
    );
  }

  currentUser() {
    return this.userQuery.currentUser;
  }

  get currentUser$() {
    return this.userQuery.currentUser$;
  }

  token() {
    return this.userQuery.token$;
  }

  watchToken() {
    this.afAuth.idToken.subscribe((token) => {
      this.userStore.update({ token });
      localStorage['token'] = token;
    });
    this.afAuth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        this.userStore.update({ token });
        // TODO: Remove on refactor
        localStorage['token'] = token;
      }
    });
  }

  signOut() {
    this.userRemote.signOut().subscribe(() => {
      localStorage.clear();
      this.ability.update([]);
      document.cookie = `${environment.cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.playbook.vc`;
      this.redirectToAuthentication();
    });
  }

  updateLastLogin() {
    const { email } = this.userStore.getValue();
    this.userRemote.updateLastLogin(btoa(email)).subscribe();
  }

  updateAbility(user: User) {
    //  TODO: Map all permissions to abilities. can('create'), or can('edit')
    //  would give better control on guards and would make easier the UI composition.

    const { can, rules } = new AbilityBuilder<Ability>();

    user.features.forEach((feature) => {
      can('read', feature);
    });

    [
      { feature: 'corporations', permission: 'corporation.list_corporations' },
      { feature: 'startups', permission: 'startup.list_startups' },
      { feature: 'private-startups', permission: 'startup.list_private_startups' },
      { feature: 'investors', permission: 'investors.view_investors' },
      { feature: 'collections', permission: 'collection.list_collections' },
    ].forEach((item) => {
      if (this.checkListPermissions(item.feature, user.features, item.permission, user.permissions)) {
        can('list', item.feature);
      }
    });

    [
      { feature: 'private-startups', permission: 'startup.add_privatestartup' },
      { feature: 'startups', permission: 'startup.add_startup' },
      { feature: 'corporations', permission: 'corporation.add_corporation' },
      { feature: 'collections', permission: 'collection.add_collection' },
      { feature: 'sheets', permission: 'smart_sheets.add_smartsheet' },
      { feature: 'dealflows', permission: 'dealflow.add_dealflow' },
      { feature: 'events', permission: 'event.add_event' },
      { feature: 'introductions', permission: 'introduction.add_introduction' },
      { feature: 'smart_alerts', permission: 'smart_alert.add_smartalert' },
      { feature: 'investments', permission: 'core.view_investment_tracker' },
      { feature: 'projects', permission: 'project.add_project' },
      { feature: 'relationships', permission: 'relationships.add_relationship' },
    ].forEach((item) => {
      if (this.checkListPermissions(item.feature, user.features, item.permission, user.permissions)) {
        can('create', item.feature);
      }
    });

    this.ability.update(rules);
  }

  checkListPermissions(feature: string, features: string[], permission: string, permissions: string[]) {
    return features.includes(feature) && permissions.includes(permission);
  }

  isStaff() {
    return this.userQuery.isStaff$;
  }

  hasPermission(permission: string) {
    return this.userQuery.hasPermission(permission);
  }

  search(query: string, company = null) {
    return this.userRemote.search(query, company);
  }

  setSearchHistorial(recent_searches: User['recent_searches'], recent_views: User['recent_views']) {
    this.searchStore.updateHistorial({
      recent_searches,
      recent_views: recent_views.map((recent_view) => ({
        ...recent_view,
        title: recent_view['source_object'].title,
        image:
          (recent_view['source_object'].image &&
            (recent_view['source_object'].image.thumb || recent_view['source_object'].image.url)) ||
          '',
      })),
    });
  }

  get canCreate() {
    return this.userQuery.canCreate();
  }

  get showMenuDescription() {
    return this.userQuery.showMenuDescription();
  }

  private redirectToAuthentication() {
    const { url, clientId, redirectUri } = environment.authentication;
    window.location.replace(`${url}/?clientId=${clientId}&redirectUri=${redirectUri}`);
  }

  public checkSession() {
    return this.userRemote.checkSession();
  }
}
