import { Component, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'playbook-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Output() file = new EventEmitter<string>();
  @Output() upload = new EventEmitter<FormData>();
  @Output() error = new EventEmitter<any>();
  @Output() progress = new EventEmitter<number | boolean>();

  constructor(private http: HttpClient) {}

  uploadFile(files) {
    const file = files[0];

    if (file.size > 20 * 1000 * 1000) {
      this.error.emit({ message: 'File is too large.' });
      return;
    }

    const acceptedTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'video/mpeg',
      'video/webm',
      'application/pdf',
      'text/plain',
      'text/html',
      'text/csv',
      'image/svg+xml',
      'video/ogg',
      'application/msword',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (acceptedTypes.includes(file.type) === false) {
      this.error.emit({ message: 'Invalid file type.' });
      return;
    }

    const fd = new FormData();
    fd.append('url', file);

    this.upload.emit(fd);
  }
}
