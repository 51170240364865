import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { BodyService } from './services/body.service';
import { IntroductionModalService } from './services/introduction-modal.service';
import { GlobalTitleService } from './services/title.service';
import { UserManager } from './services/user-mananger.service';
import { IntroductionModalComponent } from './shared/components/introduction-modal/introduction-modal.component';
import { OverlayComponent } from './shared/components/overlay/overlay.component';

declare var gtag: any;

@Component({
  selector: 'playbook-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  company: any;
  private sub = new Subscription();

  @ViewChild('introductionOverlay', { static: true }) introductionOverlay: IntroductionModalComponent;
  @ViewChild('uploadProfilePicOverlay', { static: true }) uploadProfilePicOverlay: OverlayComponent;

  constructor(
    private router: Router,
    public userManager: UserManager,
    @Inject(PLATFORM_ID) private platformId: Object,
    private globalTitleService: GlobalTitleService,
    private activatedRoute: ActivatedRoute,
    private bodyService: BodyService,
    private route: ActivatedRoute,
    private introductionModalService: IntroductionModalService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      if (isPlatformBrowser(this.platformId)) {
        window.scroll(0, 0);
      }

      if (typeof gtag !== 'undefined' && gtag) {
        const routerEvent = event as NavigationEnd;
        gtag('config', 'UA-28295056-5', { page_path: routerEvent.urlAfterRedirects });
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        if (!data['setsOwnTitle']) {
          if (!!data['title']) {
            this.globalTitleService.setTitle('Playbook - ' + data['title']);
          } else {
            this.globalTitleService.setTitle('Playbook');
          }
        }
      });

    this.introductionModalService
      .watch()
      .pipe(
        filter((message) => {
          return message.type === 'openModal';
        })
      )
      .subscribe((_) => {
        this.introductionOverlay.openOverlay();
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    this.bodyService.click(event);
  }
}
