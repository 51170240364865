import { Component, OnInit, Input } from '@angular/core';
import { Corporation } from '../../../../models/corporation';

@Component({
  selector: 'playbook-corporation-result-card',
  templateUrl: './corporation-result-card.component.html',
  styleUrls: ['./corporation-result-card.component.scss'],
})
export class CorporationResultCardComponent implements OnInit {
  @Input() corporation: Corporation;

  constructor() {}

  ngOnInit() {}
}
