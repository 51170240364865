import {
  Component,
  Output,
  Input,
  EventEmitter,
  HostListener,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { BodyService } from '../../../services/body.service';
import { Subscription } from 'rxjs';

// TODO: We need to refactor this component
@Component({
  selector: 'playbook-drop-menu',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DropMenuComponent implements OnDestroy {
  listOpen = false;
  private stopProp = false;
  @Input() alignMenu = 'right';
  @Input() noPadding = false;
  @Input() option1 = false;
  @Input() widthOption1 = false;
  @Input() class = '';
  sub: Subscription = null;

  constructor(
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private bodyService: BodyService
  ) {
    changeDetector.detach();
    this.sub = this.bodyService.watchClick().subscribe((event) => {
      if (this.listOpen === false) {
        return;
      }

      if (event.srcElement === this.elementRef || this.elementRef.nativeElement.contains(event.srcElement)) {
        return;
      }

      this.listOpen = false;
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  toggleList(event?) {
    this.listOpen = !this.listOpen;
    this.changeDetector.detectChanges();
  }
}
