import { Component, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { OverlayComponent } from '../overlay/overlay.component';
import { CustomTag } from '../../../models/custom-tag';
import { CustomTagService } from '../../../services/custom-tag.service';

@Component({
  selector: 'playbook-custom-tag-modal',
  styleUrls: ['./custom-tag-modal.component.scss'],
  templateUrl: 'custom-tag-modal.component.html',
})
export class CustomTagModalComponent implements AfterViewInit {
  canAutoFocus = false;
  charactersRemaining = null;
  maxCharacters = 30;
  filters: any;
  loading = false;
  companyId: any;
  type: any;
  serviceCount: any;
  removeFilterObj: any;

  @Input() displayValue = '';
  @Input() modalTitle = '';
  @Input() filterTitle = '';
  @Output() filterUpdate = new EventEmitter<any>();

  @ViewChild('EditFilterModal', { static: true }) EditFilterModal: OverlayComponent;
  @ViewChild('deleteTagModal', { static: true }) deleteTagModal: OverlayComponent;

  constructor(private customTagService: CustomTagService) {}

  ngAfterViewInit() {
    this.canAutoFocus = true;
  }

  openOverlay(id, type) {
    this.filters = [];
    this.EditFilterModal.overlayOpen = true;
    this.companyId = id;
    this.type = type;

    this.loading = true;

    this.customTagService.getCustomTags(id, this.type).subscribe((data) => {
      this.loading = false;
      this.filters = data.results;
      this.filters.push(new CustomTag());
    });
  }

  closeOverlay() {
    this.EditFilterModal.overlayOpen = false;
  }

  saveFilter() {
    this.loading = true;
    this.serviceCount = 0;
    this.filters.forEach((filter) => {
      this.serviceCount++;
      if (!filter[this.displayValue]) {
        this.removeFilter(filter, true);
      } else if (filter[this.displayValue].trim().length === 0) {
        this.removeFilter(filter, true);
      } else {
        let serviceCall = null;
        if (filter.id) {
          serviceCall = this.customTagService.editCustomTags(filter, this.companyId, this.type);
        } else {
          serviceCall = this.customTagService.addCustomTags(filter, this.companyId, this.type);
        }

        serviceCall.subscribe(
          (error) => {
            this.serviceCount--;
            this.checkServiceFinish();
          },
          () => {
            this.serviceCount--;
            this.checkServiceFinish();
          }
        );
      }
    });
    this.checkServiceFinish();
  }

  checkServiceFinish() {
    if (this.serviceCount < 1) {
      this.filterUpdate.emit();
      this.closeOverlay();
    }
  }

  removeFilter(filter?, save?) {
    if (!filter) {
      filter = this.removeFilterObj;
    }
    if (filter.id) {
      this.customTagService.removeCustomTags(filter, this.companyId, this.type).subscribe(
        (error) => {
          if (save) {
            this.serviceCount--;
            this.checkServiceFinish();
          }
        },
        () => {
          if (save) {
            this.serviceCount--;
            this.checkServiceFinish();
          }
        }
      );
    } else {
      this.serviceCount--;
    }
    this.filters.splice(this.filters.indexOf(filter), 1);
    this.deleteTagModal.overlayOpen = false;
  }

  checkNextField($event, filter) {
    const filterIndex = this.filters.indexOf(filter);
    if ($event.code === 'Enter' && filterIndex === this.filters.length - 1) {
      this.filters.push(new CustomTag());
      this.getCharactersRemaining('');
    }
  }

  getCharactersRemaining(filterName: string) {
    if (filterName) {
      this.charactersRemaining = this.maxCharacters - filterName.length;
    }
  }
}
