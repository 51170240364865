import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { User } from 'app/models/user';
import { UserStore } from './user.store';
import { Ability } from '@casl/ability';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<User> {
  currentUser$ = this.select((state) => state);
  isStaff$ = this.select((state) => state.is_staff);
  token$ = this.select((state) => state.token);

  constructor(protected store: UserStore, private ability: Ability) {
    super(store);
  }

  get currentUser() {
    return this.getValue();
  }

  get token() {
    return this.getValue().token;
  }

  hasPermission(permission: string) {
    return (this.getValue() as User).permissions.some((p) => p === permission);
  }

  canCreate() {
    return this.select((state) => state.features.some((feature) => this.ability.can('create', feature)));
  }

  showMenuDescription() {
    return this.select((state) =>
      state.permissions.some((permission) => permission === 'core.view_navigation_descriptions')
    );
  }
}
