import { Injectable } from '@angular/core';
import { Country } from '@playbook/models';
import { map } from 'rxjs/operators';
import { CountryQuery } from '../state/country.query';
import { CountryStore } from '../state/country.store';
import { CountryRemote } from './country.remote';

@Injectable({ providedIn: 'root' })
export class CountryService {
  constructor(
    private countryRemote: CountryRemote,
    private countryStore: CountryStore,
    private countryQuery: CountryQuery
  ) {}

  fetchAll() {
    this.countryRemote
      .fetch()
      .pipe(map((data) => data.results))
      .subscribe((countries) => {
        this.countryStore.set(countries);
      });
  }

  getCountries() {
    return this.countryQuery.selectAll();
  }

  getCountry(id: number) {
    return this.countryQuery.getEntity(id);
  }

  get countries() {
    return this.countryQuery.getAll();
  }

  getCountryByName(countryName: string) {
    return this.countries.find((country) => country.country_name.toLowerCase().includes(countryName.toLowerCase()));
  }

  search(country: string) {
    return this.countryQuery.selectAll({
      filterBy: (entity) => entity.country_name.toLowerCase().includes(country.toLowerCase()),
    });
  }
}
