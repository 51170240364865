import { Pipe, PipeTransform } from '@angular/core';
import { Tag } from '../models/tag';

@Pipe({
  name: 'verticalColorTags',
})
export class VerticalColorTagsPipe implements PipeTransform {
  transform(tags: Tag[]): any {
    const verticals = tags.map((tag) => {
      return tag.vertical;
    });

    const verticalOccurances = {};
    let compare = 0;
    let frequent;

    verticals.forEach((vertical) => {
      if (vertical === null) {
        return;
      }

      if (verticalOccurances[vertical.color] === undefined) {
        verticalOccurances[vertical.color] = 1;
      } else {
        verticalOccurances[vertical.color] += 1;
      }

      if (verticalOccurances[vertical.color] > compare) {
        compare = verticalOccurances[vertical.color];
        frequent = vertical;
      }
    });

    if (!!frequent && !!frequent.color) {
      return frequent.color;
    }

    return false;
  }
}
