import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Dealflow, DealflowAgenda, DealflowAttendee, ResultSet } from '@playbook/models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DealflowService {
  constructor(private http: HttpClient) {}

  getAll(status: number, own?: boolean, page?: number, limit?: number) {
    let httpParams = new HttpParams();

    if (!limit) {
      limit = 40;
    }

    if (!page) {
      page = 0;
    }

    const offset = page * limit;

    httpParams = httpParams.append('dealflow_status', `${status}`);
    httpParams = httpParams.append('limit', `${limit}`);
    httpParams = httpParams.append('offset', `${offset}`);

    if (own) {
      httpParams = httpParams.append('own', '1');
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/', { params: httpParams });
  }

  getUpcoming() {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/upcoming/');
  }

  get(id: number) {
    return this.http.get<Dealflow>(environment.apiBaseUrl + 'dealflow/' + id + '/');
  }

  getStartups(id: number, limit?: number) {
    if (!limit) {
      limit = 40;
    }
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/' + id + '/startups/?limit=' + limit);
  }

  addStartupToDealflow(dealflowId: number, startupId: number, position: number) {
    return this.http.post<any>(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/startups/', {
      startup: startupId,
      position: position,
    });
  }

  deleteStarupFromDealflow(dealflowId: number, startupId: number) {
    return this.http.delete(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/startups/' + startupId + '/');
  }

  reorderStartup(dealflowId: number, itemId: number, index: number) {
    return this.http.put(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/startups/' + itemId + '/reorder/', {
      to_index: index,
    });
  }

  setStartupPriority(dealflowId: number, itemId: number, priority: number) {
    return this.http.patch(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/startups/' + itemId + '/', {
      priority: priority,
    });
  }

  create(dealflow: any) {
    const data = {};
    const keys = Object.getOwnPropertyNames(dealflow);

    keys.forEach((key) => {
      if (key === 'vertical') {
        data['vertical'] = dealflow.vertical.map(function (vertical) {
          return vertical.id;
        });
      } else if (key === 'tags') {
        data['tags'] = dealflow.tags.map(function (tag) {
          return tag.id;
        });
      } else if (key === 'corporation') {
        data['corporation'] = dealflow.corporation.id;
      } else if (key === 'office') {
        data['office'] = dealflow.office.id;
      } else {
        data[key] = dealflow[key];
      }
    });

    return this.http.post<Dealflow>(environment.apiBaseUrl + 'dealflow/', data);
  }

  edit(dealflow: any, id: any) {
    const data = {};
    const keys = Object.getOwnPropertyNames(dealflow);

    keys.forEach((key) => {
      if (key === 'vertical') {
        data['vertical'] = dealflow.vertical.map(function (vertical) {
          return vertical.id;
        });
      } else if (key === 'tags') {
        data['tags'] = dealflow.tags.map(function (tag) {
          return tag.id;
        });
      } else if (key === 'corporation') {
        data['corporation'] = dealflow.corporation.id;
      } else if (key === 'office') {
        data['office'] = dealflow.office.id;
      } else {
        data[key] = dealflow[key];
      }
    });

    return this.http.patch<Dealflow>(environment.apiBaseUrl + 'dealflow/' + id + '/', data);
  }

  getAgenda(dealflowId) {
    const url = environment.apiBaseUrl + 'dealflow/' + dealflowId + '/agenda/';

    return this.http.get<ResultSet>(url);
  }

  saveTimeslot(dealflowId, agendaItem) {
    const url = environment.apiBaseUrl + 'dealflow/' + dealflowId + '/agenda/';

    const formatStart = agendaItem.start_time.getHours() + ':' + agendaItem.start_time.getMinutes();
    const formatEnd = agendaItem.end_time.getHours() + ':' + agendaItem.end_time.getMinutes();
    agendaItem.start_time = formatStart;
    agendaItem.end_time = formatEnd;

    if (agendaItem.id) {
      return this.http.patch<DealflowAgenda>(url + agendaItem.id + '/', agendaItem);
    }

    return this.http.post<DealflowAgenda>(url, agendaItem);
  }

  removeTimeslot(dealflowId, timeslotId) {
    const url = environment.apiBaseUrl + 'dealflow/' + dealflowId + '/agenda/' + timeslotId + '/';

    return this.http.delete(url);
  }

  updateFeedback(dealflowId, startupId, feedback) {
    const url = environment.apiBaseUrl + 'dealflow/' + dealflowId + '/startups/' + startupId + '/feedback/';

    return this.http.post(url, feedback);
  }

  getFeedback(dealflowId) {
    const url = environment.apiBaseUrl + 'dealflow/' + dealflowId + '/feedback/';

    return this.http.get<ResultSet>(url);
  }

  search(searchValue: string) {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('search', searchValue);
    httpParams = httpParams.append('limit', '100');
    httpParams = httpParams.append('offset', '0');

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/', { params: httpParams });
  }

  searchFilter(predicates: any) {
    let httpParams = new HttpParams();

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];

      if (value == null || value.length === 0) {
        return;
      }

      httpParams = httpParams.append(key, `${predicates[key]}`);
    });

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/', { params: httpParams });
  }

  getPreviousDealflows(dealflowId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/previous/');
  }

  copyFromCollection(dealflowId: number, collectionId: number) {
    return this.http.post<ResultSet>(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/startups/from_collection/', {
      collection_id: collectionId,
    });
  }

  sendCustomEmail(dealflowId: number, startupId, emailId, data) {
    data.to = data.to.split(', ');

    if (data.cc && data.cc.length > 1) {
      data.cc = data.cc.split(', ');
    } else {
      data.cc = [];
    }

    const body = {
      subject: data.subject,
      body: data.body,
      to: data.to,
      from: data.from,
      cc: data.cc,
      email_type: data.email_type,
      link: data.link,
    };

    return this.http.post<any>(
      environment.apiBaseUrl +
        'dealflow/' +
        dealflowId +
        '/startups/' +
        startupId +
        '/email/' +
        emailId +
        '/custom_mail/',
      body
    );
  }

  saveCustomEmail(dealflowId: number, startupId, emailId, data) {
    data.to = data.to.split(', ');

    if (data.cc && data.cc.length > 1) {
      data.cc = data.cc.split(', ');
    } else {
      data.cc = [];
    }

    const body = {
      subject: data.subject,
      body: data.body,
      to: data.to,
      from: data.from,
      cc: data.cc,
      email_type: data.email_type,
    };

    return this.http.post(
      environment.apiBaseUrl +
        'dealflow/' +
        dealflowId +
        '/startups/' +
        startupId +
        '/email/' +
        emailId +
        '/save_mail/',
      body
    );
  }

  updateRoom(dealflowId: number, room: string) {
    return this.http.patch<Dealflow>(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/', {
      chosen_room: room,
    });
  }

  addAttendee(dealflowId: number, attendee: DealflowAttendee) {
    const data = {};
    const keys = Object.getOwnPropertyNames(attendee);

    keys.forEach((key) => {
      if (key === 'attendee') {
        data['attendee'] = attendee.attendee.id;
      } else {
        data[key] = attendee[key];
      }
    });

    const url = environment.apiBaseUrl + 'dealflow/' + dealflowId + '/attendees/';

    return this.http.post<DealflowAttendee>(url, data);
  }

  editAttendee(dealflowId: number, attendee: DealflowAttendee) {
    const data = {};
    const keys = Object.getOwnPropertyNames(attendee);

    keys.forEach((key) => {
      if (key === 'attendee') {
        data['attendee'] = attendee.attendee.id;
      } else {
        data[key] = attendee[key];
      }
    });

    return this.http.patch<DealflowAttendee>(
      environment.apiBaseUrl + 'dealflow/' + dealflowId + '/attendees/' + attendee.id + '/',
      data
    );
  }

  getAttendees(dealflowId: number) {
    const url = environment.apiBaseUrl + 'dealflow/' + dealflowId + '/attendees/';

    return this.http.get<ResultSet>(url);
  }

  removeAttendee(dealflowId, attendee) {
    return this.http.delete(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/attendees/' + attendee.id + '/');
  }

  selectTimeslot(dealflowId: number, timeslotId: number, startupId: number) {
    return this.http.post(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/agenda/' + timeslotId + '/select/', {
      startup: startupId,
    });
  }

  notAttending(dealflowId: number, startupId: number) {
    return this.http.post(
      environment.apiBaseUrl + 'dealflow/' + dealflowId + '/startups/' + startupId + '/not_attending/',
      {}
    );
  }

  getStartupTimeslot(dealflowId: number, timeslotId: number) {
    return this.http.get<ResultSet>(
      environment.apiBaseUrl + 'dealflow/' + dealflowId + '/agenda/' + timeslotId + '/invitation/'
    );
  }

  getStartupTimeslotAll(dealflowId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/invitation/');
  }

  addStartupToTimeslot(dealflowId: number, startupId: number, timeslotId: number) {
    const data = {
      dealflow: dealflowId,
      dealflow_agenda: timeslotId,
      startup: startupId,
      invitation_status: 'Pending',
    };

    const url = environment.apiBaseUrl + 'dealflow/' + dealflowId + '/agenda/' + timeslotId + '/invitation/';

    return this.http.post<any>(url, data);
  }

  removeTimeslotStartup(dealflowId: number, timeslotId: number, invitationId: number) {
    return this.http.delete(
      environment.apiBaseUrl + 'dealflow/' + dealflowId + '/agenda/' + timeslotId + '/invitation/' + invitationId
    );
  }

  getActivity(dealflowId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/activity/');
  }

  makeActivityComment(dealflowId: number, comment: string) {
    return this.http.post(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/activity/', {
      comment: comment,
    });
  }

  getDealflowMails(dealflowId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/email/');
  }

  getDealflowMail(dealflowId: number, mailId: number) {
    return this.http.get<any>(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/email/' + mailId + '/get_single/');
  }

  sendDealflowStepEmail(dealflowId, current_user, startup, next_step, link) {
    const data = {
      to: current_user,
      startup: startup.startup,
      next_step: next_step,
      link: link,
    };
    return this.http.post(environment.apiBaseUrl + 'dealflow/' + dealflowId + '/dealflow_internal_email/', data);
  }

  private formatEmailMessage(body) {
    const tmp = body.split('\n');
    body = '';
    let counter = tmp.length;
    tmp.forEach((element) => {
      if (counter > 1) {
        body += element + '<br>';
        counter--;
      } else {
        body += element;
      }
    });
    return body;
  }
}
