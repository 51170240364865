import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { Startup } from '../../../models/startup';

@Component({
  selector: 'playbook-tab',
  styleUrls: ['./tab.component.scss'],
  templateUrl: 'tab.component.html',
})
export class TabComponent {
  @Input() label: String = '';
  shouldDisplay = false;
  @Input() disabled = false;
  @Input() success = false;
  @Input() disabledTooltipText = false;
  @Output() didDisplay = new EventEmitter<boolean>();

  display() {
    this.shouldDisplay = true;
    this.didDisplay.emit(true);
  }
}
