import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'playbook-radio-vote',
  styleUrls: ['./radio-vote.component.scss'],
  templateUrl: 'radio-vote.component.html',
})
export class RadioVoteComponent {
  saving = false;
  hoverRating: number = null;

  @Input() value: number = null;
  @Input() criteria;
  @Output() voteCast = new EventEmitter<any>();

  saveVote(value) {
    this.value = +value;
    this.voteCast.emit(this.value);
  }
}
