import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { OnDestroy, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AppInjector } from 'app/app.injector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoClearComponent } from '../base/auto-clear.component';

export class PlaybookDialog<T> extends AutoClearComponent implements OnInit, OnDestroy {
  private originalWidth = '0';
  private originalHeight = '0';
  private injector = AppInjector.getInjector();
  private document: Document;
  renderer: Renderer2;

  constructor(public dialogRef: MatDialogRef<T>) {
    super();
    this.document = this.injector.get(DOCUMENT);
    // tslint:disable-next-line: deprecation
    const rendererFactory = this.injector.get(RendererFactory2);
    this.renderer = rendererFactory.createRenderer(null, null);
    this.enableBreakpointerObserver();
    this.saveOriginalConfig();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
  }

  ngOnDestroy() {
    this.renderer.removeStyle(this.document.body, 'overflow');
    super.ngOnDestroy();
  }

  private enableBreakpointerObserver() {
    const breakpointObserver = this.injector.get(BreakpointObserver);
    breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result.matches) {
          this.dialogRef.updateSize('100%', '100%');
        } else {
          this.dialogRef.updateSize(this.originalWidth, this.originalHeight);
        }
      });
  }

  private saveOriginalConfig() {
    this.originalWidth = this.dialogRef._containerInstance._config.width;
    this.originalHeight = this.dialogRef._containerInstance._config.height;
  }
}
