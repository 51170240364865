import { Component, OnInit, ViewChild } from '@angular/core';
import { Corporation } from '../../../models/corporation';
import { Startup } from '../../../models/startup';
import { CorporationService } from '../../../modules/corporations/corporations.service';
import { StartupService } from '../../../modules/startups/startups.service';
import { Subscription, Observable } from 'rxjs';
import { IntroductionService } from '../../../services/introduction.service';
import { IntroductionModalService } from '../../../services/introduction-modal.service';
import { throttleTime } from 'rxjs/operators';
import { Office } from '../../../models/office';
import { Vertical } from '../../../models/vertical';
import { OfficeService } from 'app/modules/offices/services/office.service';
import { VerticalService } from 'app/modules/verticals/services/vertical.service';
import { OverlayComponent } from '../overlay/overlay.component';
import { ToasterService } from 'app/shared/modules/toaster/services/toaster.service';

@Component({
  selector: 'playbook-introduction-modal',
  templateUrl: './introduction-modal.component.html',
  styleUrls: ['./introduction-modal.component.scss'],
  providers: [IntroductionService],
})
export class IntroductionModalComponent implements OnInit {
  notIntroducted = true;
  checking = false;
  creating = false;
  checked = false;
  created = false;
  date: Date;
  startDateShow = { day: null, month: null, year: null, monthShow: null };
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  startup: Startup = null;
  startups: Startup[] = [];
  corporation: Corporation = null;
  corporations: Corporation[] = [];
  searchingStartup: Subscription = null;
  searchingCorporation: Subscription = null;
  vertical = null;
  office = null;
  verticals$: Observable<Vertical[]>;
  offices$: Observable<Office[]>;
  @ViewChild('introductionModal', { static: true }) introductionModal: OverlayComponent;

  constructor(
    private toasterService: ToasterService,
    private startupService: StartupService,
    private corporationService: CorporationService,
    private introductionService: IntroductionService,
    private introductionModalService: IntroductionModalService,
    private verticalService: VerticalService,
    private officeService: OfficeService
  ) {}

  ngOnInit() {
    this.offices$ = this.officeService.getOffices();
    this.verticals$ = this.verticalService.getVerticals();
  }

  openOverlay() {
    this.introductionModal.overlayOpen = true;
    this.created = false;
    this.startup = null;
    this.corporation = null;
    this.notIntroducted = true;
    this.corporations = [];
    this.startups = [];
    this.startDateShow = { day: null, month: null, year: null, monthShow: null };
  }

  searchStartups(searchTerm) {
    if (searchTerm.trim().length === 0) {
      return;
    }

    if (this.searchingStartup) {
      this.searchingStartup.unsubscribe();
    }

    this.searchingStartup = this.startupService
      .advancedSearch({ terms: [{ field: '*', term: searchTerm }], filters: {} })
      .subscribe((res) => {
        this.startups = res.results;
      });
  }

  searchCorporations(searchTerm) {
    if (searchTerm.trim().length === 0) {
      return;
    }

    if (this.searchingCorporation) {
      this.searchingCorporation.unsubscribe();
    }

    this.searchingCorporation = this.corporationService
      .searchFilter({ search: searchTerm }, 'company_name')
      .subscribe((res) => {
        this.corporations = res.results;
      });
  }

  setStartup(startup: Startup) {
    this.startup = startup;
    this.checkIntroduction();
  }

  setCorporation(corporation: Corporation) {
    this.corporation = corporation;
    this.checkIntroduction();
  }

  checkIntroduction() {
    if (!this.startup || !this.corporation) {
      this.checking = false;
      return;
    }

    this.checked = false;
    this.checking = true;

    this.introductionService.check(this.startup.id, this.corporation.id).subscribe((res) => {
      this.checked = true;
      this.checking = false;
      this.notIntroducted = !res.exists;
    });
  }

  createIntroduction() {
    this.creating = true;

    this.introductionService
      .create(this.startup.id, this.corporation.id, {
        date: this.date,
        vertical: this.vertical ? this.vertical.id : null,
        office: this.office ? this.office.id : null,
      })
      .subscribe(
        (res) => {
          this.created = true;
          this.creating = false;
          this.introductionModalService.send({
            type: 'newIntroduction',
            payload: res,
          });
        },
        (err) => {
          this.toasterService.pop(
            'error',
            'Could not create introduction',
            'There was a problem creating the introduction. Please try again.'
          );
          this.creating = false;
        }
      );
  }

  setStartAt() {
    const date = new Date();
    this.date = date;
    if (this.startDateShow.day && this.startDateShow.month && this.startDateShow.year) {
      date.setDate(1);
      date.setMonth(this.startDateShow.month - 1);
      date.setDate(this.startDateShow.day);
      date.setFullYear(this.startDateShow.year);
    }
    return date;
  }

  splitDate(date) {
    const newDate = new Date(date);
    this.date = newDate;
    this.startDateShow.day = newDate.getDate();
    this.startDateShow.month = newDate.getMonth() + 1;
    this.startDateShow.monthShow = this.months[newDate.getMonth()];
    this.startDateShow.year = newDate.getFullYear();
  }
}
