import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { SearchItem } from '@playbook/modules/search/models/search-explorer';
import { AutoClearComponent } from '@playbook/shared/components/base/auto-clear.component';
import { ExtendedInfoService } from '@playbook/modules/search/services/extended-info.service';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'playbook-search-extended-info',
  templateUrl: './search-extended-info.component.html',
  styleUrls: ['./search-extended-info.component.scss'],
})
export class SearchExtendedInfoComponent extends AutoClearComponent implements OnInit {
  @Input() searchItem: SearchItem;

  portalInstance = null;

  constructor(private extendedInfoService: ExtendedInfoService) {
    super();
  }

  ngOnInit(): void {
    this.extendedInfoService
      .listen()
      .pipe(
        takeUntil(this.destroy$),
        filter((data) => data !== this)
      )
      .subscribe((res) => {
        this.close();
      });
  }

  close() {
    this.portalInstance.detach();
  }
}
