import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'playbook-profile-card',
  styleUrls: ['./profile-card.component.scss'],
  templateUrl: './profile-card.component.html',
})
export class ProfileCardComponent {
  constructor(private router: Router) {}
  @Input() profile: any;
  @Input() displayValue = 'name';
  @Input() profileDisplay = false;

  @Input() profilePicture = true;
  @Input() userProfile = false;
  @Input() allowEdit = true;
  @Input() allowRemove = true;
  @Input() allowOpenProfile = true;

  @Output() remove = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() openProfile = new EventEmitter<any>();
  @Output() profileSelected = new EventEmitter<any>();

  editProfile() {
    this.edit.emit(this.profile);
  }

  removeProfile() {
    this.remove.emit(this.profile);
  }

  navigateToProfile() {
    this.openProfile.emit(this.profile);
  }

  selectProfile() {
    this.profileSelected.emit(this.profile);
  }
}
