import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Domain } from '../models/domain';

@Injectable()
export class DomainsService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all offices, can be filtered by country.
   *
   * @param officeId optional country ID for filtering
   * @returns Observable<ResultSet>
   */

  createDomain(domain) {
    return this.http.post<Domain>(environment.apiBaseUrl + 'domains/', domain);
  }

  updateDomain(domain: Domain) {
    return this.http.patch<Domain>(environment.apiBaseUrl + 'domains/' + domain.id + '/', domain);
  }

  deleteDomain(domain: Domain) {
    return this.http.delete(environment.apiBaseUrl + 'domains/' + domain.id + '/');
  }

  searchDomain(predicates: object, sort: string) {
    let httpParams = new HttpParams();

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];

      if (value == null || value.length === 0) {
        return;
      }

      httpParams = httpParams.append(key, `${predicates[key]}`);
    });

    if (sort && sort.length > 0) {
      httpParams = httpParams.append('ordering', sort);
    }

    return this.http.get<any>(environment.apiBaseUrl + 'domains/search/', { params: httpParams });
  }

  /**
   * Check the availability of a domain in playbook database.
   * @param domain domain to check
   * @returns whether the domain is available in playbook or not.
   */
  checkDomainAvailability(domain: string): Observable<boolean> {
    if (!domain || domain.length < 5 || !domain.includes('.') || domain[domain.length - 1] === '.') {
      return of(false);
    }

    const availableDomain$ = this.searchDomain({ domain_name: domain }, null).pipe(
      take(1),
      map((startups: any[]) => startups.length === 0)
    );
    const allowedDomain$ = this.http
      .get(environment.apiBaseUrl + 'unallowed-domains/search_domain/?domain=' + domain)
      .pipe(
        take(1),
        map((res) => !res['found'])
      );
    return forkJoin([availableDomain$, allowedDomain$]).pipe(
      take(1),
      map(([available, allowed]) => available && allowed)
    );
  }
}
