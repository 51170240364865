import { Component, OnInit, Input } from '@angular/core';
import { Startup } from '../../../../models/startup';

@Component({
  selector: 'playbook-startup-result-card',
  templateUrl: './startup-result-card.component.html',
  styleUrls: ['./startup-result-card.component.scss'],
})
export class StartupResultCardComponent implements OnInit {
  @Input() startup: Startup;

  constructor() {}

  ngOnInit() {}
}
