import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchDto, SearchPayload, SearchResult, SearchResultItem } from '../models/search-explorer';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class SearchExplorerRemote {
  private searchBaseUrl = `${environment.apiUrl}v2/search/`;

  constructor(private http: HttpClient) {}

  search(payload: SearchDto) {
    return this.http.post<SearchResult>(this.searchBaseUrl, payload);
  }

  searchStartups(query: string, order_by: string, page: number, perPage: number) {
    const payload: SearchPayload = {
      max_results: perPage,
      page: page + 1,
      query: query,
      order_by: order_by,
    };
    return this.http.post<SearchResultItem>(`${this.searchBaseUrl}startups/`, payload);
  }

  searchCollections(query: string, order_by: string) {
    const payload: SearchPayload = {
      query: query,
      order_by: order_by,
    };
    return this.http.post<SearchResultItem>(`${this.searchBaseUrl}collections/`, payload);
  }

  removeRecentSearch() {
    return this.http.delete(`${environment.apiUrl}v2/recent-searches/`);
  }

  removeRecentView() {
    return this.http.delete(`${environment.apiUrl}v2/recent-searches/`);
  }
}
