import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultSet, Tag } from '@playbook/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TagsRemote {
  constructor(private http: HttpClient) {}

  fetch(): Observable<ResultSet<Tag>> {
    const params = new HttpParams().set('limit', '1000');
    return this.http.get<ResultSet<Tag>>(`${environment.apiBaseUrl}tags/`, { params });
  }
}
