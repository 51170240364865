import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  Relationship,
  RelationshipActivity,
  RelationshipDto,
  RelationshipFeedback,
  RelationshipInteraction,
  ResultSet,
} from '@playbook/models';
import { Observable } from 'rxjs';
import { SmartSheet } from '@playbook/modules/smart-sheets/models/smart-sheet';

@Injectable({
  providedIn: 'root',
})
export class RelationshipService {
  private baseUrl = `${environment.apiBaseUrl}relationships/`;

  constructor(private http: HttpClient) {}

  getTrackers() {
    return this.http.get<ResultSet<SmartSheet>>(`${this.baseUrl}tracker`);
  }

  get(id: number): Observable<Relationship> {
    return this.http.get<Relationship>(`${this.baseUrl}${id}/`);
  }

  patch(id: number, updates: Partial<Relationship>): Observable<Relationship> {
    return this.http.patch<Relationship>(`${this.baseUrl}${id}/`, updates);
  }

  create(payload: RelationshipDto): Observable<Relationship> {
    return this.http.post<Relationship>(this.baseUrl, payload);
  }

  delete(id: number): Observable<Relationship> {
    return this.http.delete<Relationship>(`${this.baseUrl}${id}/`);
  }

  uploadFile(relationshipId: number, formData: FormData) {
    return this.http.post(`${this.baseUrl}${relationshipId}/files/`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteFile(relationshipId: number, fileId: number) {
    return this.http.delete(`${this.baseUrl}${relationshipId}/files/${fileId}/`);
  }

  postReview(relationshipId: number, interactionId: number, payload: Partial<RelationshipFeedback>) {
    return this.http.post<RelationshipFeedback>(
      `${this.baseUrl}${relationshipId}/interactions/${interactionId}/feedback`,
      payload
    );
  }

  editReview(relationshipId: number, interactionId: number, reviewId: number, payload: Partial<RelationshipFeedback>) {
    return this.http.patch<RelationshipFeedback>(
      `${this.baseUrl}${relationshipId}/interactions/${interactionId}/feedback/${reviewId}`,
      payload
    );
  }

  postUpdate(relationshipId: number, message: string) {
    return this.http.post<RelationshipActivity>(`${this.baseUrl}${relationshipId}/activity/`, {
      values: message,
    });
  }
}
