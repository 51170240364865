import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class IntroductionModalService {
  private messages: Subject<any> = new Subject();

  watch() {
    return this.messages;
  }

  send(message) {
    this.messages.next(message);
  }
}
