import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GlobalTitleService } from './title.service';

@Injectable()
export class NotificationCountService {
  count: Observable<number>;
  private _observer: Observer<number>;

  constructor(private globalTitleService: GlobalTitleService) {
    this.count = new Observable<number>((observer) => {
      this._observer = observer;
    });
  }

  updateCount(count?: number, page?: string) {
    this.globalTitleService.updateNotifcations(count, page);
  }

  resetCount() {
    this.globalTitleService.resetNotificationCount();
  }
}
