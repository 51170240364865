import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  isMobile = false;

  constructor(private breakpointObserver: BreakpointObserver) {}

  get isMobile$() {
    return this.breakpointObserver.observe('(max-width: 900px)').pipe(
      map((state) => state.matches),
      tap((isMobile) => (this.isMobile = isMobile))
    );
  }
}
