import { Tag } from '@playbook/models';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface TagState extends EntityState<Tag, number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tags' })
export class TagsStore extends EntityStore<TagState, Tag, number> {
  constructor() {
    super();
  }
}
