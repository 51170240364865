import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class IntroductionService {
  constructor(private http: HttpClient) {}

  check(startupId: number, corporationId: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startup', `${startupId}`);
    httpParams = httpParams.append('corporation', `${corporationId}`);

    return this.http.get<any>(environment.apiBaseUrl + 'introduction/check/', { params: httpParams });
  }

  create(startupId: number, corporationId: number, params) {
    const payload = params || {};
    payload['startup'] = startupId;
    payload['corporation'] = corporationId;

    return this.http.post(environment.apiBaseUrl + 'introduction/', payload);
  }

  get(startupId: number, corporationId: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startup', `${startupId}`);
    httpParams = httpParams.append('corporation', `${corporationId}`);

    return this.http.get<any>(environment.apiBaseUrl + 'introduction/get/', { params: httpParams });
  }

  delete(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'introduction/' + id + '/');
  }

  update(id: number, data: any) {
    return this.http.patch(environment.apiBaseUrl + 'introduction/' + id + '/', data);
  }
}
