import { Observable } from 'rxjs';
import { CacheService, CacheReturnTypes } from '../services/cache.service';

export function InvalidateCache(namespace: string, keyOnly?: boolean, useArgs?: boolean): any | Observable<any> {
  return function (
    target: Function,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<any>
  ): any | Observable<any> {
    const method: Function = descriptor.value;

    descriptor.value = function (...args: Array<any>): any | Observable<any> {
      const cache = CacheService.getInstance();
      const value = method.apply(this, args);

      const defaultArgs = useArgs === true ? args : null;

      cache.remove(namespace, defaultArgs);

      if (keyOnly !== true) {
        cache.clearNamespace(namespace);
      }

      return value;
    };

    return descriptor;
  };
}
