import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Corporation } from '../../models/corporation';
import { ResultSet } from '../../models/result-set';
import { Cached, InvalidateCache } from '../cache/cache.module';
import { map } from 'rxjs/operators';

@Injectable()
export class CorporationService {
  constructor(private http: HttpClient) {}

  @Cached('corporation')
  getAll(page?: number, limit?: number) {
    const params = new HttpParams().set('limit', `${limit}`).set('offset', `${page * limit}`);

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/', { params });
  }

  get(id: number) {
    return this.http.get<Corporation>(environment.apiBaseUrl + 'corporations/' + id + '/');
  }

  @InvalidateCache('corporation:get', true, true)
  delete(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'corporations/' + id + '/');
  }

  @InvalidateCache('corporation:getAll', true)
  @InvalidateCache('tag:startups')
  create(corporation: any) {
    const data = {};
    const keys = Object.getOwnPropertyNames(corporation);

    keys.forEach((key) => {
      if (key === 'verticals') {
        data['verticals'] = corporation.verticals.map(function (vertical) {
          return vertical.id;
        });
      } else if (key === 'tags') {
        data['tags'] = corporation.tags.map(function (tag) {
          return tag.id;
        });
      } else if (key === 'account_managers') {
        data['account_managers'] = corporation.account_managers.map(function (user) {
          return user.id;
        });
      } else if (key === 'business_regions') {
        data['business_regions'] = corporation.business_regions.map(function (business_region) {
          return business_region.id;
        });
      } else if (key === 'business_scope') {
        data['business_scope'] = corporation.business_scope.id;
      } else if (key === 'industry_rank') {
        data['industry_rank'] = corporation.industry_rank.id;
      } else if (!!corporation[key]) {
        /*else if (key === 'stage') {
                data['stage'] = corporation.stage.id;
            }*/
        data[key] = corporation[key];
      }
    });

    if (data['sub_domains']) {
      data['sub_domains'] = data['sub_domains'].filter((domain) => {
        return !!domain;
      });
    }

    return this.http.post<Corporation>(environment.apiBaseUrl + 'corporations/', data);
  }

  @InvalidateCache('corporation', true)
  edit(originalCorporation: Corporation, corporation: any) {
    const newValues = {};
    const keys = Object.getOwnPropertyNames(corporation);

    if (!!corporation.logo && !!corporation.logo.id) {
      corporation.logo = corporation.logo.id;
    }

    if (corporation.contract_date === '') {
      corporation.contract_date = null;
    }

    newValues['verticals'] = corporation.verticals.map(function (vertical) {
      return vertical.id;
    });
    newValues['tags'] = corporation.tags.map(function (tag) {
      return tag.id;
    });
    newValues['locations'] = corporation.locations.map(function (location) {
      return location.id;
    });
    newValues['account_managers'] = corporation.account_managers.map(function (user) {
      return user.id;
    });
    newValues['business_regions'] = corporation.business_regions.map(function (business_region) {
      return business_region.id;
    });
    if (corporation.business_scope) {
      newValues['business_scope'] = corporation.business_scope.id;
    }
    if (corporation.industry_rank) {
      newValues['industry_rank'] = corporation.industry_rank.id;
    }
    if (corporation.domains) {
      newValues['domains'] = corporation.domains;
    }
    newValues['parent_corporation'] = corporation.parent_corporation;

    keys.forEach((key) => {
      if (key === 'verticals') {
        return;
      }
      if (key === 'tags') {
        return;
      }
      if (key === 'locations') {
        return;
      }
      if (key === 'account_managers') {
        return;
      }
      if (key === 'business_regions') {
        return;
      }
      if (key === 'business_scope') {
        return;
      }
      if (key === 'industry_rank') {
        return;
      }
      if (key === 'domains') {
        return;
      }
      if (key === 'sub_domains') {
        return;
      }
      if (key === 'parent_corporation') {
        return;
      }
      if (corporation[key] !== originalCorporation[key]) {
        if (!!corporation[key] || corporation[key] === '') {
          if (!corporation[key].id) {
            newValues[key] = corporation[key];
            return;
          }

          if (corporation[key].id !== corporation[key].id) {
            newValues[key] = corporation[key].id;
            return;
          }
        }
      }
    });

    return this.http.patch<Corporation>(environment.apiBaseUrl + 'corporations/' + corporation.id + '/', newValues);
  }

  searchFilter(predicates: object, sort: string, limit?: number, page?: number) {
    let params = new HttpParams().set('limit', `${limit}`).set('offset', `${limit * page}`);

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];

      if (value == null || value.length === 0) {
        return;
      }

      params = params.append(key, predicates[key]);
    });

    if (sort && sort.length > 0) {
      params = params.append('ordering', sort);
    }

    return this.http.get<ResultSet<Corporation>>(environment.apiBaseUrl + 'corporations/', { params });
  }

  follow(id: number) {
    return this.http.put(environment.apiBaseUrl + 'corporations/' + id + '/follow/', {});
  }

  unfollow(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'corporations/' + id + '/follow/', {});
  }

  doesFollow(id: number) {
    return this.http.get(environment.apiBaseUrl + 'corporations/' + id + '/follows/', {});
  }

  addCollections(id: number, collectionIds: number[]) {
    return this.http.post(environment.apiBaseUrl + 'corporations/' + id + '/collections/', {
      collections: collectionIds,
    });
  }

  getFiles(corporationId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/' + corporationId + '/files/');
  }

  @Cached('corporation:dealflows')
  getDealflows(corporationId: number, page?: number, limit?: number) {
    const params = new HttpParams().set('limit', `${limit}`).set('offset', `${page * limit}`);

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/' + corporationId + '/dealflows/', {
      params,
    });
  }

  getEvents(corporationId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/' + corporationId + '/events/');
  }

  getOfficeHours(corporationId: number, page?: number, limit?: number) {
    const params = new HttpParams().set('limit', `${limit}`).set('offset', `${page * limit}`);

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/' + corporationId + '/office-hours/', {
      params,
    });
  }

  uploadFile(corporationId: number, formData: FormData) {
    return this.http.post(environment.apiBaseUrl + 'corporations/' + corporationId + '/files/', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateFileName(corporationId: number, fileId: number, filename: string) {
    const url = environment.apiBaseUrl + 'corporations/' + corporationId + '/files/' + fileId + '/';
    return this.http.patch(url, { name: filename });
  }

  deleteFile(corporationId: number, fileId: number) {
    return this.http.delete(environment.apiBaseUrl + 'corporations/' + corporationId + '/files/' + fileId + '/');
  }

  searchDomain(predicates: object, sort: string) {
    let params = new HttpParams();

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];

      if (value == null || value.length === 0) {
        return;
      }
      params = params.append(key, predicates[key]);
    });

    if (sort && sort.length > 0) {
      params = params.append('ordering', sort);
    }

    return this.http.get<any>(environment.apiBaseUrl + 'domains/search/', { params });
  }

  getCompanyDetails(domain: string) {
    return this.http.post(environment.apiBaseUrl + 'enrichment/clearbit/', { domain: domain });
  }

  advancedSearch(query, ordering?, page?: number, limit = 40) {
    let params = new HttpParams().set('limit', `${limit}`).set('offset', `${page * limit}`);

    query.terms.forEach((term) => {
      if (term.term.trim().length === 0) {
        return;
      }
      params = params.append('search', term.term);
    });

    if (query.filters.verticals) {
      query.filters.verticals.forEach((vertical) => {
        params = params.append('vertical', `${vertical.id}`);
      });
    }

    if (query.filters.tags) {
      query.filters.tags.forEach((tag) => {
        params = params.append('tag', `${tag.id}`);
      });
    }

    if (query.filters.account_managers) {
      query.filters.account_managers.forEach((manager) => {
        params = params.append('account_manager', `${manager.id}`);
      });
    }

    if (query.location) {
      params = params.append('location', `${query.location}`);
    }

    if (query.filters.countries) {
      query.filters.countries.forEach((country) => {
        params = params.append('country', `${country.country_code.toLowerCase()}`);
      });
    }

    if (query.filters.time_period) {
      const updatedDate = new Date();
      updatedDate.setDate(updatedDate.getDate() - query.filters.time_period.days);
      let month: any = updatedDate.getMonth() + 1;

      if (month < 10) {
        month = '0' + month;
      }

      let date: any = updatedDate.getDate() + 1;

      if (date < 10) {
        date = '0' + date;
      }

      params = params.append('updated', `${updatedDate.getFullYear() + '-' + month + '-' + date}`);
    }

    if (ordering) {
      params = params.append('ordering', `${ordering}`);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/', { params });
  }

  getOpportunities(corporationId: number, page?: number) {
    const limit = 40;
    const params = new HttpParams().set('limit', `${limit}`).set('offset', `${page * limit}`);
    return this.http.get<ResultSet>(`${environment.apiBaseUrl}corporations/${corporationId}/opportunities/`, {
      params,
    });
  }

  getUserRelations(corporationId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'corporations/' + corporationId + '/user-relations/');
  }

  getCorporations(id: number, searchText?: string, page = 0, limit = 40) {
    let params = new HttpParams().set('limit', `${limit}`).set('offset', `${page * limit}`);
    if (searchText) {
      params = params.append('search', searchText);
    }
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'tags/' + id + '/corporations/', { params });
  }

  search(query: string) {
    return this.http
      .post<Corporation[]>(`${environment.apiUrl}v2/search/`, {
        models: ['corporations'],
        payload: { order_by: 'relevance', query, max_results: 50 },
      })
      .pipe(map((payload) => payload['corporations']['results']));
  }

  searchAll(query: string) {
    return this.http
      .post<Corporation[]>(`${environment.apiUrl}v2/search/`, {
        models: ['vcs', 'corporations'],
        payload: { order_by: 'relevance', query, max_results: 50 },
      })
      .pipe(map((payload) => [].concat(payload['corporations']['results'], payload['vcs']['results'])));
  }
}
