import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkCheck',
})
export class LinkCheckPipe implements PipeTransform {
  transform(obj: any[]): any {
    let count = 0;
    obj.forEach((element) => {
      if (element.virtual_link === '') {
        count++;
      }
    });
    if (obj.length === count) {
      return 1;
    } else if (count === 0) {
      return 2;
    } else {
      return 3;
    }
  }
}
