import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class GlobalTitleService {
  public globalTitle = '';
  notificationCount = 0;
  dropdownCount = 0;
  commandCount = 0;

  constructor(private titleService: Title) {}

  setTitle(newTitle?: string) {
    if (newTitle) {
      this.globalTitle = newTitle;
    }
    let title = '';
    if (this.notificationCount > 0) {
      title = `(${this.notificationCount}) ${this.globalTitle}`;
    } else {
      title = this.globalTitle;
    }

    this.titleService.setTitle(title);
  }

  updateNotifcations(incr: number, page: string) {
    if (page === 'Notification Dropdown') {
      this.dropdownCount = incr;
    }
    if (page === 'Command Center') {
      this.commandCount = incr;
    }
    this.notificationCount = this.commandCount + this.dropdownCount;
    this.setTitle();
  }

  resetNotificationCount() {
    this.notificationCount = 0;
    this.setTitle();
  }

  getGlobalTitle() {
    return this.globalTitle;
  }
}
