import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'collectionActivityType',
})
export class CollectionActivityTypePipe implements PipeTransform {
  transform(value: string, count: number = 0): string {
    let action;
    if (value === 'collection_created') {
      action = { action: 'created', type: 'collection' };
    } else if (value === 'collection_updated') {
      action = { action: 'updated', type: 'collection' };
    } else if (value === 'startup_added') {
      action = { action: 'added', type: 'startup' };
    } else if (value === 'startup_deleted') {
      action = { action: 'removed', type: 'startup' };
    } else if (value === 'ballot_created') {
      action = { action: 'created', type: 'ballot' };
    }

    if (action) {
      return `${action.action} ${this.numberize(count)} ${action.type}${this.plural(count)}.`;
    }
    return value;
  }

  private numberize(count): string {
    return count > 1 ? `${count}` : 'a';
  }

  private plural(count): string {
    return count > 1 ? 's' : '';
  }
}
