import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'playbook-search-explorer-info',
  templateUrl: './search-explorer-info.component.html',
})
export class SearchExplorerInfoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
