import {
  Component,
  Input,
  ContentChildren,
  QueryList,
  AfterContentInit,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { OverlayComponent } from '../overlay/overlay.component';

@Component({
  selector: 'playbook-tab-group',
  styleUrls: ['./tab-group.component.scss'],
  templateUrl: 'tab-group.component.html',
})
export class TabGroupComponent implements AfterContentInit {
  @Input() label: String = '';
  @Input() navigationBackgroundColor = 'none';
  @Input() isModal = false;
  @Input() isModal1 = false;
  @Input() isModal2 = false;
  @Input() displayStyle = null;
  @Input() topRound = false;
  @Input() lastUpdated: any;
  @Output() tabChange = new EventEmitter<string>();
  @Output() saveChanges = new EventEmitter<string>();
  @Input() changesMade = false;

  queueTab: any;

  @ViewChild('confirmOverlay', { static: true }) confirmOverlay: OverlayComponent;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  ngAfterContentInit() {
    if (this.tabs.length === 0) {
      return;
    }

    this.tabs.first.shouldDisplay = true;
  }

  showTabByName(tabName: string) {
    const tab = this.tabs.find((t) => t.label === tabName);
    this.showSelectedTab(tab);
  }

  showTabByIndex(index: number) {
    const tab = this.tabs.find((t, i) => i === index);
    this.showSelectedTab(tab);
  }

  showTab(tab, find?) {
    if (this.changesMade) {
      this.confirmOverlay.overlayOpen = true;
      this.queueTab = tab;
    } else if (!tab.disabled) {
      this.tabs.forEach((targetTab) => {
        targetTab.shouldDisplay = false;
      });
      if (find) {
        this.tabs.forEach((each) => {
          if (each.label === tab.label) {
            tab = each;
          }
        });
      }
      tab.display();
      this.tabChange.emit(tab.label);
    }
  }

  reInit() {
    this.showTab(this.tabs.first);
  }

  getCurrentTab() {
    return this.tabs.reduce((prev, tab) => {
      if (tab.shouldDisplay === true) {
        return tab;
      }

      return prev;
    }, null);
  }

  continueWithoutSaving() {
    this.changesMade = false;
    this.showTab(this.queueTab);
  }

  saveAndContinue() {
    this.changesMade = false;
    this.showTab(this.queueTab);
    this.saveChanges.emit();
  }

  private showSelectedTab(tab: TabComponent) {
    if (tab) {
      this.tabs.forEach((targetTab) => {
        targetTab.shouldDisplay = false;
      });
      tab.display();
    }
  }
}
