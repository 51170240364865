import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'playbook-overflow-container',
  templateUrl: './overflow-container.component.html',
  styleUrls: ['./overflow-container.component.scss'],
})
export class OverflowContainerComponent implements OnInit {
  @Input() tab: Boolean = false;
  @Input() dealflowStartup: Boolean = false;
  @Input() noMarginPadding: Boolean = false;
  @Input() size = '';
  @Input() disabled = false;
  @Input() count = 0;
  @Input() noPaddingBottom = false;
  @Input() noHover = false;
  isEdge = false;
  showStyle: false;
  leftArrowShow = false;
  rightArrowShow = true;
  @ViewChild('carouselContent', { read: ElementRef, static: true }) public carouselContent: ElementRef;

  ngOnInit() {
    const version = this.detectBrowser();
    if (version >= 12) {
      this.isEdge = true;
    }
  }

  public scrollRight(): void {
    if (this.isEdge) {
      this.carouselContent.nativeElement.scrollLeft += 1300;
    } else {
      this.carouselContent.nativeElement.scrollTo({
        left: this.carouselContent.nativeElement.scrollLeft + 1150,
        behavior: 'smooth',
      });
    }
  }

  public scrollLeft(): void {
    if (this.isEdge) {
      this.carouselContent.nativeElement.scrollLeft -= 1300;
    } else {
      this.carouselContent.nativeElement.scrollTo({
        left: this.carouselContent.nativeElement.scrollLeft - 1150,
        behavior: 'smooth',
      });
    }
  }

  detectBrowser() {
    const ua = window.navigator.userAgent;
    const ie = ua.indexOf('MSIE');
    if (ie > 0) {
      return parseInt(ua.substring(ie + 5, ua.indexOf('.', ie)), 10);
    }
    const ie11 = ua.indexOf('Trident/');
    if (ie11 > 0) {
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }
    return false;
  }

  show() {
    const maxScrollLeft =
      this.carouselContent.nativeElement.scrollWidth - this.carouselContent.nativeElement.clientWidth;
    if (this.carouselContent.nativeElement.scrollLeft === 0) {
      this.leftArrowShow = false;
    } else {
      this.leftArrowShow = true;
    }

    if (this.carouselContent.nativeElement.scrollLeft === maxScrollLeft) {
      this.rightArrowShow = false;
    } else {
      this.rightArrowShow = true;
    }
  }

  getArrowClass() {
    return {
      large: this.size === 'large',
      medium: this.size === 'medium',
      small: this.size === 'small',
      xsmall: this.size === 'xsmall',
      user: this.size === 'user',
      startup: this.size === 'startup',
      tag: this.size === 'tag',
      dealflow: this.size === 'dealflow',
      'dealflow-startup': this.size === 'dealflow-startup',
      'collection-card': this.size === 'collection-card',
      today: this.size === 'today',
    };
  }
}
