import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { Toast } from '../model/toast';

@Component({
  selector: 'playbook-toaster',
  templateUrl: './toaster.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent {
  constructor(public snackBarRef: MatSnackBarRef<ToasterComponent>, @Inject(MAT_SNACK_BAR_DATA) public toast: Toast) {}

  close() {
    this.snackBarRef.dismiss();
  }
}
