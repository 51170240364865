import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';

@Injectable()
export class BodyService {
  private messages: Subject<any> = new Subject();

  watchClick() {
    return this.messages;
  }

  click(event) {
    this.messages.next(event);
  }
}
