import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { slideMotion } from '../core/animations/animation-consts';

@Component({
  selector: 'playbook-select',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideMotion],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() title: string;
  @Input() showTitle = true;
  @Input() items: object[];
  @Input() preSelectedItems?: object[];
  @Input() displayValue: string | null = null;
  @Input() serverSearch = false;
  @Input() mode: 'single' | 'multiple' = 'single';
  @Input() clearOnSelection = true;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() inputDisabled = false;
  @Output() valueChange = new EventEmitter<string>();
  @ViewChild(CdkOverlayOrigin, { static: true, read: ElementRef }) originElement: ElementRef<HTMLDivElement>;
  @ViewChild(CdkConnectedOverlay, { static: true }) cdkConnectedOverlay: CdkConnectedOverlay;
  query$ = new BehaviorSubject<string>('');
  destroy$ = new Subject();
  listOfValue$ = new BehaviorSubject<any[]>([]);
  listOfValues: any[];
  originContainerWidth: number = null;
  selectOpened = false;
  focused = false;
  value = '';
  selection = new Set<any>();
  dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';
  origin: CdkOverlayOrigin;
  onChange = (value: number | number[]) => {};
  onTouched: () => any = () => {};
  @Input() compareFn: (o1: any, o2: any) => boolean = (o1: any, o2: any) => o1 === o2;
  @Input() getMultipleDisplayValueFn: (v: any) => string = this.getDisplayValue;

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(modelValue: any): void {
    if (modelValue) {
      switch (this.mode) {
        case 'multiple':
          // clears current selection before setting the new selection
          this.selection.clear();
          if (modelValue.length !== 0) {
            modelValue.forEach((value: any) => {
              // only add to the selection if it is an option
              const item = this.items.find((i) => i[this.displayValue] === value[this.displayValue]);
              if (item) {
                this.selection.add(item);
              }
            });
          }
          this.onChange(Array.from(this.selection));
          break;

        default:
          this.value = modelValue[this.displayValue];
          this.onChange(modelValue);
          break;
      }
    }

    this.cdr.detectChanges();
  }

  registerOnChange(fn: (id: number | number[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setOpenState(value: boolean): void {
    if (this.selectOpened !== value) {
      this.selectOpened = value;
      this.cdr.markForCheck();
      this.updateCdkConnectedOverlayStatus();
      if (!value) {
        this.query$.next('');
      }
    }
  }

  clearValue() {
    switch (this.mode) {
      case 'multiple':
        this.selection.clear();
        break;

      default:
        this.value = null;
        break;
    }
    this.onChange(undefined);
  }

  onValuesChange(value: string) {
    this.query$.next(value);
    this.updateCdkConnectedOverlayPositions();
  }

  selectItem(value: any) {
    switch (this.mode) {
      case 'multiple':
        break;

      default:
        this.value = this.getDisplayValue(value);
        this.setOpenState(false);
        this.onChange(value);
        break;
    }
  }

  onPositionChange(position: ConnectedOverlayPositionChange): void {
    this.dropDownPosition = position.connectionPair.originY;
  }

  updateCdkConnectedOverlayPositions(): void {
    if (this.cdkConnectedOverlay.overlayRef) {
      this.cdkConnectedOverlay.overlayRef.updatePosition();
    }
  }

  isSelected(value: any) {
    return [...this.selection].some((item) => this.compareFn(item, value));
  }

  onFocused() {
    this.setOpenState(true);
  }

  onValueChange(value: string) {
    if (this.serverSearch) {
      this.valueChange.emit(value);
    } else {
      this.query$.next(value);
    }
  }

  handleItem(value: any) {
    switch (this.mode) {
      case 'multiple':
        this.toggleItem(value);
        break;

      default:
        this.selectItem(value);
        break;
    }
  }

  toggleItem(value: any) {
    if (this.selection.has(value)) {
      this.selection.delete(value);
    } else {
      this.selection.add(value);
    }
    this.value = '';
    this.onChange([...this.selection]);
    setTimeout(() => {
      this.updateCdkConnectedOverlayPositions();
    }, 0);
  }

  ngOnInit(): void {
    combineLatest([this.query$, this.listOfValue$])
      .pipe(
        map(([query, listOfValues]) =>
          (listOfValues || []).filter((value) => {
            return (this.getDisplayValue(value) as string).toLowerCase().includes(query.trim().toLowerCase());
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((values) => {
        this.listOfValues = values;
      });
  }

  ngAfterViewInit() {
    if (this.preSelectedItems && this.preSelectedItems.length > 0) {
      for (const selected of this.preSelectedItems) {
        this.toggleItem(selected);
      }
    }
    this.cdr.markForCheck();
    this.updateCdkConnectedOverlayStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.listOfValue$.next(changes.items.currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateCdkConnectedOverlayStatus(): void {
    if (this.originElement && this.originElement.nativeElement) {
      this.originContainerWidth = this.originElement.nativeElement.getBoundingClientRect().width;
    }
  }

  getDisplayValue(value) {
    if (Array.isArray(this.displayValue)) {
      return this.displayValue.map((ds) => value[ds]).join(' - ');
    }
    return value[this.displayValue];
  }
}
