import { Vertical } from './vertical';
import { Stage } from './stage';
import { StartupTeamMember } from './startup-team-member';
import { Tag } from './tag';
import { Country } from './country';
import { Office } from './office';
import { User } from './user';
import { Batch } from './batch';
import { StartupAcquisition } from '../modules/startups/models/acquisition';
import { StartupIpo } from '../modules/startups/models/ipo';

export class Startup {
  id: number;
  website: string;
  company_name: string;
  domain_name: string;
  sub_domains: any;
  domains: any;
  blurb: string;
  created: string;
  background: string;
  description: string;
  verticals: Vertical[] = [];
  hq_city: string;
  hq_country: Country | number;
  hq_state: string;
  country: Country | number;
  one_liner: string;
  advantage: string;
  stage: Stage;
  employee_count: number;
  employee_part_time_count: number;
  inrterested_offices: Office[];
  case_study: string;
  batches: Batch[];
  interested_offices: Office[] = [];
  total_money_raised: TotalMoneyRaised;
  money_raised_usd: string;
  contact: string;
  phone_number: string;
  hq_address: string;
  email: string;
  founded: number;
  image: string;
  brand_color: string;
  logo: any;
  account_managers: User[];
  portfolio_managers: User[];
  team_members: StartupTeamMember[] = [];
  collections: any[];
  tags: Tag[] = [];
  latest_investment: Date;
  competitors: string;
  investors: string;
  updated: Date;
  uploaded_by: User;
  youtube_video_url: string;
  youtube_video_details = { title: '', duration: '', processing: true };
  partner_interests: string;
  comments: string;
  how_did_you_hear: string;
  pnp_insights: string;
  b2bb2c: string;
  portfolio: boolean;
  verified: boolean;
  validated: boolean;
  introducted: boolean;
  is_closed: boolean;
  awaiting_completion: boolean;
  crunchbase_source: boolean;
  ipo: StartupIpo;
  acquisitions: StartupAcquisition[];
  current_valuation: number;
  current_pro_rata: string;
  current_pps: string;
  priority: number;
  profile_strength: ProfileStrength;
  status: StartupProfileStatus;
  hype_score?: number;
  average_feedback?: number;
  last_month_visit_count: number;
  disabled: boolean;
}

export interface TotalMoneyRaised {
  value: number;
  currency: string;
  undisclosed: boolean;
}

export enum StartupStatus {
  SOURCED = 1,
  INVITED = 2,
  CLAIMED = 3,
  PENDING_VERIFICATION = 4,
  VERIFIED = 5,
}

export interface StartupProfileStatus {
  invited_at?: Date;
  invited_by?: number;
  claimed_at?: Date;
  claimed_by?: number;
  requested_at?: Date;
  requested_by?: number;
  verified_at?: Date;
  verified_by?: number;
  status: StartupStatus;
}

export interface ProfileStrength {
  progress: number;
  values: ProfileStrengthValue[];
}

export interface ProfileStrengthValue {
  title: string;
  field: string;
  valid: boolean;
  group: 'Beginner' | 'Intermediate' | 'Advanced' | 'Professional';
}
