import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Collection } from '../../../models/collection';
import { OverlayComponent } from '../overlay/overlay.component';
import { CollectionService } from '../../../services/collections.service';
import { ToasterService } from 'app/shared/modules/toaster/services/toaster.service';

@Component({
  selector: 'playbook-collection-copy-modal',
  templateUrl: './copy-collection-modal.component.html',
  styleUrls: ['./copy-collection-modal.component.scss'],
})
export class CopyCollectionModalComponent {
  sort: string;
  collections: Collection[];
  loadingCollections = false;
  selectedCollection: Collection;
  unselectedCollections: Collection[] = [];

  @Input() title: any;
  @Output() collectionSelected = new EventEmitter<any>();
  @ViewChild('copyFromCollectionModal', { static: true }) copyFromCollectionModal: OverlayComponent;

  constructor(private collectionService: CollectionService, private toastService: ToasterService) {}

  openModal() {
    this.copyFromCollectionModal.overlayOpen = true;
    this.selectedCollection = null;

    if (!this.collections) {
      this.loadingCollections = true;
      this.collectionService.getOwnCollections('').subscribe(
        (res) => {
          this.collections = res.results;
          this.filterCollections();
          this.loadingCollections = false;
        },
        (err) => {
          this.loadingCollections = false;
        }
      );
    }
  }

  filterCollections() {
    this.unselectedCollections = this.collections.filter((collection) => {
      return this.selectedCollection !== collection;
    });
  }

  toggleSelection(collection) {
    if (this.selectedCollection === collection) {
      this.selectedCollection = null;
    } else {
      this.selectedCollection = collection;
    }

    this.filterCollections();
  }

  searchCollections(value) {
    this.collectionService.search(value, 'collection_name').subscribe((res) => {
      this.collections = res.results;
      this.filterCollections();
    });
  }

  copyFromCollection() {
    this.collectionSelected.emit(this.selectedCollection.id);
    this.copyFromCollectionModal.overlayOpen = false;
  }
}
