import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ToasterComponent } from './components/toaster.component';
import { ToasterService } from './services/toaster.service';

@NgModule({
  declarations: [ToasterComponent],
  imports: [CommonModule, MatSnackBarModule],
  providers: [
    ToasterService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000, verticalPosition: 'top', horizontalPosition: 'right' },
    },
  ],
  entryComponents: [ToasterComponent],
})
export class ToasterModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ToasterModule,
      providers: [ToasterService],
    };
  }
}
