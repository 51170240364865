import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipeModule } from '../../pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { CollectionResultCardComponent } from './components/collection-result-card/collection-result-card.component';
import { CorporationResultCardComponent } from './components/corporation-result-card/corporation-result-card.component';
import { DealflowResultCardComponent } from './components/dealflow-result-card/dealflow-result-card.component';
import { EventResultCardComponent } from './components/event-result-card/event-result-card.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { InvestorResultCardComponent } from './components/investor-result-card/investor-result-card.component';
import { StartupResultCardComponent } from './components/startup-result-card/startup-result-card.component';
import { UserResultCardComponent } from './components/user-result-card/user-result-card.component';
import { GlobalSearchService } from './global-search.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material';
import { AbilityModule } from '@casl/angular';
import { SearchExplorerModule } from '../search/search-explorer.module';

@NgModule({
  declarations: [
    GlobalSearchComponent,
    UserResultCardComponent,
    DealflowResultCardComponent,
    EventResultCardComponent,
    CollectionResultCardComponent,
    CorporationResultCardComponent,
    StartupResultCardComponent,
    InvestorResultCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    AbilityModule,
    SearchExplorerModule,
  ],
  exports: [StartupResultCardComponent, CorporationResultCardComponent, GlobalSearchComponent],
  providers: [GlobalSearchService],
})
export class GlobalSearchModule {}
