import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { distinctUntilChanged, exhaustMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { NotificationQuery } from '../state/notification.query';
import { NotificationStore } from '../state/notification.store';
import { NotificationRemote } from './notification.remote';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private notificationRemote: NotificationRemote,
    private notificationStore: NotificationStore,
    private notificationQuery: NotificationQuery
  ) {}

  initializeNotifications() {
    this.notificationQuery
      .pageChanges()
      .pipe(
        withLatestFrom(this.query.select((state) => state.ui.perPage)),
        switchMap(([currentPage, perPage]) => this.notificationRemote.fetch(currentPage, perPage)),
        switchMap((payload) => {
          this.notificationStore.updateUI({
            finalReached: this.notificationQuery.getCount() + payload.results.length >= payload.count,
          });
          return of(payload.results);
        }),
        distinctUntilChanged()
      )
      .subscribe((notifications) => {
        this.notificationStore.add(notifications);
        this.notificationStore.setLoading(false);
      });
  }

  loadMore() {
    this.store.setLoading(true);
    const { currentPage } = this.store.getUI();
    this.notificationStore.updateUI({
      currentPage: currentPage + 1,
    });
  }

  seen() {
    this.notificationRemote.seen().subscribe(() => {
      this.store.update(null, { seen: true });
    });
  }

  markAllAsRead() {
    this.notificationRemote.markAllAsRead().subscribe(() => {
      this.store.update(null, { read: true, seen: true });
    });
  }

  pendingNotifications() {
    return this.notificationQuery.pendingNotifications();
  }

  removeNotification(notificationId: number) {
    this.notificationStore.remove(notificationId);
  }

  unseen() {
    return this.notificationQuery.getAll().filter((notification) => notification.seen).length;
  }

  get store() {
    return this.notificationStore;
  }

  get query() {
    return this.notificationQuery;
  }
}
