import { Injectable } from '@angular/core';
import { OfficeRemote } from './office.remote';
import { OfficeStore } from '../state/office.store';
import { OfficeQuery } from '../state/office.query';
import { map } from 'rxjs/operators';
import { Office } from '@playbook/models';

@Injectable({ providedIn: 'root' })
export class OfficeService {
  constructor(private officeRemote: OfficeRemote, private officeStore: OfficeStore, private officeQuery: OfficeQuery) {}

  fetchAll() {
    this.officeRemote
      .fetch()
      .pipe(map((data) => data.results))
      .subscribe((offices) => this.officeStore.set(offices));
  }

  getOffices() {
    return this.officeQuery.selectAll();
  }

  getOffice(id: number) {
    return this.officeQuery.getEntity(id);
  }

  get offices() {
    return this.officeQuery.getValue() as Office[];
  }

  search(officeName: string, countryId = null) {
    return this.officeQuery.selectAll({
      filterBy: [
        (entity) => entity.office_name.toLowerCase().includes(officeName.toLowerCase()),
        (entity) => (countryId ? entity.country.id === countryId : true),
      ],
    });
  }

  addOrUpdateOffice(office: Office) {
    this.officeStore.upsert(office.id, office);
  }

  deleteOffice(officeId: number) {
    this.officeStore.remove(officeId);
  }
}
