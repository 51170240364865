import { Component, Output, Input, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'playbook-drop-menu-checkbox',
  templateUrl: './drop-menu-checkbox.component.html',
  styleUrls: ['./drop-menu-checkbox.component.scss'],
})
export class DropMenuCheckboxComponent {
  listOpen = false;
  private stopProp = false;
  @Input() alignMenu = 'right';
  @Input() extended = false;
  constructor(private ref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  clickOutside(event) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.listOpen = false;
    }
  }

  toggleList() {
    this.listOpen = !this.listOpen;
  }
}
