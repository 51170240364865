import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Office, ResultSet } from '@playbook/models';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class OfficeRemote {
  constructor(private http: HttpClient) {}

  fetch() {
    return this.http.get<ResultSet<Office>>(`${environment.apiBaseUrl}offices/?limit=1000`);
  }
}
