import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ica_round',
})
export class ICARoundPipe implements PipeTransform {
  round_mapping = {
    P: 'Priced Equity',
    C: 'Convertible',
  };

  transform(round: string): string {
    return this.round_mapping[round];
  }
}
