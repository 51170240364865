import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[playbookAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  @Input() disableAutoFocus = false;
  @Input() focusEnd = false;
  @Input() scrollToFocus = false;

  constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.disableAutoFocus) {
      return;
    }

    if (this.focusEnd) {
      this.focusTextEnd(this.el.nativeElement, this.scrollToFocus);
    } else {
      this.el.nativeElement.focus();
    }
  }

  private focusTextEnd(element: any, scrollToFocus = false): void {
    // Sets the focus at the end of the text value when the editor is open
    if (!element) {
      return;
    }

    if (document && document.createRange) {
      const range = document.createRange();
      range.selectNodeContents(element);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }

    element.focus();

    if (scrollToFocus) {
      element.scrollTop = element.scrollHeight;
    }
  }
}
