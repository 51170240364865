import { Component, Output, Input, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'playbook-overlay',
  templateUrl: 'overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {
  @Input() backgroundColor: string | boolean = false;
  @Input() bodyClass = '';
  @Input() pushedUp = false;
  @Input() headerText: string | boolean = false;
  @Input() videoPlayer = false;
  @Input() hardHide = false;
  @Input() filter = false;
  @Input() dealflowFilter = false;
  @Input() noPadding = false;
  @Output() saveFilter = new EventEmitter<boolean>();

  /**
   * Make OverlayComponent work with components that use OnPush detection strategy by making it reactive while
   * keeping the same API as before.
   */
  private readonly _overlayState = new BehaviorSubject(false);

  set overlayOpen(value) {
    this._overlayState.next(value);
  }

  get overlayOpen() {
    return this._overlayState.getValue();
  }

  get overlayState() {
    return this._overlayState.asObservable();
  }

  /**
   * Hides the overlay. $event being true implicitly tells the overlay to close.
   * $event being an event object will check to make sure that the click is outside
   * of the overlay. If inside, cancels the event.
   *
   * @param {*} $event
   * @returns
   * @memberof OverlayComponent
   */
  hideOverlay($event: any) {
    if (!this.hardHide) {
      if ($event === true) {
        this.overlayOpen = false;
        return;
      }
      if ($event.currentTarget !== $event.target) {
        return;
      }
      if (this.dealflowFilter) {
        this.saveFilter.emit(true);
      }
      this.overlayOpen = false;
    }
  }
}
