import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'playbook-markdown-editor',
  styleUrls: ['./markdown-editor.component.scss'],
  templateUrl: './markdown-editor.component.html',
})
export class MarkdownEditorComponent {
  selectedRange = null;

  @Input() content = '';
  @Input() showPreview = true;
  @Input() trackChanges = false;
  @Output() update = new EventEmitter<string>();
  @ViewChild('textEditor', { static: true }) textEditor;

  getSelectedRange() {
    this.selectedRange = [this.textEditor.nativeElement.selectionStart, this.textEditor.nativeElement.selectionEnd];

    if (this.trackChanges) {
      this.update.emit(this.content);
    }
  }

  setFormatting(formatMarker, surround?) {
    if (!this.selectedRange) {
      return;
    }

    const formatMarkerLength = formatMarker.length;
    const newEndPosition = this.selectedRange[1] + formatMarkerLength;

    this.insertAtPosition(formatMarker, this.selectedRange[0]);

    if (surround) {
      this.insertAtPosition(formatMarker, newEndPosition);
    }

    this.updateCursorRange(formatMarkerLength * 2);
  }

  setLineStart(character) {
    const selectedText = this.content.slice(this.selectedRange[0], this.selectedRange[1]);
    const newText = selectedText.replace(/\n/g, `\n${character} `);
    this.content = [
      this.content.slice(0, this.selectedRange[0]),
      newText,
      this.content.slice(this.selectedRange[1]),
    ].join('');

    let i = this.selectedRange[0];

    while (i >= 0) {
      if (this.content[i] === '\n') {
        this.content = [this.content.slice(0, i + 1), character + ' ', this.content.slice(i + 1)].join('');
        break;
      } else if (i === 0) {
        this.insertAtPosition(character + ' ', i);
        break;
      }

      i--;
    }

    this.updateCursorRange(character.length + 1);
  }

  setNumberLines() {
    let i = this.selectedRange[0];

    while (i >= 0) {
      if (this.content[i] === '\n') {
        this.content = [this.content.slice(0, i + 1), '1. ', this.content.slice(i + 1)].join('');
        break;
      } else if (i === 0) {
        this.insertAtPosition('1. ', i);
        break;
      }

      i--;
    }

    if (this.selectedRange[0] === this.selectedRange[1]) {
      return;
    }

    this.selectedRange[1] += 3;

    let nextNumber = 2;
    i = this.selectedRange[0];

    while (i <= this.selectedRange[1]) {
      if (this.content[i] === '\n') {
        this.content = [this.content.slice(0, i + 1), `${nextNumber}. `, this.content.slice(i + 1)].join('');
        nextNumber++;
        this.selectedRange[1] += 3;
      }

      i++;
    }

    this.updateCursorRange();
  }

  insertAtPosition(characters, position) {
    this.content = [this.content.slice(0, position), characters, this.content.slice(position)].join('');
  }

  updateCursorRange(offset?: number) {
    if (offset === undefined) {
      offset = 0;
    }

    const collapseTo = this.selectedRange[1] + offset;

    setTimeout(() => {
      this.textEditor.nativeElement.focus();
      this.textEditor.nativeElement.setSelectionRange(this.selectedRange[0], collapseTo);
    }, 1);
  }

  boldText() {
    this.setFormatting('**', true);
  }

  italicText() {
    this.setFormatting('*', true);
  }

  bulletPoint() {
    this.setLineStart('* ');
  }

  numberPoint() {
    this.setNumberLines();
  }

  headerText() {
    this.setLineStart('### ');
  }
}
