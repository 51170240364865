import { Pipe, PipeTransform } from '@angular/core';
import { Tag } from '../models/tag';

@Pipe({
  name: 'permissionName',
})
export class PermissionNamePipe implements PipeTransform {
  transform(permissionSlug: any): string {
    return {
      can_view: 'Viewer',
      can_contribute: 'Collaborator',
      can_manage: 'Manager',
    }[permissionSlug];
  }
}
