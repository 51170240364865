import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultSet, Stage } from '@playbook/models';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class StageRemote {
  constructor(private http: HttpClient) {}
  fetch() {
    return this.http.get<ResultSet<Stage>>(`${environment.apiBaseUrl}stages/`);
  }
}
