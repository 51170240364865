import { Component, Output, Input, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'playbook-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Output() item = new EventEmitter<any>();
  @Input() items: any[];
  @Input() target: any;
  @Input() paddingOption1 = false;

  menuOpen = false;
  private stopProp = false;

  constructor() {}

  itemSelected(item) {
    this.item.emit({ action: item.action, target: this.target });
    this.menuOpen = false;
  }

  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }

    this.menuOpen = false;
  }

  @HostListener('click', ['$event'])
  clicked(event) {
    this.stopProp = event.currentTarget.contains(event.srcElement);
  }
}
