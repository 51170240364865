import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status',
})
export class DealflowStatusPipe implements PipeTransform {
  dealflowStatus = ['Created', 'Sourcing', 'Reviewing', 'Scheduling', 'Ready', 'Feedback', 'Completed'];

  transform(id: number): string {
    return this.dealflowStatus[id];
  }
}
