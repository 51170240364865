import {Observable, of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';

export enum CacheReturnTypes {
  Observable = 'O',
  Plain = 'P',
}

@Injectable()
export class CacheService {
  private static instance: CacheService = undefined;

  private defaultExpiry = 300;
  private _cache = {};

  constructor() {
    CacheService.instance = this;
  }

  static getInstance() {
    return CacheService.instance;
  }

  buildCacheKey(keyNamespace: string, keyData?: any | any[]) {
    if (!keyData) {
      return keyNamespace;
    }

    if (keyData instanceof Array) {
      return keyNamespace + ':' + keyData.filter((key) => !!key).join(':');
    }

    return keyNamespace + ':' + keyData;
  }

  getDefaultExpiry() {
    return this.defaultExpiry;
  }

  set(namespace: string, key: any, value: any, returnType?: CacheReturnTypes, expiry?: number) {
    const fullyQualifiedKey = this.buildCacheKey(namespace, key);

    let timeValue: number;

    if (expiry) {
      timeValue = new Date().getTime() + expiry * 1000;
    } else {
      timeValue = new Date().getTime() + this.defaultExpiry * 1000;
    }

    if (!returnType) {
      returnType = CacheReturnTypes.Plain;
    }

    this._cache[fullyQualifiedKey] = {
      value: value,
      namespace: namespace,
      expires: timeValue,
      returnType: returnType,
    };

    return true;
  }

  get(namespace: string, key: any | any[]): any | Observable<any> {
    const fullyQualifiedKey = this.buildCacheKey(namespace, key);

    if (!this._cache[fullyQualifiedKey]) {
      return false;
    }

    if (this._cache[fullyQualifiedKey].expires < new Date().getTime()) {
      this.remove(namespace, key);
      return false;
    }

    if (this._cache[fullyQualifiedKey].returnType === CacheReturnTypes.Observable) {
      return observableOf(this._cache[fullyQualifiedKey].value);
    }

    return this._cache[fullyQualifiedKey].value;
  }

  exists(namespace: string, key: any[]) {
    const fullyQualifiedKey = this.buildCacheKey(namespace, key);

    return !!this._cache[fullyQualifiedKey];
  }

  getExpiry(namespace: string, key: any | any[]) {
    const fullyQualifiedKey = this.buildCacheKey(namespace, key);

    if (!this._cache[fullyQualifiedKey]) {
      return false;
    }

    return this._cache[fullyQualifiedKey].expires;
  }

  remove(namespace: string, key: any | any[]) {
    const fullyQualifiedKey = this.buildCacheKey(namespace, key);

    if (!this._cache[fullyQualifiedKey]) {
      return false;
    }

    delete this._cache[fullyQualifiedKey];

    return true;
  }

  clear() {
    this._cache = {};
  }

  clearNamespace(namespace: string) {
    Object.keys(this._cache).forEach((key) => {
      const value = this._cache[key];

      if (!!value.namespace && value.namespace === namespace) {
        delete this._cache[key];
      }
    });
  }

  size() {
    return Object.keys(this._cache).length;
  }
}
