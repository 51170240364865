import { Component, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { GeoService } from '../../../services/geo.service';

@Component({
  selector: 'playbook-radial-search',
  templateUrl: 'radial-search.component.html',
  styleUrls: ['./radial-search.component.scss'],
  providers: [GeoService],
})
export class RadialSearchComponent {
  searchOpen = false;
  searchResults = [];
  selectedLocation: any = null;
  searchRadius = 50;
  locationSearch = '';
  showResults = false;
  locationChosen = false;
  currentLocation = null;
  private stopProp = false;
  private searchSub = null;
  private searchTimeout = null;

  @Output() location = new EventEmitter<any>();
  @ViewChild('radius', { static: true }) radius: ElementRef;

  constructor(private geoService: GeoService) {}

  searchLocations() {
    if (this.searchTimeout !== null) {
      clearTimeout(this.searchTimeout);
    }

    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }

    this.searchTimeout = setTimeout(() => {
      this.searchSub = this.geoService.search(this.locationSearch).subscribe((res) => {
        this.showResults = true;
        this.searchResults = res.results;
      });
    }, 250);
  }

  selectLocation(location) {
    this.currentLocation = location;
    this.locationChosen = true;
    this.showResults = false;
    this.locationSearch = location.location_name + ', ' + location.country_code;
    this.radius.nativeElement.focus();
    this.location.emit([location, this.searchRadius]);
  }

  radiusChange() {
    if (this.currentLocation) {
      this.location.emit([this.currentLocation, this.searchRadius]);
    }
  }

  removeLocation() {
    this.location.emit(null);
    this.locationSearch = '';
    this.searchResults = [];
    this.locationChosen = false;
    this.searchOpen = false;
  }

  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }

    this.searchOpen = false;
  }

  @HostListener('click', ['$event'])
  clicked(event) {
    this.stopProp = event.currentTarget.contains(event.srcElement);
  }
}
