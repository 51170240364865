import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'href',
})
export class HrefPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    let result: any;

    const regex = /https?:\/\/[a-z.\/_\-0-9#\?=\%\&:]+/gi;
    result = value.replace(regex, (match, index) => {
      return '<a target="_blank" href="' + match + '">' + match + '</a>';
    });

    return result;
  }
}
