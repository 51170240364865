import { Component, Input, HostListener, ViewChild, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GeoService } from '../../../services/geo.service';

@Component({
  selector: 'playbook-map-autocomplete',
  templateUrl: './map-autocomplete.component.html',
  styleUrls: ['./map-autocomplete.component.scss'],
})
export class MapAutocompleteComponent implements AfterContentInit {
  constructor(private http: HttpClient, private geoService: GeoService) {}

  private stopProp = false;
  showResults = false;
  filteredObjects = [];
  keyTimer: any;

  @Input() objects: any[];
  @Input() disabled = false;
  @Input() displayValue = 'name';
  @Input() selectedObject: any = null;
  @Input() error = false;
  @ViewChild('objectSearch', { static: true }) objectSearch;
  @Output() objectSelected = new EventEmitter<any>();

  ngAfterContentInit() {
    if (!!this.selectedObject) {
      this.objectSearch.nativeElement.value = this.selectedObject;
    }
  }

  queryAddress(address: string) {
    this.selectedObject = address;
    this.objectSearch.nativeElement.value = address;
    this.objectSelected.emit(address);
    this.showResultCheck();
    if (this.keyTimer) {
      clearTimeout(this.keyTimer);
    }
    this.keyTimer = setTimeout(() => {
      this.getAddress(address);
    }, 300);
  }

  getAddress(address: string) {
    if (address.length > 3) {
      this.geoService.getAddress(address).subscribe((data) => {
        this.filteredObjects = [];
        data.predictions.forEach((element) => {
          this.filteredObjects.push(element.description);
          this.showResultCheck();
        });
      });
    }
  }

  selectObject(obj) {
    this.selectedObject = obj;
    this.objectSearch.nativeElement.value = obj;
    this.objectSelected.emit(obj);
    this.showResults = false;
  }

  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }

    this.showResults = false;
  }

  @HostListener('click', ['$event'])
  clicked(event) {
    this.stopProp = event.currentTarget.contains(event.srcElement);
  }

  showResultCheck() {
    if (this.objectSearch.nativeElement.value.length > 3 && this.filteredObjects.length > 0) {
      this.showResults = true;
    } else {
      this.showResults = false;
    }
  }
}
