import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule, MatChipsModule, MatFormFieldModule, MatAutocompleteModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'angular-calendar';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgsRevealModule } from 'ng-scrollreveal';
import { MarkdownModule } from 'ngx-markdown';
import { MomentModule } from 'ngx-moment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CorporationService } from '../modules/corporations/corporations.service';
import { DealflowPreviewService } from '../modules/dealflow/dealflow-preview.service';
import { StartupService } from '../modules/startups/startups.service';
import { PipeModule } from '../pipes/pipes.module';
import { CustomTagService } from '../services/custom-tag.service';
import { DealflowService } from '../services/dealflow.service';
import { DomainsService } from '../services/domains.service';
import { GeoService } from '../services/geo.service';
import { IntroductionService } from '../services/introduction.service';
import { NotificationCountService } from '../services/notification-count.service';
import { NotificationSoundService } from '../services/notification-sound.service';
import { UserService } from '../services/user.service';
import { AdminPageHeaderComponent } from './components/admin-page-header/admin-page-header.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { CalendarContainerComponent } from './components/calendar-container/calendar-container.component';
import { CalendarMonthViewCellComponent } from './components/calendar-month-view/calendar-month-view-cell.component';
import { CalendarMonthViewHeaderComponent } from './components/calendar-month-view/calendar-month-view-header.component';
import { CalendarMonthViewComponent } from './components/calendar-month-view/calendar-month-view.component';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { CommentVoteComponent } from './components/comment-vote/comment-vote.component';
import { CopyCollectionModalComponent } from './components/copy-collection-modal/copy-collection-modal.component';
import { CustomTagModalComponent } from './components/custom-tag-modal/custom-tag-modal.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangeSearchComponent } from './components/date-range-search/date-range-search.component';
import { DealflowCardComponent } from './components/dealflow-card/dealflow-card.component';
import { DragDropFileUploadComponent } from './components/drag-drop-file-upload/drag-drop-file-upload.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { DropMenuCheckboxComponent } from './components/drop-menu-checkbox/drop-menu-checkbox.component';
import { DropMenuComponent } from './components/drop-menu/drop-menu.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { IconComponent } from './components/icon/icon.component';
import { IntroductionModalComponent } from './components/introduction-modal/introduction-modal.component';
import { MapAutocompleteComponent } from './components/map-autocomplete/map-autocomplete.component';
import { MarkdownEditorComponent } from './components/markdown-editor/markdown-editor.component';
import { MediaUploadComponent } from './components/media-upload/media-upload.component';
import { MenuComponent } from './components/menu/menu.component';
import { ObjectChooserComponent } from './components/object-chooser/object-chooser.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { OverflowContainerComponent } from './components/overflow-container/overflow-container.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { RadialSearchComponent } from './components/radial-search/radial-search.component';
import { RadioVoteComponent } from './components/radio-vote/radio-vote.component';
import { SavedSearchComponent } from './components/saved-search/saved-search.component';
import { ScoreSliderComponent } from './components/score-slider/score-slider.component';
import { SettingsSidebarComponent } from './components/settings-sidebar/settings-sidebar.component';
import { SingleObjectChooserComponent } from './components/single-object-chooser/single-object-chooser.component';
import { SliderVoteComponent } from './components/slider-vote/slider-vote.component';
import { StaffPickerOverlayComponent } from './components/staff-picker-overlay/staff-picker-overlay.component';
import { StarVoteComponent } from './components/star-vote/star-vote.component';
import { StartupInviteOverlayComponent } from './components/startup-invite-overlay/startup-invite-overlay.component';
import { StepHeaderProgressComponent } from './components/stepper/step-header-progress/step-header-progress.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StrengthIndicatorComponent } from './components/strength-indicator/strength-indicator.component';
import { SurveyOverlayComponent } from './components/survey-overlay/survey-overlay.component';
import { TabGroupProgressbarComponent } from './components/tab-group-progressbar/tab-group-progressbar.component';
import { TabGroupComponent } from './components/tab-group/tab-group.component';
import { TabComponent } from './components/tab/tab.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserFollowCardComponent } from './components/user-follow-card/user-follow-card.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { ElementInfiniteScrollDirective } from './directives/element-infinite-scroll.directive';
import { FileDropDirective } from './directives/file-drop.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { PermissionDirective } from './directives/permission.directive';
import { StickyPolyFillDirective } from './directives/sticky-polyfill.directive';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { SubDomainSelectorComponent } from './components/sub-domain-selector/sub-domain-selector.component';

@NgModule({
  declarations: [
    OverlayComponent,
    StartupInviteOverlayComponent,
    DropDownComponent,
    MediaUploadComponent,
    PermissionDirective,
    InfiniteScrollDirective,
    ElementInfiniteScrollDirective,
    StickyPolyFillDirective,
    MenuComponent,
    DropMenuComponent,
    DropMenuCheckboxComponent,
    ButtonToggleComponent,
    ObjectChooserComponent,
    PaginationComponent,
    RadialSearchComponent,
    DateRangeSearchComponent,
    SavedSearchComponent,
    UserAvatarComponent,
    OverflowContainerComponent,
    UserFollowCardComponent,
    AutoFocusDirective,
    CommentVoteComponent,
    StarVoteComponent,
    TabComponent,
    TabGroupComponent,
    TabGroupProgressbarComponent,
    RadioVoteComponent,
    SliderVoteComponent,
    ScoreSliderComponent,
    SingleObjectChooserComponent,
    CollectionCardComponent,
    FileUploadComponent,
    DatePickerComponent,
    TimePickerComponent,
    SettingsSidebarComponent,
    AdminPageHeaderComponent,
    MapAutocompleteComponent,
    DealflowCardComponent,
    EventCardComponent,
    CalendarContainerComponent,
    CalendarMonthViewComponent,
    CalendarMonthViewHeaderComponent,
    CalendarMonthViewCellComponent,
    OnboardingComponent,
    IntroductionModalComponent,
    CustomTagModalComponent,
    ProfileCardComponent,
    StrengthIndicatorComponent,
    MarkdownEditorComponent,
    StaffPickerOverlayComponent,
    SurveyOverlayComponent,
    BackButtonComponent,
    CopyCollectionModalComponent,
    DragDropFileUploadComponent,
    FileDropDirective,
    IconComponent,
    StepperComponent,
    StepHeaderProgressComponent,
    AutocompleteInputComponent,
    SubDomainSelectorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MomentModule,
    NgsRevealModule,
    PipeModule,
    NgxSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CalendarModule,
    MarkdownModule.forChild(),
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    CdkStepperModule,
    MatChipsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
  ],
  exports: [
    OverlayComponent,
    StartupInviteOverlayComponent,
    DropDownComponent,
    MediaUploadComponent,
    PermissionDirective,
    InfiniteScrollDirective,
    ElementInfiniteScrollDirective,
    StickyPolyFillDirective,
    MenuComponent,
    DropMenuComponent,
    DropMenuCheckboxComponent,
    ButtonToggleComponent,
    ObjectChooserComponent,
    PaginationComponent,
    RadialSearchComponent,
    DateRangeSearchComponent,
    SavedSearchComponent,
    UserAvatarComponent,
    OverflowContainerComponent,
    UserFollowCardComponent,
    AutoFocusDirective,
    CommentVoteComponent,
    StarVoteComponent,
    TabComponent,
    TabGroupComponent,
    TabGroupProgressbarComponent,
    RadioVoteComponent,
    ScoreSliderComponent,
    SliderVoteComponent,
    SingleObjectChooserComponent,
    CollectionCardComponent,
    FileUploadComponent,
    DatePickerComponent,
    TimePickerComponent,
    SettingsSidebarComponent,
    AdminPageHeaderComponent,
    DealflowCardComponent,
    EventCardComponent,
    MapAutocompleteComponent,
    CalendarContainerComponent,
    CalendarMonthViewComponent,
    CalendarMonthViewHeaderComponent,
    CalendarMonthViewCellComponent,
    OnboardingComponent,
    IntroductionModalComponent,
    CustomTagModalComponent,
    ProfileCardComponent,
    StrengthIndicatorComponent,
    MarkdownEditorComponent,
    StaffPickerOverlayComponent,
    SurveyOverlayComponent,
    BackButtonComponent,
    CopyCollectionModalComponent,
    DragDropFileUploadComponent,
    IconComponent,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    StepperComponent,
    CdkStepperModule,
    AutocompleteInputComponent,
    SubDomainSelectorComponent,
  ],
  providers: [
    UserService,
    StartupService,
    DealflowService,
    IntroductionService,
    NotificationSoundService,
    NotificationCountService,
    DealflowPreviewService,
    GeoService,
    CorporationService,
    DomainsService,
    CustomTagService,
  ],
})
export class SharedModule {}
