import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { SelectSearchComponent } from './select-search.component';

@NgModule({
  declarations: [SelectComponent, SelectSearchComponent],
  imports: [CommonModule, FormsModule, OverlayModule],
  exports: [SelectComponent],
  providers: [],
})
export class SelectModule {}
