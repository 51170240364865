import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { SearchExplorerMiddleware } from '../../services/search-explorer.middleware';
import { Observable } from 'rxjs';

@Component({
  selector: 'playbook-search-explorer-toolbar',
  templateUrl: './search-explorer-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExplorerToolbarComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInputEl', { static: true }) searchInputEl: ElementRef<HTMLInputElement>;
  searchControl = new FormControl(null, Validators.required);
  loading$: Observable<boolean>;

  constructor(private searchExplorerMiddleware: SearchExplorerMiddleware) {}

  clearControl() {
    this.searchControl.reset('');
    setTimeout(() => this.focus(), 300);
  }

  ngOnInit(): void {
    this.onSearchControlChange();
    this.loading$ = this.searchExplorerMiddleware.loading;
  }

  focus() {
    this.searchInputEl.nativeElement.focus();
  }

  ngAfterViewInit() {
    this.focus();
  }

  private onSearchControlChange() {
    this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((query) => {
      if (query && query.length > 0) {
        this.searchExplorerMiddleware.store.setLoading(true);
        this.searchExplorerMiddleware.store.updateSearch({
          payload: {
            query,
          },
        });
      } else {
        this.searchExplorerMiddleware.store.resetSearch();
      }
    });
  }
}
