import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoFocusableDirective } from './autofocus/autofocus.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';

@NgModule({
  declarations: [AutoFocusableDirective, ClickOutsideDirective],
  imports: [CommonModule],
  exports: [AutoFocusableDirective, ClickOutsideDirective],
  providers: [],
})
export class DirectivesModule {}
