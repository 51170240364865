import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class FilesizePipe implements PipeTransform {
  transform(bytes: number): string {
    if (bytes < 1000) {
      return bytes + ' bytes';
    } else if (bytes < 1000 * 1000) {
      return bytes / 1000 + 'KB';
    } else {
      return bytes / 1000 / 1000 + 'MB';
    }
  }
}
