import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode } from '@datorama/akita';
import { AppInjector } from 'app/app.injector';
import * as firebase from 'firebase/app';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
  // Remove when all logs are removed from the code
  // This is to prevent logs on production
  console.log = function () {};
}

firebase.initializeApp(environment.firebase);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
firebase.performance();

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap()
    .then((moduleRef) => {
      AppInjector.setInjector(moduleRef.injector);
      // const appRef = moduleRef.injector.get(ApplicationRef);
      // const compRef = appRef.components[0];
      // // allows to run `ng.profiler.timeChangeDetection();`
      // enableDebugTools(compRef);
    })
    .catch((err) => console.log(err));
}
