import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResultSet, User } from '@playbook/models';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  followUser(id: number) {
    return this.http.post(environment.apiBaseUrl + 'users/' + id + '/follow/', {});
  }

  unfollowUser(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'users/' + id + '/follow/', {});
  }

  getFollowList(id: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'users/' + id + '/follows/');
  }

  getSuggestedUsers() {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'users/get_suggested_users/');
  }

  getCurrent() {
    return this.http.get<User>(environment.apiBaseUrl + 'me/');
  }

  getStats() {
    return this.http.get(environment.apiBaseUrl + 'me/stats/');
  }

  getOperationUser() {
    return this.http.get<any>(environment.apiBaseUrl + 'users/get_operation_users/');
  }
}
