import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country, ResultSet } from '@playbook/models';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class CountryRemote {
  constructor(private http: HttpClient) {}

  fetch() {
    return this.http.get<ResultSet<Country>>(`${environment.apiBaseUrl}countries/`);
  }
}
