import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'playbook-comment-vote',
  styleUrls: ['./comment-vote.component.scss'],
  templateUrl: 'comment-vote.component.html',
})
export class CommentVoteComponent {
  saving = false;
  interval: any;

  @Input() value: String = '';
  @Input() editing = false;
  @Input() criteria;
  @Output() voteCast = new EventEmitter<any>();

  saveVote() {
    this.voteCast.emit(this.value);
  }
}
