import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { Corporation } from '@playbook/models/corporation';
import { Startup } from '@playbook/models/startup';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ToasterService } from 'app/shared/modules/toaster/services/toaster.service';
import { RelationshipDto, Vertical, Office, Relationship } from '@playbook/models';
import { RelationshipService } from '@playbook/modules/relationship-tracking/services/relationship.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { CorporationService } from '@playbook/services/corporation.service';
import { StartupService } from '@playbook/services/startup.service';
import { formatDate } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VerticalService } from '@playbook/modules/verticals/services/vertical.service';
import { OfficeService } from 'app/modules/offices/services/office.service';

@Component({
  selector: 'playbook-relationship-setup',
  templateUrl: './relationship-setup.component.html',
  styleUrls: ['./relationship-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipSetupComponent implements OnInit {
  date = new Date();
  form: FormGroup;
  startDateShow = { day: null, month: null, year: null, monthShow: null };
  corporations$: Observable<Corporation[]>;
  startups$: Observable<Startup[]>;
  verticals$: Observable<Vertical[]>;
  offices$: Observable<Office[]>;

  routeId: string;
  isEditing = false;

  relationship: Relationship;

  selectedCorporation?: object[] = [];

  selectedStartup?: object[] = [];

  private destroy$ = new Subject();

  officeIsSelected = (o1: Office, o2: Office) => {
    return o1.id === o2.id;
  };

  verticalIsSelected = (o1: Vertical, o2: Vertical) => {
    return o1.id === o2.id;
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService,
    private startupService: StartupService,
    private corporationService: CorporationService,
    private relationshipService: RelationshipService,
    @Inject(MAT_DIALOG_DATA) public data: Corporation,
    @Optional() public dialogRef: MatDialogRef<RelationshipSetupComponent>,
    private verticalService: VerticalService,
    private officeService: OfficeService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.verticals$ = this.verticalService.getVerticals();
    this.offices$ = this.officeService.getOffices();
    this.initForm();

    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      this.routeId = paramMap.get('id');
      if (this.routeId) {
        this.isEditing = true;
        this.relationshipService.get(Number(this.routeId)).subscribe((relationship) => {
          this.relationship = relationship;
          this.setFormValue(this.relationship);
        });
      } else {
        this.splitDate(new Date());
      }
    });
  }

  setFormValue(data: any) {
    this.form.patchValue(data);
    this.setObjectValue('tags', 'id');
    if (data.date_added) {
      this.splitDate(data.date_added);
    }
    this.cdr.detectChanges();
  }

  setObjectValue(controlName: any, key: any) {
    const control = this.form.controls[controlName] as FormArray;

    if (!control || !this.relationship[controlName]) {
      return;
    }

    this.clearFormArray(control);

    this.relationship[controlName].forEach((object) => {
      control.push(new FormControl(object[key], [Validators.min(1)]));
    });
  }

  clearFormArray(control: FormArray) {
    control.reset([]);
  }

  initForm() {
    this.form = this.fb.group({
      startup: [{ value: null, disabled: this.isEditing }, [Validators.required]],
      corporation: [{ value: this.data, disabled: this.isEditing }, [Validators.required]],
      date_added: [new Date()],
      verticals: [this.fb.array([]), [Validators.required]],
      offices: [this.fb.array([])],
    });
  }

  splitDate(date) {
    const newDate = new Date(date);
    this.startDateShow.day = newDate.getDate();
    this.startDateShow.month = newDate.getMonth() + 1;
    this.startDateShow.monthShow = formatDate(newDate, 'MMMM', 'en_us');
    this.startDateShow.year = newDate.getFullYear();

    if (this.form) {
      this.form.get('date_added').setValue(newDate);
    }
  }

  createRelationship(value) {
    value.verticals = value.verticals.map((vertical: Vertical) => vertical.id);
    value.offices = value.offices.map((office: Office) => office.id);

    if (!this.relationship) {
      const relationshipData: RelationshipDto = {
        startup: value.startup.id,
        corporation: value.corporation.id,
        verticals: value.verticals,
        offices: value.offices,
        date_added: value.date_added.toISOString(),
      };
      this.relationshipService
        .create(relationshipData)
        .pipe(take(1))
        .subscribe(
          (relationship) => {
            this.toasterService.pop('success', 'Relationship created', 'Relationship was created');
            if (!this.data && !this.dialogRef) {
              this.router.navigate(['/relationships', relationship.id]);
            } else if (this.dialogRef) {
              this.dialogRef.close(true);
            }
          },
          (error) => {
            if (error.error.message === 'Relationship already existing.') {
              this.toasterService.pop('error', 'Could not be created', 'Relationship already existing');
            } else {
              this.toasterService.pop('error', 'Could not be created', 'Relationship could not be created');
            }
          }
        );
    }

    if (this.relationship) {
      const relationshipData: Partial<Relationship> = {
        verticals: value.verticals,
        offices: value.offices,
        date_added: value.date_added.toISOString(),
      };
      this.relationshipService
        .patch(this.relationship.id, relationshipData)
        .pipe(take(1))
        .subscribe(
          (relationship) => {
            this.toasterService.pop('success', 'Relationship created', 'Relationship was created');
            if (!this.data && !this.dialogRef) {
              this.router.navigate(['/relationships', relationship.id]);
            } else if (this.dialogRef) {
              this.dialogRef.close(true);
            }
          },
          (error) => {
            this.toasterService.pop('error', 'Could not be created', 'Relationship could not be created');
          }
        );
    }
  }

  onValueChange(query: string, type: 'corp' | 'startup') {
    switch (type) {
      case 'startup':
        this.startups$ = this.startupService.search(query);
        break;
      case 'corp':
        this.corporations$ = this.corporationService.search(query);
        break;
    }
  }
}
