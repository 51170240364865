import { HttpClient, HttpParams } from '@angular/common/http';
import { ResultSet } from '../models/result-set';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { User } from '@playbook/models';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  constructor(private http: HttpClient) {}

  getAll(page?: number, limit?: number, order?: string) {
    let params = new HttpParams();

    if (!limit) {
      limit = 40;
    }
    params = params.append('limit', String(limit));

    const offset = page * limit;
    params = params.append('offset', String(offset));

    if (order) {
      params = params.append('ordering', order);
    }

    return this.http.get<ResultSet>(`${environment.apiBaseUrl}staff/`, { params: params });
  }

  search(query, page = 0, limit = 40, order?: string) {
    let params = new HttpParams();
    const offset = page * limit;
    params = params.append('limit', String(limit));
    params = params.append('offset', String(offset));
    if (order) {
      params = params.append('ordering', order);
    }
    params = params.append('search', query);

    return this.http.get<ResultSet<User>>(`${environment.apiBaseUrl}staff/`, { params: params });
  }
}
