import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'nl2br',
})
export class Nl2brPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, sanitizeBeforehand?: boolean): string {
    if (typeof value !== 'string') {
      return value;
    }

    let textParsed = value.replace(/(?:\r\n|\r|\n){2}/g, '<br /><br />');
    textParsed = textParsed.replace(/(?:\r\n|\r|\n)/g, '<br />');
    // Let´s sanitize HTML
    const result = this.sanitizer.sanitize(SecurityContext.HTML, textParsed);

    return result;
  }
}
