import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Collection } from '../../../models/collection';
import { User } from '../../../models/user';
import { CollectionService } from '../../../services/collections.service';

@Component({
  selector: 'playbook-strength-indicator',
  templateUrl: './strength-indicator.component.html',
  styleUrls: ['./strength-indicator.component.scss'],
})
export class StrengthIndicatorComponent {
  @Input() strength = 0;

  values = [20, 50, 80];

  getColor() {
    if (this.strength >= 80) {
      return 'var(--var-secondary)';
    } else if (this.strength >= 50) {
      return 'var(--var-mikado)';
    } else {
      return 'var(--var-red-apple)';
    }
  }
}
