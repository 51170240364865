import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SearchExplorerComponent } from '../components/search-explorer/search-explorer.component';
import { SearchExplorerModel } from '../models/search-explorer';

@Injectable({ providedIn: 'root' })
export class SearchExplorerService {
  constructor(private dialog: MatDialog) {}

  open(models?: SearchExplorerModel[]) {
    this.dialog.open(SearchExplorerComponent, {
      closeOnNavigation: true,
      maxHeight: '80%',
      width: '85%',
      maxWidth: '900px',
      minHeight: '50px',
      panelClass: 'pb-dialog-panel-alt',
      position: {
        top: '50px',
      },
      data: {
        models,
      },
    });
  }
}
