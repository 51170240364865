import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GlobalSearchResults, ResultSet } from '@playbook/models';
import { Observable } from 'rxjs';

@Injectable()
export class GlobalSearchService {
  constructor(private http: HttpClient) {}

  /**
   * Perform search
   *
   * @returns Observable<ResultSet>
   */
  search(query: string): Observable<GlobalSearchResults> {
    const httpParams = new HttpParams().set('q', query);
    return this.http.get<GlobalSearchResults>(environment.apiBaseUrl + 'search/', { params: httpParams });
  }

  lookup(query: string) {
    const httpParams = new HttpParams().set('lookup', query);
    return this.http.get<GlobalSearchResults>(environment.apiBaseUrl + 'lookup/', { params: httpParams });
  }

  lookupReturn(query: string) {
    let httpParams = new HttpParams().set('lookup', query);
    httpParams = httpParams.append('return_id', 'true');
    return this.http.get<GlobalSearchResults>(environment.apiBaseUrl + 'lookup/', { params: httpParams });
  }

  generalLookup(query: string, type: string) {
    let httpParams = new HttpParams().set('lookup', query);
    httpParams = httpParams.append('type', type);
    return this.http.get<GlobalSearchResults>(environment.apiBaseUrl + 'general-lookup/', { params: httpParams });
  }
}
