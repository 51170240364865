import { Injectable } from '@angular/core';
import { Vertical } from '@playbook/models';
import { map } from 'rxjs/operators';
import { VerticalQuery } from '../state/vertical.state';
import { VerticalStore } from '../state/vertical.store';
import { VerticalRemote } from './vertical.remote';

@Injectable({ providedIn: 'root' })
export class VerticalService {
  constructor(
    private verticalRemote: VerticalRemote,
    private verticalStore: VerticalStore,
    private verticalQuery: VerticalQuery
  ) {}

  fetchAll() {
    this.verticalRemote
      .fetch()
      .pipe(map((data) => data.results))
      .subscribe((verticals) => this.verticalStore.set(verticals));
  }

  getVerticals() {
    return this.verticalQuery.selectAll();
  }

  getVertical(id: number) {
    return this.verticalQuery.getEntity(id);
  }

  get verticals(): Vertical[] {
    return this.verticalQuery.getAll();
  }

  search(verticalName: string, officeId = null) {
    return this.verticalQuery.selectAll({
      filterBy: [
        (entity) => entity.vertical_name.toLowerCase().includes(verticalName.toLowerCase()),
        (entity) => (officeId ? entity.offices.some((office) => office.id === officeId) : true),
      ],
    });
  }

  addOrUpdateVertical(vertical: Vertical) {
    this.verticalStore.upsert(vertical.id, vertical);
  }

  deleteVertical(verticalId: number) {
    this.verticalStore.remove(verticalId);
  }

  setActive(id: number) {
    this.verticalStore.setActive(id);
  }

  getActive() {
    return this.verticalQuery.getActive();
  }
}
