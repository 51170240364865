import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SearchExtendedInfoComponent } from '@playbook/modules/search/components/search-extended-info/search-extended-info.component';
import { SearchItem } from '@playbook/modules/search/models/search-explorer';
import { ExtendedInfoService } from '@playbook/modules/search/services/extended-info.service';

@Directive({ selector: '[playbookExtendedSearchInfo]' })
export class SearchExtendedInfoDirective implements OnInit {
  @Input('extendedSearchInfo') searchItem: SearchItem = null;

  private hoverTimer;
  private overlayRef: OverlayRef;

  constructor(
    private extendedInfoService: ExtendedInfoService,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      },
    ]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    if (!this.overlayRef.hasAttached()) {
      this.hoverTimer = setTimeout(() => {
        const tooltipPortal = new ComponentPortal(SearchExtendedInfoComponent);
        const tooltipRef: ComponentRef<SearchExtendedInfoComponent> = this.overlayRef.attach(tooltipPortal);
        tooltipRef.instance.searchItem = this.searchItem;
        tooltipRef.instance.portalInstance = this.overlayRef;
        this.extendedInfoService.update(tooltipRef.instance);
      }, 500);
    }
  }

  @HostListener('mouseout')
  hide() {
    clearTimeout(this.hoverTimer);
  }
}
