import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { ButtonItem } from './button-item';

@Component({
  selector: 'playbook-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
})
export class ButtonToggleComponent implements OnInit {
  @Input() owner: 'playbook' | 'corporation';
  @Input() items: ButtonItem[] = [];
  @Input() style: string;
  @Input() disabled = false;
  @Output() button = new EventEmitter<string>();

  ngOnInit() {
    if (this.owner === 'corporation') {
      this.items.forEach((item) => (item.is_active = item === this.items[1]));
      this.button.emit(this.items[1].slug);
    }
  }

  buttonClicked(selectedItem: ButtonItem): boolean {
    if (!this.disabled) {
      this.items.forEach((item) => (item.is_active = item === selectedItem));
      this.button.emit(selectedItem.slug);
      return true;
    }

    return false;
  }
}
