import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../../../models/event';

@Component({
  selector: 'playbook-event-result-card',
  templateUrl: './event-result-card.component.html',
  styleUrls: ['./event-result-card.component.scss'],
})
export class EventResultCardComponent implements OnInit {
  @Input() event: Event;

  constructor() {}

  ngOnInit() {}
}
