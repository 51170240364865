import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Notification, NotificationItem, NotificationType } from '@playbook/models/notification';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NotificationState, NotificationStore } from './notification.store';
import { UserService } from '@playbook/modules/user/services/user.service';
import { Dealflow } from '@playbook/models';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class NotificationQuery extends QueryEntity<NotificationState> {
  constructor(protected store: NotificationStore, private userService: UserService) {
    super(store);
  }

  notifications(): Observable<NotificationItem[]> {
    return this.selectAll({
      filterBy: ({ object, content_object }) => !!object || !!content_object,
    }).pipe(
      map((state) =>
        state.map((notification) => {
          let url: string | null = null;
          let htmlText: string | null = null;
          let iconPaths: string[] | null = null;
          console.log(notification.object_type);
          switch (notification.notification_type) {
            case NotificationType.USER_CREATED:
              htmlText = `Hi ${this.userService.currentUser().full_name}! Welcome to Playbook!`;
              iconPaths = ['M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'];
              break;
            case NotificationType.USER_FOLLOWED:
              htmlText = `${notification.content_object['first_name']} ${notification.content_object['last_name']} followed you`;
              iconPaths = ['M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'];
              url = `/user/${notification.content_object['id']}`;
              break;
            case NotificationType.DEALFLOW_ATTENDEE_ADDED:
              htmlText = `You were added to the dealflow with ${this.getDealflowName(
                notification.content_object
              )} on ${new DatePipe('en-US').transform(notification.content_object['start_date'], 'mediumDate')}`;
              iconPaths = ['M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'];
              url = `/user/${notification.content_object['id']}`;
              break;
            default:
              htmlText = `<span>Hello</span>`;
              iconPaths = ['M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'];
              break;
          }

          return {
            id: notification.id,
            object_id: notification.content_object['id'],
            object_type: notification.object_type,
            date: notification.date,
            notification_type: notification.notification_type,
            read: notification.read,
            seen: notification.seen,
            htmlText,
            url,
            iconPaths,
          };
        })
      )
    );
  }

  pageChanges() {
    return this.select((state) => state.ui.currentPage);
  }

  finalReached() {
    return this.select(({ state }) => state.ui.finalReached);
  }

  pendingNotifications() {
    return this.selectAll({
      filterBy: ({ seen }) => !seen,
    }).pipe(switchMap((notifications) => of(notifications.length)));
  }

  private getDealflowName(dealflow: any) {
    let dfName = dealflow.corporation;
    if (dealflow.group_name) {
      dfName += ' (' + dealflow.group_name + ')';
    }
    return dfName;
  }
}
