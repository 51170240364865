import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'darkenLighten',
})
export class DarkenLightenPipe implements PipeTransform {
  transform(col: any, amt: number): any {
    let usePound = false;

    if (col === false) {
      return false;
    }

    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    const matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
    const match = matchColors.exec(col);
    let num;

    if (match !== null) {
      num = Number(match[1]).toString(16) + Number(match[2]).toString(16) + Number(match[3]).toString(16);
      usePound = true;
    } else {
      num = parseInt(col, 16);
    }

    // tslint:disable-next-line:no-bitwise
    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    // tslint:disable-next-line:no-bitwise
    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    // tslint:disable-next-line:no-bitwise
    let g = (num & 0x0000ff) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    // tslint:disable-next-line:no-bitwise
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }
}
