import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiUrl)) {
      if (!request.url.includes('?') && !request.url.endsWith('/')) {
        request = request.clone({ url: `${request.url}/` });
      }

      request = request.clone({
        withCredentials: true,
      });
    }

    return next.handle(request);
  }
}
