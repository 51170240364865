import { Vertical } from './vertical';
import { User } from './user';

export class Event {
  id: number;
  event_name: string;
  verticals: Vertical[] = [];
  start_date: any;
  end_date: any;
  corporation_access: number;
  startup_access: number;
  updated: Date;
  updated_by: User;
  created: Date;
  created_by: User;
  point_of_contact: User;
  description: string;
  office: any;
  location_address: string;
  destination_info: any;
  office_hour: boolean;
  virtual_option: number;
  meeting_info: any;
  eventbrite_link: string;
  event_date: any;
  collaborators: User[] | number;
}
