import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(element: HTMLElement) {
    const clickOutside = this.elementRef.nativeElement.contains(element);
    if (!clickOutside) {
      this.clickOutside.emit(null);
    }
  }
}
