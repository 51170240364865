import { Vertical } from './vertical';
import { User } from './user';
import { Stage } from './stage';
import { Partnership } from './partnership';
import { CorporationTeamMember } from './corporation-team-member';
import { Tag } from './tag';
import { Office } from './office';
import { BusinessRegion } from './business-region';
import { BusinessScope } from './business-scope';
import { IndustryRank } from './industry-rank';
import { Collection } from './collection';

export interface CorporationCollections {
  shared: Collection[];
  owned: Collection[];
  tied: Collection[];
}

export class Corporation {
  id: number;
  corporation_name: string;
  domain_name: string;
  sub_domains: string[];
  domains: any;
  founded: string;
  revenue: any;
  revenue_usd: any;
  corporation_type: string;
  ceo: string;
  markets: string;
  hq_city: string;
  hq_country: string;
  website: string;
  description: string;
  partnership: string;
  relationship: string;
  // top_interests: Tag[];
  image: string;
  brand_color: string;
  logo: any;
  verticals: any[];
  tags: Tag[];
  account_managers: User[];
  members: User[];
  team_members: CorporationTeamMember[] = [];
  updated: Date;
  created: Date;
  interested_offices: Office[] = [];
  uploaded_by: User;
  business_regions: BusinessRegion[];
  business_scope: BusinessScope;
  industry_rank: IndustryRank;
  parent_corporation: Corporation;
  child_corporations: Corporation[];
  locations: Office[] = [];
  contract_date: string;
  collections?: CorporationCollections;
  relationship_tracker: number;
}
