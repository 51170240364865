import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, EntityState } from '@datorama/akita';
import { Notification } from '@playbook/models/notification';

export interface NotificationState extends EntityState<Notification, number> {
  ui: {
    searching?: boolean;
    perPage?: number;
    currentPage?: number;
    finalReached?: boolean;
  };
}

const initialState = {
  ui: {
    perPage: 40,
    currentPage: 0,
    finalReached: false,
  },
};

@StoreConfig({ name: 'notifications' })
@Injectable({ providedIn: 'root' })
export class NotificationStore extends EntityStore<NotificationState> {
  constructor() {
    super(initialState);
  }

  getUI() {
    return this.getValue().ui;
  }

  updateUI(ui: NotificationState['ui']) {
    this.update((state) => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }
}
