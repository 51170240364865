import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomainsService } from '@playbook/services/domains.service';
import { of } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'playbook-sub-domain-selector',
  templateUrl: './sub-domain-selector.component.html',
  styleUrls: ['./sub-domain-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubDomainSelectorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubDomainSelectorComponent implements ControlValueAccessor {
  mainDomain: string;
  @Input('mainDomain') set mainDomainChange(domain: string) {
    this.mainDomain = domain;
  }
  checkingSubdomain = false;
  isSubdomainAvailable = false;
  validSubdomain: string;

  domainsForm = new FormGroup({
    sub_domain_input: new FormControl(''),
    sub_domains: new FormControl([]),
  });

  private onChange: Function;
  private onTouched: Function;
  private disabled: boolean;

  constructor(private changeDetector: ChangeDetectorRef, private domainService: DomainsService) {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  writeValue(obj: string[]): void {
    this.domainsForm.controls.sub_domains.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.domainsForm.controls.sub_domain_input.valueChanges
      .pipe(
        filter((domain: string) => domain !== undefined),
        tap(() => {
          this.checkingSubdomain = true;
          this.isSubdomainAvailable = false;
          this.validSubdomain = undefined;
        }),
        debounceTime(1000),
        switchMap((domain: string) => {
          const subDomains = this.domainsForm.controls.sub_domains.value || [];
          if (subDomains.some((d: string) => d === domain) || domain === this.mainDomain) {
            return of({ isAvailable: false, domain });
          }
          return this.domainService.checkDomainAvailability(domain).pipe(
            map((isAvailable) => {
              return { isAvailable, domain };
            })
          );
        })
      )
      .subscribe(({ isAvailable, domain }) => {
        this.validSubdomain = isAvailable ? domain : undefined;
        this.isSubdomainAvailable = isAvailable;
        this.checkingSubdomain = false;
        this.changeDetector.detectChanges();
      });
  }

  addSubdomain(): void {
    const domain = this.domainsForm.controls.sub_domain_input.value;
    if (this.validSubdomain === domain) {
      const subDomains = this.domainsForm.controls.sub_domains.value || [];
      if (!subDomains.some((d: string) => d === domain) && domain !== this.mainDomain) {
        subDomains.push(domain);
        this.domainsForm.controls.sub_domains.patchValue(subDomains);
        this.onChange(subDomains);
      }
      this.domainsForm.controls.sub_domain_input.patchValue('');
    }
    this.changeDetector.detectChanges();
  }

  removeSubdomain(subDomain: string, event): void {
    if (event.x === 0 && event.y === 0) {
      // Prevent unexpected behaviour.
      // pressing enter in the input triggers the click event on the first subdomain in list calling this function.
      return;
    }

    let subDomains: string[] = this.domainsForm.controls.sub_domains.value;
    subDomains = subDomains.filter((domain: string) => domain != subDomain);
    this.domainsForm.controls.sub_domains.patchValue(subDomains);
    this.onChange(subDomains);
    this.changeDetector.detectChanges();
  }
}
