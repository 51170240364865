import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@playbook/models';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class UserRemote {
  constructor(private http: HttpClient) {}

  getUser() {
    return this.http.get<User>(`${environment.apiBaseUrl}me/`, { withCredentials: true });
  }

  updateLastLogin(token: string) {
    const params = new HttpParams().set('token', token);
    return this.http.get(`${environment.apiBaseUrl}accounts/signed/`, { params });
  }

  search(query: string, company = null) {
    let params = new HttpParams().set('q', query);
    if (company) {
      params = params.append('company', company);
    }
    return this.http.get<User[]>(`${environment.apiBaseUrl}users/lookup/`, { params });
  }

  signOut() {
    return this.http.post(`${environment.apiBaseUrl}accounts/signout/`, {});
  }

  checkSession() {
    return this.http.get<{ valid: boolean }>(`${environment.apiBaseUrl}accounts/session-status/`, {
      withCredentials: true,
    });
  }
}
