import { Investor } from '../../../../models/investor';
import { Input, Component } from '@angular/core';

@Component({
  selector: 'playbook-investor-result-card',
  templateUrl: './investor-result-card.component.html',
  styleUrls: ['./investor-result-card.component.scss'],
})
export class InvestorResultCardComponent {
  @Input() investor: Investor;

  constructor() {}
}
