import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'luminous',
})
export class LuminousPipe implements PipeTransform {
  transform(color: any): boolean {
    // convert hex to rgb

    if (!color) {
      return false;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    const red = parseInt(result[1], 16);
    const green = parseInt(result[2], 16);
    const blue = parseInt(result[3], 16);

    const luminosity = 0.299 * red + 0.587 * green + 0.114 * blue;

    return luminosity / 255 > 0.68;
  }
}
