import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SearchItem, SearchTab } from '../../models/search-explorer';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { SearchExplorerResultsItemComponent } from '../search-explorer-results-item/search-explorer-results-item.component';
import { ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'playbook-search-explorer-results',
  templateUrl: './search-explorer-results.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExplorerResultsComponent implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport, { static: true }) cdkVirtualScrollViewport!: CdkVirtualScrollViewport;
  @ViewChildren(SearchExplorerResultsItemComponent) items: QueryList<any>;
  @Input() listOfItems: SearchItem[];
  @Input() loading = false;
  @Input() currentTab: SearchTab;
  @Input() singleMode = false;
  @Output() readonly scrollToBottom = new EventEmitter<void>();
  @Output() navigation = new EventEmitter();
  itemSize = 48;
  maxItemLength = 14;
  keyManager: FocusKeyManager<SearchExplorerResultsItemComponent>;
  private scrolledIndex = 0;

  trackValue(_index: number, item: SearchItem) {
    return item.id;
  }

  onScrolledIndexChange(index: number): void {
    this.scrolledIndex = index;
    if (this.canLoadMore(index)) {
      this.scrollToBottom.emit();
    }
  }

  onNavigation() {
    this.navigation.emit();
  }

  onKeydown(e: KeyboardEvent) {
    e.preventDefault();
    if (e.keyCode === ENTER) {
      console.log(this.keyManager.activeItem.item);
    } else {
      this.keyManager.onKeydown(e);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { currentTab } = changes;

    if (!currentTab.previousValue || currentTab.previousValue.key !== currentTab.currentValue.key) {
      this.cdkVirtualScrollViewport.scrollToIndex(0);
    }
  }

  private canLoadMore(index: number) {
    return (
      Math.ceil((index * 100) / (this.listOfItems.length - this.maxItemLength)) > 99 &&
      !this.loading &&
      this.listOfItems.length < this.currentTab.total
    );
  }
}
