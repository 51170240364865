import { Injectable } from '@angular/core';
import { Ability } from '@casl/ability';
import { SearchDto, SearchExplorerModel, SearchResultItem, SearchTab } from '../models/search-explorer';
import { SearchExplorerQuery } from '../state/search-explorer.query';
import { SearchExplorerStore } from '../state/search-explorer.store';
import { SearchExplorerRemote } from './search-explorer.remote';
import { forkJoin, iif, of, Subscription } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { setLoading } from '@datorama/akita';

const DEFAULT_KEYS = [
  {
    key: 'users',
    order: 9000,
  },
  {
    key: 'dealflows',
    order: 7000,
  },
  {
    key: 'events',
    order: 8000,
  },
  {
    key: 'vcs',
    order: 5000,
  },
];

const ALL_KEYS = [
  {
    key: 'startups',
    order: 1000,
  },
  {
    key: 'corporations',
    order: 2000,
  },
  {
    key: 'investors',
    order: 3000,
  },
  {
    key: 'collections',
    order: 4000,
  },
];

@Injectable({ providedIn: 'root' })
export class SearchExplorerMiddleware {
  searchSubscription: Subscription;

  constructor(
    private ability: Ability,
    private searchExplorerRemote: SearchExplorerRemote,
    private searchExplorerStore: SearchExplorerStore,
    private searchExplorerQuery: SearchExplorerQuery
  ) {}

  initializeSearch(models: SearchExplorerModel[]) {
    let keys = [];

    if (models) {
      keys = ALL_KEYS.filter((item) => models.includes(item.key as SearchExplorerModel));
    } else {
      keys = [...DEFAULT_KEYS, ...ALL_KEYS.filter((item) => this.ability.can('list', item.key))].sort(
        (a, b) => a.order - b.order
      );
    }

    this.searchExplorerStore.updateSearch({
      models: keys.map((k) => k.key as SearchExplorerModel),
    });

    return this.searchExplorerQuery.search().pipe(
      switchMap((value) => iif(() => value.payload.query.length === 0, of(null), of(value))),
      switchMap((payload) => (payload ? this.searchExplorerRemote.search(payload) : of(null))),
      tap((data: SearchDto | null) => {
        if (data) {
          const searchDto = this.searchExplorerStore.getSearch();
          const keysWithResults = Object.keys(data).filter((key: string) => data[key].results.length > 0);
          this.searchExplorerStore.update((state) => ({
            ...state,
            ...data,
          }));
          const uiState = this.searchExplorerStore.getUI();
          let selectedTab = keysWithResults[0] as SearchExplorerModel;
          if (uiState.query === searchDto.payload.query) {
            selectedTab = uiState.selectedTab;
          }
          this.searchExplorerStore.updateUI({
            selectedTab,
            query: searchDto.payload.query,
          });
        }
      })
    );
  }

  updateResults(tab: SearchTab) {
    this.searchExplorerStore.updateUI({ selectedTab: tab.key as SearchExplorerModel, page: 1 });
  }

  loadMore() {
    const { selectedTab, page } = this.searchExplorerStore.getUI();
    const search = this.searchExplorerStore.getSearch();

    const payload = {
      models: [selectedTab],
      payload: {
        ...search.payload,
        query: search.payload.query,
        max_results: search.payload.max_results,
        page: page + 1,
      },
    };

    this.searchExplorerStore.updateUI({ loadingMore: true });
    this.searchExplorerRemote.search(payload).subscribe((response) => {
      this.searchExplorerStore.update((state) => {
        return {
          ...state,
          [selectedTab]: {
            ...state[selectedTab],
            results: [...state[selectedTab].results, ...(response as SearchResultItem)[selectedTab].results],
          },
          ui: {
            ...state.ui,
            loadingMore: false,
            page: page + 1,
          },
        };
      });
    });
  }

  get loading() {
    return this.searchExplorerQuery.selectLoading();
  }

  get loadingMore() {
    return this.searchExplorerQuery.selectLoadingMore();
  }

  get tabs() {
    return this.searchExplorerQuery.tabs();
  }

  get results() {
    return this.searchExplorerQuery.results();
  }

  get currentTab() {
    return this.searchExplorerQuery.currentTab();
  }

  get recentViews() {
    return this.searchExplorerQuery.recentViews();
  }

  get recentSearch() {
    return this.searchExplorerQuery.recentSearch();
  }

  removeRecentSearch() {
    this.searchExplorerRemote.removeRecentSearch().subscribe(() => {
      this.searchExplorerStore.updateHistorial({
        recent_searches: [],
      });
    });
  }

  removeRecentView() {
    this.searchExplorerRemote.removeRecentSearch().subscribe(() => {
      this.searchExplorerStore.updateHistorial({
        recent_views: [],
      });
    });
  }

  resetUI() {
    this.searchExplorerStore.resetUI();
  }

  get store() {
    return this.searchExplorerStore;
  }

  get filterEnabled() {
    return this.searchExplorerQuery.filterEnabled();
  }
}
