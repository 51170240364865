import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'playbook-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @Input() background = null;
  @Input() transparent = false;
  @Output() back = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  goBack() {
    this.back.emit(true);
  }
}
