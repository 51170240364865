import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  NgModule,
  ViewChildren,
} from '@angular/core';
import { NgModel, NgForm, FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { OverlayComponent } from '../overlay/overlay.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'playbook-survey-overlay',
  templateUrl: './survey-overlay.component.html',
  styleUrls: ['./survey-overlay.component.scss'],
})
export class SurveyOverlayComponent {
  @Input() survey: any;
  @Output() saveSurvey = new EventEmitter<any>();
  @ViewChild('surveyOverlay', { static: true }) surveyOverlay: OverlayComponent;

  constructor(private fb: FormBuilder, private ngxSpinner: NgxSpinnerService) {}

  openOverlay() {
    this.surveyOverlay.overlayOpen = true;
  }

  closeOverlay() {
    this.surveyOverlay.overlayOpen = false;
  }

  saveMentorSurvey() {
    this.saveSurvey.emit(this.survey);
    this.closeOverlay();
  }
}
