import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchExplorerMiddleware } from '../../services/search-explorer.middleware';
import { SearchExplorerModel } from '../../models/search-explorer';

interface SortValue {
  key: string;
  description: string;
  availableOn: string[];
}

const sortValues: SortValue[] = [
  { key: 'relevance', description: 'Relevance', availableOn: ['all', 'startups'] },
  { key: '-hypescore', description: 'Hypescore', availableOn: ['startups'] },
  { key: '-feedback', description: 'Star Rating', availableOn: ['startups'] },
  { key: '-money_raised', description: 'Money Raised', availableOn: ['startups'] },
  { key: '-created', description: 'Created', availableOn: ['all', 'startups'] },
  { key: '-updated', description: 'Updated', availableOn: ['all', 'startups'] },
];

const defaultSortValues = sortValues.filter((s) => s.availableOn.includes('all'));

@Component({
  selector: 'playbook-search-explorer-options',
  templateUrl: './search-explorer-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExplorerOptionsComponent implements OnInit {
  selectedSort$ = new BehaviorSubject<string>(defaultSortValues[0].description);
  sortOptions$ = new BehaviorSubject(defaultSortValues);

  constructor(private searchExplorerMiddleware: SearchExplorerMiddleware) {}

  toggleFilter() {
    this.searchExplorerMiddleware.store.updateUI({
      filterEnabled: !this.searchExplorerMiddleware.store.getUI().filterEnabled,
    });
  }

  setOrder(sortValue: SortValue) {
    this.selectedSort$.next(sortValue.description);
    this.searchExplorerMiddleware.store.setLoading(true);
    this.searchExplorerMiddleware.store.updateSearch({
      payload: {
        order_by: sortValue.key,
      },
    });
  }

  ngOnInit(): void {
    const { models } = this.searchExplorerMiddleware.store.getSearch();

    if (models.length === 1) {
      this.buildSortList(models);
    }
  }

  private buildSortList(models: SearchExplorerModel[]) {
    const values = sortValues.filter((sort) => sort.availableOn.includes(models[0]));
    this.sortOptions$.next(values);
    this.selectedSort$.next(values[0].description);
  }
}
