import { Component, Input, TemplateRef } from '@angular/core';
import { WeekDay } from 'calendar-utils';

@Component({
  selector: 'playbook-calendar-month-view-header',
  templateUrl: './calendar-month-view-header.component.html',
  styleUrls: ['./calendar-month-view.component.scss'],
})
export class CalendarMonthViewHeaderComponent {
  @Input() days: WeekDay[];

  @Input() locale: string;

  @Input() customTemplate: TemplateRef<any>;
}
