import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attendeeName',
})
export class AttendeeNamePipe implements PipeTransform {
  transform(attendee: any): any {
    if (!!attendee['displayName']) {
      return attendee['displayName'];
    }

    const regex = RegExp(/(\"?([\w\s\(\)-]+)\"?)?\s\<?([\w-_\.]+@[\w\.]{2,})\>?/gim);
    const matches = regex.exec(attendee['email']);

    if (matches === null) {
      return attendee['email'];
    }

    if (!!matches[2]) {
      return matches[2].trim();
    }

    return matches[3].trim();
  }
}
