import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchExplorerEmptyComponent } from './components/search-explorer-empty/search-explorer-empty.component';
import { SearchExplorerComponent } from './components/search-explorer/search-explorer.component';
import { SearchExplorerRecentComponent } from './components/search-explorer-recent/search-explorer-recent.component';
import { SearchExplorerToolbarComponent } from './components/search-explorer-toolbar/search-explorer-toolbar.component';
import { SearchExplorerResultsItemComponent } from './components/search-explorer-results-item/search-explorer-results-item.component';
import { SearchExplorerResultsComponent } from './components/search-explorer-results/search-explorer-results.component';
import { SearchExplorerTabsComponent } from './components/search-explorer-tabs/search-explorer-tabs.component';
import { SearchExplorerInfoComponent } from './components/search-explorer-info/search-explorer-info.component';
import { SearchExplorerFiltersComponent } from './components/search-explorer-filters/search-explorer-filters.component';
import { CoreModule } from '../core/core.module';
import { SearchExplorerOptionsComponent } from './components/search-explorer-options/search-explorer-options.component';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { SearchExtendedInfoComponent } from './components/search-extended-info/search-extended-info.component';
import { SearchExtendedInfoDirective } from '@playbook/modules/search/directives/search-extended-info.directive';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ExtendedInfoService } from '@playbook/modules/search/services/extended-info.service';
import { PipeModule } from '@playbook/pipes/pipes.module';

@NgModule({
  declarations: [
    SearchExplorerComponent,
    SearchExplorerEmptyComponent,
    SearchExplorerRecentComponent,
    SearchExplorerToolbarComponent,
    SearchExplorerTabsComponent,
    SearchExplorerResultsComponent,
    SearchExplorerResultsItemComponent,
    SearchExplorerInfoComponent,
    SearchExplorerFiltersComponent,
    SearchExplorerOptionsComponent,
    SearchExtendedInfoDirective,
    SearchExtendedInfoComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    OverlayModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    PipeModule,
  ],
  exports: [],
  providers: [ExtendedInfoService],
  entryComponents: [SearchExplorerComponent, SearchExtendedInfoComponent],
})
export class SearchExplorerModule {}
