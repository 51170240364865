import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResultSet } from '../../app/models/result-set';

@Injectable()
export class CustomTagService {
  constructor(private http: HttpClient) {}

  editCustomTags(tag: any, companyId, type) {
    if (type === 'corporation') {
      return this.http.patch(environment.apiBaseUrl + 'corporations/' + companyId + '/note-tags/' + tag.id + '/', tag);
    } else {
      return this.http.patch(environment.apiBaseUrl + 'startups/' + companyId + '/note-tags/' + tag.id + '/', tag);
    }
  }

  addCustomTags(tag: any, companyId, type) {
    if (type === 'corporation') {
      return this.http.post(environment.apiBaseUrl + 'corporations/' + companyId + '/note-tags/', tag);
    } else {
      return this.http.post(environment.apiBaseUrl + 'startups/' + companyId + '/note-tags/', tag);
    }
  }

  removeCustomTags(tag: any, companyId, type) {
    if (type === 'corporation') {
      return this.http.delete(environment.apiBaseUrl + 'corporations/' + companyId + '/note-tags/' + tag.id + '/');
    } else {
      return this.http.delete(environment.apiBaseUrl + 'startups/' + companyId + '/note-tags/' + tag.id + '/');
    }
  }

  getCustomTags(companyId, type) {
    if (type === 'corporation') {
      return this.http.get<any>(environment.apiBaseUrl + 'corporations/' + companyId + '/note-tags/');
    } else {
      return this.http.get<any>(environment.apiBaseUrl + 'startups/' + companyId + '/note-tags/');
    }
  }
}
