import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultSet, Vertical } from '@playbook/models';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class VerticalRemote {
  constructor(private http: HttpClient) {}

  fetch() {
    return this.http.get<ResultSet<Vertical>>(`${environment.apiBaseUrl}verticals/`);
  }
}
