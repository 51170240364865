import { Component, Output, Input, EventEmitter, HostListener, AfterContentInit } from '@angular/core';

@Component({
  selector: 'playbook-drop-down-list',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent {
  listOpen = false;
  @Input() chosenItem: any = false;
  private stopProp = false;
  @Input() isMobile: Boolean = false;
  @Input() isChoice = true;
  @Input() disableAll = false;
  @Input() displayProperty = 'name';
  @Input() items: any[];
  @Input() defaultCurrentTitle = '';
  @Input() paddingOption1 = false;
  @Input() dropDownOption1 = false;
  @Input() dropdownBodyOption1 = false;
  @Input() timezone = false;
  @Input() disabled = false;
  @Input() valueProperty = null;
  @Input() filter = false;
  @Output() item = new EventEmitter<any>();

  chooseItem(item) {
    if (!!this.isChoice) {
      this.chosenItem = item;
    }

    this.listOpen = false;

    if (this.valueProperty !== null) {
      this.item.emit(item[this.valueProperty]);
    } else {
      this.item.emit(item);
    }
  }

  toggleList() {
    if (!this.disabled) {
      this.listOpen = !this.listOpen;
    }
  }

  setTitle(title: string) {
    const item = {};
    item[this.displayProperty] = title;
    this.chosenItem = item;
  }

  setTitleDefault() {
    this.setTitle(this.defaultCurrentTitle);
  }

  clearSelection() {
    this.chosenItem = false;
    this.item.emit(null);
    this.listOpen = false;
  }

  @HostListener('body:touchend', ['$event'])
  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }

    this.listOpen = false;
  }

  @HostListener('touchend', ['$event'])
  @HostListener('click', ['$event'])
  clicked(event) {
    this.stopProp = event.currentTarget.contains(event.srcElement);
  }
}
