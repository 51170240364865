import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'playbook-star-vote',
  styleUrls: ['./star-vote.component.scss'],
  templateUrl: 'star-vote.component.html',
})
export class StarVoteComponent implements AfterContentInit, OnChanges {
  saving = false;
  ratingRange = [];
  ratingName = '';
  hoverRating: number = null;

  @Input() value: number = null;
  @Input() criteria;
  @Output() voteCast = new EventEmitter<any>();

  constructor() {
    for (let i = 1; i < 6; i++) {
      this.ratingRange.push(i);
    }
  }

  ngAfterContentInit() {
    this.resetRatingName();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.resetRatingName();
    }
  }

  showRatingName(rating: number) {
    this.ratingName = this.criteria.criteria_options.score_labels[rating - 1];
    this.hoverRating = rating;
  }

  resetRatingName() {
    this.hoverRating = null;

    if (this.value !== null) {
      this.showRatingName(this.value);
    } else {
      this.ratingName = '';
    }
  }

  saveVote(value) {
    this.value = +value;
    this.showRatingName(this.value);
    this.voteCast.emit(this.value);
  }

  hoverClass(rating) {
    if (this.hoverRating !== null) {
      return this.hoverRating >= rating;
    }

    return this.value >= rating;
  }
}
