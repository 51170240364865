import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Vertical } from '@playbook/models';
import { VerticalState, VerticalStore } from './vertical.store';

@Injectable({ providedIn: 'root' })
export class VerticalQuery extends QueryEntity<VerticalState, Vertical, number> {
  constructor(protected store: VerticalStore) {
    super(store);
  }
}
