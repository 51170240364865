import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[playbookElementInfiniteScroll]',
})
export class ElementInfiniteScrollDirective {
  @Input() scrollThreshold = 100;
  @Input() disabled = false;
  @Input() reverse = false;
  @Output() scrollThresholdReached = new EventEmitter<boolean>();

  constructor(private el: ElementRef) {}

  @HostListener('scroll')
  didScroll() {
    if (this.disabled === true) {
      return;
    }

    if (this.reverse === false) {
      const threshold = this.el.nativeElement.scrollHeight - this.scrollThreshold;
      const currentPosition = this.el.nativeElement.clientHeight + this.el.nativeElement.scrollTop;

      if (currentPosition > threshold) {
        this.scrollThresholdReached.emit(true);
      }
    } else {
      if (this.el.nativeElement.scrollTop < this.scrollThreshold) {
        this.scrollThresholdReached.emit(true);
      }
    }
  }
}
