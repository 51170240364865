import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[playbookFileDrop]',
})
export class FileDropDirective {
  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() filesHovered = new EventEmitter<boolean>();

  @HostListener('drop', ['$event'])
  onDrop($event: any): void {
    $event.preventDefault();
    this.filesHovered.emit(false);
    this.filesDropped.emit($event.dataTransfer.files);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: any): void {
    $event.preventDefault();
    this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: any): void {
    this.filesHovered.emit(false);
  }
}
