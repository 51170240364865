import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Collection } from '../models/collection';
import { environment } from '../../environments/environment';
import { ResultSet } from '../models/result-set';
import { Collaborator } from '../models/collaborator';
import { Voter } from '../models/voter';
import { Filter } from '../models/filter';
import { CollectionStartup } from '../models/collection-startup';
import { CollectionStartupFilter } from '../models/collection-startup-filter';
import { Cached, InvalidateCache } from '../modules/cache/cache.module';
import { CollectionFilter } from '../models/collection-filter';

@Injectable()
export class CollectionService {
  constructor(private http: HttpClient) {}

  @Cached('collections')
  getRecent() {
    const httpParams = new HttpParams().set('ordering', '-created');
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/', { params: httpParams });
  }

  @Cached('collections')
  getStartupRelated() {
    const httpParams = new HttpParams().set('ordering', '-created');
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/has_startup/', { params: httpParams });
  }

  getMostLiked(tagSelected, ballotFilter) {
    let httpParams = new HttpParams().set('ordering', '-likes_count');

    if (tagSelected != null && tagSelected.length !== 0) {
      httpParams = httpParams.append('tags', String(tagSelected));
    }

    if (ballotFilter !== '') {
      httpParams = httpParams.append('ballot_count', String(ballotFilter));
    }
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/', { params: httpParams });
  }

  getVerticalSelectionStartupIds(collectionId: number) {
    return this.http.get<any>(environment.apiBaseUrl + 'collections/' + collectionId + '/vertical_startups/');
  }

  @Cached('collections')
  getRecentUpdated(filter?: CollectionFilter, lite = false) {
    let params = new HttpParams().set('ordering', '-updated');
    if (lite) {
      params = params.append('lite', 'true');
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/', {
      params: this.parseFilter(params, filter),
    });
  }

  @Cached('collections')
  getRecentCreated(filter?: CollectionFilter, lite = false) {
    let params = new HttpParams().set('ordering', '-created');
    if (lite) {
      params = params.append('lite', 'true');
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/', {
      params: this.parseFilter(params, filter),
    });
  }

  @Cached('collections')
  getNextRecentCreated(cursor: string) {
    return this.http.get<ResultSet>(cursor);
  }

  @Cached('collections:user')
  getUserCollections(userId) {
    const params = new HttpParams().set('ordering', '-created');

    return this.http.get<Collection[]>(environment.apiBaseUrl + 'users/' + userId + '/collections/', {
      params: params,
    });
  }

  @Cached('collections')
  getOwnCollectionsFiltered(tagSelected, ballotFilter) {
    let httpParams = new HttpParams().set('ordering', '-created');
    if (tagSelected != null && tagSelected.length !== 0) {
      httpParams = httpParams.append('tags', String(tagSelected));
    }
    if (ballotFilter !== '') {
      httpParams = httpParams.append('ballot_count', ballotFilter);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/own/', { params: httpParams });
  }

  @Cached('collections')
  getOwnCollections(tagSelected, edit?: boolean) {
    let httpParams = new HttpParams().set('ordering', '-created');
    if (tagSelected != null && tagSelected.length !== 0) {
      httpParams = httpParams.append('tags', String(tagSelected));
    }

    if (edit) {
      httpParams = httpParams.append('edit', 'true');
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/own/', { params: httpParams });
  }

  @Cached('collection:tags')
  getCollectionTags() {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/tags/');
  }

  @Cached('collections')
  get(collectionId) {
    return this.http.get<Collection>(environment.apiBaseUrl + 'collections/' + collectionId + '/');
  }

  @InvalidateCache('collections')
  delete(collectionId) {
    return this.http.delete(environment.apiBaseUrl + 'collections/' + collectionId + '/');
  }

  @Cached('collections')
  getSuggestedStartups(collectionId) {
    return this.http.get<any>(environment.apiBaseUrl + 'collections/' + collectionId + '/suggest/');
  }

  @Cached('collections')
  getSuggestedCollections(collectionId) {
    return this.http.get<any>(environment.apiBaseUrl + 'collections/' + collectionId + '/related/');
  }

  @InvalidateCache('collection:startups')
  addStartupToCollection(collectionId, startupId, position) {
    return this.http.post(environment.apiBaseUrl + 'collections/' + collectionId + '/startups/', {
      startup: startupId,
      position: position,
    });
  }

  @InvalidateCache('collections')
  create(collection: any) {
    return this.http.post<Collection>(environment.apiBaseUrl + 'collections/', collection);
  }

  @InvalidateCache('collections')
  update(collection: Collection) {
    return this.http.patch<Collection>(environment.apiBaseUrl + 'collections/' + collection.id + '/', collection);
  }

  search(terms, order) {
    let httpParams = new HttpParams().set('ordering', order);
    httpParams = httpParams.append('search', terms);
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/', { params: httpParams });
  }

  @Cached('collection:startups')
  getStartups(collectionId: number, page?: number) {
    if (!page) {
      page = 1;
    }

    const params = new HttpParams().set('page', String(page));

    return this.http.get<ResultSet>(`${environment.apiBaseUrl}collections/${collectionId}/startups/`, {
      params: params,
    });
  }

  @Cached('collection:startups')
  getPdfStartups(collectionId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/' + collectionId + '/startups/pdf/');
  }

  @InvalidateCache('collections')
  addToTracker(collectionId: number, corporationId: number) {
    return this.http.post(environment.apiBaseUrl + 'collections/' + collectionId + '/add_to_tracker/', {
      corporation_id: corporationId,
    });
  }

  @Cached('collection:startup')
  getStartup(collectionId: number, startupId: number) {
    return this.http.get<CollectionStartup>(
      environment.apiBaseUrl + 'collections/' + collectionId + '/startups/' + startupId + '/'
    );
  }

  @Cached('collection:startup')
  deleteStartupFromCollection(collectionId: number, startupId: number) {
    return this.http.delete(environment.apiBaseUrl + 'collections/' + collectionId + '/startups/' + startupId + '/');
  }

  @InvalidateCache('collection:startups')
  reorderStartup(collectionId: number, itemId: number, index: number) {
    return this.http.put(environment.apiBaseUrl + 'collections/' + collectionId + '/startups/reorder/', {
      collection_startup_id: itemId,
      to_index: index,
    });
  }

  collectionLikeCreate(collection: any, user: number) {
    const data = { user: user, collection: collection };
    return this.http.post<any>(environment.apiBaseUrl + 'collections-like/?collection=' + collection, data);
  }

  collectionLikeDelete(collection: number) {
    return this.http.delete<any>(environment.apiBaseUrl + 'collections/' + collection + 'like/');
  }

  @InvalidateCache('collections')
  @InvalidateCache('collection:startups')
  copy(collectionId: number, targetIds?: number[]) {
    let payload = {};

    if (targetIds) {
      payload = {
        target_collection_ids: targetIds,
      };
    }

    return this.http.post(environment.apiBaseUrl + 'collections/' + collectionId + '/copy/', payload);
  }

  getVoters(collectionId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/' + collectionId + '/ballot/voters/');
  }

  getCollaborators(collectionId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'collections/' + collectionId + '/collaborators/');
  }

  addCollaborator(collectionId: number, userId: number) {
    return this.http.post<Collaborator>(environment.apiBaseUrl + 'collections/' + collectionId + '/collaborators/', {
      collaborator: userId,
    });
  }

  addVoter(collectionId: number, userId: number) {
    return this.http.post<Voter>(environment.apiBaseUrl + 'collections/' + collectionId + '/ballot/voters/', {
      user: userId,
    });
  }

  addCorporationAsVoters(collectionId: number, corporationId: number) {
    return this.http.post<Voter[]>(
      environment.apiBaseUrl + 'collections/' + collectionId + '/ballot/voters/corporation/',
      { corporation: corporationId }
    );
  }

  setCollaboratorPermission(collectionId: number, collaboratorId: number, permission: string) {
    return this.http.patch<Collaborator>(
      environment.apiBaseUrl + 'collections/' + collectionId + '/collaborators/' + collaboratorId + '/',
      { permission: permission }
    );
  }

  removeVoter(collectionId: number, voterId) {
    return this.http.delete(environment.apiBaseUrl + 'collections/' + collectionId + '/ballot/voters/' + voterId + '/');
  }

  removeCollaborator(collectionId: number, collaboratorId) {
    return this.http.delete(
      environment.apiBaseUrl + 'collections/' + collectionId + '/collaborators/' + collaboratorId + '/'
    );
  }

  like(collection: number) {
    return this.http.post<any>(environment.apiBaseUrl + 'collections/' + collection + '/likes/', {});
  }

  unlike(collection: number) {
    return this.http.delete<any>(environment.apiBaseUrl + 'collections/' + collection + '/likes/');
  }

  addFilter(collectionId: number, filter: Filter) {
    return this.http.post<Filter>(environment.apiBaseUrl + 'collections/' + collectionId + '/filters/', {
      filter_name: filter.filter_name,
    });
  }

  updateFilter(collectionId: number, filter: Filter) {
    return this.http.patch<Filter>(
      environment.apiBaseUrl + 'collections/' + collectionId + '/filters/' + filter.id + '/',
      { filter_name: filter.filter_name }
    );
  }

  deleteFilter(collectionId: number, filterId: number) {
    return this.http.delete(environment.apiBaseUrl + 'collections/' + collectionId + '/filters/' + filterId + '/');
  }

  addFilterToStartup(collection: Collection, collectionStartup: CollectionStartup, filterId: number) {
    return this.http.post<CollectionStartupFilter>(
      environment.apiBaseUrl + 'collections/' + collection.id + '/startups/' + collectionStartup.id + '/filters/',
      { collection_filter: filterId }
    );
  }

  removeFilterFromStartup(collection: Collection, collectionStartup: CollectionStartup, filterId: number) {
    return this.http.delete<CollectionStartupFilter>(
      environment.apiBaseUrl +
        'collections/' +
        collection.id +
        '/startups/' +
        collectionStartup.id +
        '/filters/' +
        filterId +
        '/'
    );
  }

  addCriteria(collectionId: number, criteria: any) {
    return this.http.post<any>(environment.apiBaseUrl + 'collections/' + collectionId + '/ballot/criteria/', criteria);
  }

  editCriteria(collectionId: number, criteria: any) {
    return this.http.patch<any>(
      environment.apiBaseUrl + 'collections/' + collectionId + '/ballot/criteria/' + criteria.id + '/',
      criteria
    );
  }

  removeCriteria(collectionId: number, criteria: any) {
    return this.http.delete<any>(
      environment.apiBaseUrl + 'collections/' + collectionId + '/ballot/criteria/' + criteria.id + '/'
    );
  }

  getCriteria(collectionId: number) {
    return this.http.get<any>(environment.apiBaseUrl + 'collections/' + collectionId + '/ballot/criteria/');
  }

  @InvalidateCache('collections')
  createUsingStartups(collectionName: string, startupIds: number[]) {
    const payload = {
      startup_ids: startupIds,
      collection_name: collectionName,
    };

    return this.http.post<Collection>(environment.apiBaseUrl + 'collections/create_using_startups/', payload);
  }

  @InvalidateCache('collections')
  changeOwner(collectionId: number, newOwnerId: number) {
    return this.http.post<any>(environment.apiBaseUrl + 'collections/' + collectionId + '/change_owner/', {
      new_owner_id: newOwnerId,
    });
  }

  uploadFile(collectionId: number, file: FormData) {
    return this.http.post(environment.apiBaseUrl + 'collections/' + collectionId + '/file/', file, {
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteFile(collectionId: number, fileId: number) {
    return this.http.delete(environment.apiBaseUrl + 'collections/' + collectionId + '/file/' + fileId + '/');
  }

  downloadFile(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  private parseFilter(params: HttpParams, filter?: CollectionFilter) {
    if (!filter) {
      return params;
    }
    params = params.append('filter', 'true');
    params = params.append('collection_type', filter.collection_type);
    if (filter.verticals) {
      params = params.append(
        'verticals',
        filter.verticals instanceof Array ? filter.verticals.join() : filter.verticals
      );
    }
    if (filter.people) {
      params = params.append('people', filter.people instanceof Array ? filter.people.join() : filter.people);
    }
    params = params.append('batch_only', String(filter.batch_only));
    params = params.append('owner', filter.owner);
    return params;
  }
}
