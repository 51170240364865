import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResultSet } from '../../models/result-set';
import { environment } from '../../../environments/environment';
import { GlobalSearchResults } from '../../models/global-search-result';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationService {
  constructor(private http: HttpClient) {}

  /**
   * Get user notifications
   *
   * @returns Observable<ResultSet>
   */
  get(cursor?: string) {
    if (cursor) {
      return this.http.get<ResultSet>(cursor).pipe(
        map((results) => {
          results.results = results.results.filter((notification) => {
            return !!notification.object;
          });

          return results;
        })
      );
    }

    return this.http
      .get<ResultSet>(environment.apiBaseUrl + 'notifications/', {
        headers: { ignoreLoadingBar: '' },
      })
      .pipe(
        map((results) => {
          results.results = results.results.filter((notification) => {
            return !!notification.object;
          });

          return results;
        })
      );
  }

  seen() {
    return this.http.post(environment.apiBaseUrl + 'notifications/seen/', {});
  }

  markAsRead(id: number) {
    return this.http.post(environment.apiBaseUrl + 'notifications/' + id + '/read/', {});
  }

  markAllAsRead() {
    return this.http.post(environment.apiBaseUrl + 'notifications/all_read/', {});
  }
}
