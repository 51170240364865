import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'playbook-slide-toggle',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleComponent),
      multi: true,
    },
  ],
  templateUrl: './slide-toggle.component.html',
})
export class SlideToggleComponent implements ControlValueAccessor {
  isChecked = false;
  onChange = (value: boolean) => {};
  onTouched = () => {};

  @HostListener('click', ['$event'])
  onHostClick(evt: MouseEvent) {
    evt.preventDefault();
    this.updateValue(!this.isChecked);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  updateValue(value: boolean): void {
    if (this.isChecked !== value) {
      this.isChecked = value;
      this.onChange(this.isChecked);
    }
  }

  writeValue(value: boolean): void {
    this.isChecked = value;
    this.cdr.markForCheck();
  }
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
