import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'playbook-drag-drop-file-upload',
  templateUrl: './drag-drop-file-upload.component.html',
  styleUrls: ['./drag-drop-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragDropFileUploadComponent {
  @Input() multiple = false;

  allowedExtensions: string[];
  acceptExtensions: string;
  @Input('allowedExtensions') set setAllowedExtensions(extensions: string[]) {
    this.allowedExtensions = extensions;
    this.acceptExtensions = extensions.map((ext) => `.${ext},`).reduce((ext1, ext2) => ext1 + ext2);
  }

  @Input() error: string;

  files: File[];
  url: string;
  @Input('files') set filesInput(files: File[]) {
    this.files = files;
    this.showImageFile(files);
    this.droppedFiles.emit(this.files);
  }
  @Input() showImage = false;

  @Output() droppedFiles = new EventEmitter<File[]>();

  dropzoneActive = false;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;

  constructor(private changeDetector: ChangeDetectorRef) {}

  // open the file browser
  browse(): void {
    if (!this.files || this.files.length === 0) {
      // reset the fileInput value to empty the file list to make sure
      // the next selection of the user is going to be taken into account
      this.fileInput.nativeElement.value = '';
      this.fileInput.nativeElement.click();
    }
  }

  inputChange(inputFiles: FileList): void {
    const files = this.getValidFiles(inputFiles);

    if (!this.multiple && files.length > 1) {
      // TODO Visual feedback
      console.warn(`can't drop more than one file`);
      return;
    }

    this.showImageFile(files);

    this.files = files;
    this.droppedFiles.emit(this.files);
  }

  private showImageFile(files: File[]): void {
    if (this.showImage && !this.multiple) {
      if (files.length === 0) {
        this.url = undefined;
        this.changeDetector.detectChanges();
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          this.url = reader.result as string;
          this.changeDetector.detectChanges();
        };
        reader.readAsDataURL(files[0]);
      }
    }
  }

  getValidFiles(inputFiles: FileList): File[] {
    const files = [];
    for (let i = 0; i < inputFiles.length; i++) {
      const file = inputFiles.item(i);
      if (this.checkExtension(file)) {
        files.push(file);
      } else {
        // TODO Visual Feedback
        console.warn(`Incorrect file extension: ${file.name}`);
      }
    }
    return files;
  }

  checkExtension(file: File): boolean {
    if (!this.allowedExtensions || this.allowedExtensions.length === 0) {
      return true;
    }

    const fileExtension = file.name.split('.').pop();
    return this.allowedExtensions.some((extension: string) => extension.toLowerCase() === fileExtension.toLowerCase());
  }

  dropzoneState(event: boolean): void {
    this.dropzoneActive = event;
  }

  removeFile(file: File): void {
    this.url = undefined;
    this.files = this.files.filter((f: File) => f.name !== file.name);
    this.droppedFiles.emit(this.files);
  }
}
