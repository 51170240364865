import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Country } from '@playbook/models';

export interface CountryState extends EntityState<Country, number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'countries' })
export class CountryStore extends EntityStore<CountryState, Country, number> {
  constructor() {
    super();
  }
}
