import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { RecentSearch, RecentView, SearchDto, SearchExplorerModel, SearchResult } from '../models/search-explorer';

const MAX_RESULTS = 50;

export interface SearchExplorerState extends SearchResult {
  ui: {
    page?: number;
    selectedTab?: SearchExplorerModel;
    loadingMore?: boolean;
    query?: string;
    filterEnabled?: boolean;
  };
  historial: {
    recent_searches?: RecentSearch[];
    recent_views?: RecentView[];
  };
  search?: SearchDto;
}

const initialState: SearchExplorerState = {
  ui: {
    selectedTab: null,
    loadingMore: false,
    filterEnabled: false,
    page: 1,
  },
  historial: {
    recent_searches: [],
    recent_views: [],
  },
  search: {
    payload: {
      order_by: 'relevance',
      query: '',
      max_results: MAX_RESULTS,
    },
  },
};

@StoreConfig({ name: 'search', resettable: true })
@Injectable({ providedIn: 'root' })
export class SearchExplorerStore extends Store<SearchExplorerState> {
  constructor() {
    super(initialState);
  }

  getUI() {
    return this.getValue().ui;
  }

  getHistorial() {
    return this.getValue().historial;
  }

  getSearch() {
    return this.getValue().search;
  }

  updateUI(ui: SearchExplorerState['ui']) {
    this.update((state) => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateHistorial(historial: SearchExplorerState['historial']) {
    this.update((state) => ({
      historial: {
        ...state.historial,
        ...historial,
      },
    }));
  }

  updateSearch(search: SearchExplorerState['search']) {
    this.update((state) => ({
      search: {
        ...state.search,
        ...search,
        payload: {
          ...state.search.payload,
          ...search.payload,
        },
      },
    }));
  }

  resetUI() {
    this.update((state) => ({
      ...initialState,
      historial: state.historial,
    }));
  }

  resetSearch() {
    this.update((state) => ({
      ...state,
      ui: {
        ...initialState.ui,
      },
      search: {
        ...state.search,
        payload: {
          max_results: MAX_RESULTS,
          query: '',
          order_by: 'relevance',
        },
      },
    }));
  }
}
