import {
  Component,
  Input,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'playbook-object-chooser',
  templateUrl: './object-chooser.component.html',
  styleUrls: ['./object-chooser.component.scss'],
})
export class ObjectChooserComponent implements AfterViewInit, OnChanges {
  showResults = false;
  private stopProp = false;
  displayObjects = [];
  searchTimeout = null;

  @Input() autoFocusEnabled = false;
  @Input() objects: any[] = [];
  @Input() objectsId: any[] = [];
  @Input() displayValue = 'name';
  @Input() selectedObjects: any[] = [];
  @Input() selectedObject: any = null;
  @Input() selecting: string;
  @Input() trackChanges = false;
  @Input() error = false;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() resetOnSelect = false;
  @Input() newControl = false;
  @Input() grabChanges = false;
  @Input() addRole = false;
  @Input() attendeeList: any;
  @Output() search = new EventEmitter<string>();
  @ViewChild('objectSearch', { static: false }) objectSearch;
  @Output() returnSingle = new EventEmitter<any>();
  @Output() changesMade = new EventEmitter<any>();
  @Output() changeType = new EventEmitter<string>();
  @Output() selectionChanges = new EventEmitter<any[]>();

  ngAfterViewInit() {
    if (this.selecting === 'single' && this.objectSearch) {
      if (this.selectedObject) {
        this.objectSearch.nativeElement.value = this.selectedObject[this.displayValue];
      } else {
        this.objectSearch.nativeElement.value = '';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedObjects && this.selecting !== 'single') {
      this.filterObjects();
    }

    if (changes.objects && !!changes.objects.currentValue) {
      this.displayObjects = changes.objects.currentValue.filter(() => true);
    }

    if (changes.objects && this.selecting !== 'single') {
      this.filterObjects();
    }

    if (this.selecting === 'single' && this.objectSearch) {
      if (this.selectedObject) {
        this.objectSearch.nativeElement.value = this.selectedObject[this.displayValue];
      } else {
        this.objectSearch.nativeElement.value = '';
      }
    }
  }

  filterObjects() {
    if (this.displayValue === '') {
      const objects = this.selectedObjects.map((obj) => {
        return obj;
      });
      this.displayObjects = this.objects.filter((object) => {
        return !objects.includes(object);
      });
    } else {
      if (this.selectedObjects) {
        const objectIds = this.selectedObjects.map((obj) => {
          return obj.id;
        });
        if (this.objects) {
          this.displayObjects = this.objects.filter((object) => {
            return !objectIds.includes(object.id);
          });
        }
      }
    }
  }

  emitSearch(value) {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.search.emit(value);
      this.searchTimeout = null;
    }, 250);
  }

  clearSearch() {
    this.objectSearch.nativeElement.value = '';
  }

  isDisabled() {
    return this.disabled;
  }

  selectObject(object) {
    if (this.selecting === 'multiple') {
      if (this.newControl) {
        this.selectedObjects.push(new FormControl(object));
      } else {
        this.selectedObjects.push(object);
      }
      this.filterObjects();
      this.clearSearch();
      this.emitSearch('');
      this.showResults = false;
    } else {
      this.returnSingle.emit(object);

      if (!this.resetOnSelect) {
        if (this.displayValue === '') {
          this.objectSearch.nativeElement.value = object;
        } else {
          this.objectSearch.nativeElement.value = object[this.displayValue];
        }
      } else {
        this.objectSearch.nativeElement.value = '';
      }

      this.showResults = false;
    }
    this.emitObjects();

    if (this.trackChanges && this.addRole) {
      if (this.attendeeList.findIndex((data) => data.attendee.id === object.id) === -1) {
        this.changeType.emit('add');
        this.changesMade.emit(object);
      } else {
        this.changeType.emit('edit');
        this.changesMade.emit(object);
      }
    } else if (this.trackChanges && !this.addRole) {
      this.changesMade.emit(object);
    }
  }

  deselectObject(object) {
    if (!this.disabled) {
      const objectIndex = this.selectedObjects.indexOf(object);
      this.selectedObjects.splice(objectIndex, 1);
      this.filterObjects();

      if (this.trackChanges && this.grabChanges) {
        this.changeType.emit('remove');
        this.changesMade.emit(object);
      } else {
        this.changesMade.emit();
      }
    }
    this.emitObjects();
  }

  emitObjects() {
    if (this.selecting === 'multiple') {
      this.selectionChanges.emit(this.selectedObjects);
    } else {
      this.selectionChanges.emit(this.selectedObject);
    }
  }

  resetSingle() {
    this.selectedObject = null;
    this.objectSearch.nativeElement.value = '';
  }

  @HostListener('body:click', ['$event'])
  bodyClicked(event) {
    if (this.stopProp === true) {
      this.stopProp = false;
      return;
    }

    this.showResults = false;
  }

  @HostListener('click', ['$event'])
  clicked(event) {
    this.stopProp = event.currentTarget.contains(event.srcElement);
  }
}
