import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultSet } from '../../../models/result-set';
import { NotificationService } from '../notifications.service';
import { take } from 'rxjs/operators';

@Injectable()
export class NotificationResolver implements Resolve<ResultSet> {
  constructor(private notificationService: NotificationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ResultSet> {
    return this.notificationService.get().pipe(take(1));
  }
}
