import { map } from 'rxjs/operators';
import { Component, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'playbook-media-upload',
  templateUrl: 'media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
})
export class MediaUploadComponent {
  @Output() image = new EventEmitter<string>();
  @Output() imageId = new EventEmitter<number>();
  @Output() error = new EventEmitter<any>();
  @Output() progress = new EventEmitter<number | boolean>();

  private upload: Subscription;

  constructor(private http: HttpClient) {}

  uploadFile(files) {
    this.error.emit(false);

    const file = files[0];
    const reader = new FileReader();
    const self = this;

    if (file.size > 1 * 1000 * 1000) {
      this.error.emit({ message: 'File is too large.' });
      return;
    }

    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (acceptedTypes.includes(file.type) === false) {
      this.error.emit({ message: 'Please upload only a jpeg or png file.' });
      return;
    }

    reader.onload = function (event) {
      self.image.emit(reader.result as any);
    };

    reader.readAsDataURL(file);

    const fd = new FormData();
    if (files[0].name !== undefined) {
      fd.append('url', file);
    } else {
      fd.append('url', file, 'logo.png');
    }

    this.upload = this.http
      .post(environment.apiBaseUrl + 'media/', fd, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            this.progress.emit(percentDone);
          } else if (event.type === HttpEventType.Response) {
            this.progress.emit(false);
            this.imageId.emit(event.body.id);
          }
        },
        (error) => {
          this.progress.emit(false);
          this.error.emit({ error: error, message: 'There was a problem uploading your file.' });
        }
      );
  }

  downloadFile(url) {
    // TODO: Review code
    return this.http
      .get(url, {
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return {
            filename: 'filename.pdf',
            data: res,
          };
        })
      )
      .subscribe((res) => {
        const file_list = [];
        file_list.push(res.data);
        this.uploadFile(file_list);
      });
  }
}
