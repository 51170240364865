import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Stage } from '@playbook/models';

export interface StageState extends EntityState<Stage, number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'stages' })
export class StageStore extends EntityStore<StageState, Stage, number> {
  constructor() {
    super();
  }
}
