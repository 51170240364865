import { Component, Input, HostListener, EventEmitter, Output, OnInit } from '@angular/core';
import { Event } from '../../../models/event';

@Component({
  selector: 'playbook-event-card',
  styleUrls: ['./event-card.component.scss'],
  templateUrl: './event-card.component.html',
})
export class EventCardComponent implements OnInit {
  verticals: any;
  @Input() event: Event = null;
  @Input() isMobile: Boolean = false;

  showPreview = false;

  ngOnInit() {
    this.verticals = this.event.verticals;
  }
}
