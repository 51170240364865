import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserManager } from '../../services/user-mananger.service';

@Directive({
  selector: '[playbookPermission]',
})
export class PermissionDirective {
  hasView = false;

  constructor(
    private userManager: UserManager,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input('user')
  @Input()
  set playbookPermission(value: string | any[]) {
    let currentUser;
    let permission;

    if (value instanceof Array) {
      currentUser = value[0];
      permission = value[1];
    } else {
      currentUser = this.userManager.currentUser;
      permission = value;
    }

    if (permission instanceof Array) {
      let hasPermission = false;

      permission.forEach((perm) => {
        if (currentUser && currentUser.hasPermission(perm) === true) {
          hasPermission = true;
        }
      });

      if (hasPermission) {
        if (this.hasView === false) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        }
      } else {
        this.viewContainer.clear();
        this.hasView = false;
      }

      return;
    }

    if (currentUser && currentUser.hasPermission(permission)) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
