import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { MonthViewDay, CalendarEvent } from 'calendar-utils';
import { CollectionStartupsResolver } from '../../../modules/collections/resolvers/collection-startups-resolver';

@Component({
  selector: 'playbook-calendar-month-view-cell',
  templateUrl: './calendar-month-view-cell.component.html',
  styleUrls: ['./calendar-month-view.component.scss'],
  /* tslint:disable */
  host: {
    class: 'cal-cell cal-day-cell',
    '[class.cal-past]': 'day.isPast',
    '[class.cal-today]': 'day.isToday',
    '[class.cal-future]': 'day.isFuture',
    '[class.cal-weekend]': 'day.isWeekend',
    '[class.cal-in-month]': 'day.inMonth',
    '[class.cal-out-month]': '!day.inMonth',
    '[class.cal-has-events]': 'day.events.length > 0',
    '[class.cal-open]': 'day === openDay',
    '[style.backgroundColor]': 'day.backgroundColor',
  },
  /* tsling:enable */
})
export class CalendarMonthViewCellComponent {
  @Input() day: MonthViewDay;

  @Input() openDay: MonthViewDay;

  @Input() locale: string;

  @Input() tooltipPlacement: string;

  @Input() tooltipAppendToBody: boolean;

  @Input() customTemplate: TemplateRef<any>;

  @Input() tooltipTemplate: TemplateRef<any>;

  @Output() switchToWeekEvent = new EventEmitter<string>();

  @Output() highlightDay: EventEmitter<any> = new EventEmitter();

  @Output() unhighlightDay: EventEmitter<any> = new EventEmitter();

  @Output()
  eventClicked: EventEmitter<{ event: CalendarEvent }> = new EventEmitter<{
    event: CalendarEvent;
  }>();

  onEventClick(mouseEvent: MouseEvent, calendarEvent: CalendarEvent): void {
    if (mouseEvent.stopPropagation) {
      mouseEvent.stopPropagation();
    }
    this.eventClicked.emit({ event: calendarEvent });
  }

  init() {
    /*
      this.day.events.forEach(element => {
        const tmpAgenda = []
        element['agenda'].forEach(each => {
          if (each.agenda_date) {
            const dateParsed = each.agenda_date.split('-');
            if ((this.day.date.getFullYear() === parseInt(dateParsed[0],10)) && (this.day.date.getMonth() === (dateParsed[1] - 1)) && (this.day.date.getDate() === parseInt(dateParsed[2],10))) {
              if (each.start_time && each.end_time) {
                tmpAgenda.push(each.agenda_type + ': ' + this.parseTime(each.start_time) + ' - ' + this.parseTime(each.end_time));
              }
            }
          }
        });
        element['tooltip'] = this.formatAgenda(tmpAgenda);
      });*/
  }

  parseTime(time) {
    let parsedTime = time.split('T');
    parsedTime = parsedTime[1].split(':');
    return this.guaranteeTwoDigit(parsedTime[0]) + ':' + this.guaranteeTwoDigit(parsedTime[1]);
  }

  guaranteeTwoDigit(time) {
    return ('0' + time).slice(-2);
  }

  formatAgenda(agenda) {
    let returnString = '';
    let i = 0;
    agenda.forEach((element) => {
      if (i != 0) {
        returnString += '<br>';
      }
      returnString += JSON.stringify(element);
      i++;
    });
    return returnString;
  }

  goToWeekView() {
    this.switchToWeekEvent.emit();
  }
}
