import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResultSet } from '../models/result-set';
import { Cached, InvalidateCache } from '../modules/cache/cache.module';

@Injectable()
export class SaveSearchService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all saved searches from the API.
   *
   * @returns Observable<ResultSet>
   */
  @Cached('savedsearch')
  getAll(searchType: string) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'me/saved-searches/', {
      params: { search_type: searchType },
    });
  }

  /**
   * Creates a saved search
   *
   * @returns Observable<ResultSet>
   */
  @InvalidateCache('savedsearch')
  create(searchType: string, filters: any) {
    const payload = {
      search_type: searchType,
      search_filters: filters,
    };

    return this.http.post<any>(environment.apiBaseUrl + 'me/saved-searches/', payload);
  }

  @InvalidateCache('savedsearch')
  updateName(id: number, name: string) {
    const payload = {
      name: name,
    };

    return this.http.patch<any>(environment.apiBaseUrl + 'me/saved-searches/' + id + '/', payload);
  }

  /**
   * Deletes the saved search
   *
   * @returns Observable<ResultSet>
   */
  @InvalidateCache('savedsearch')
  delete(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'me/saved-searches/' + id + '/');
  }
}
