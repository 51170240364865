import { User } from './user';
import { Startup } from './startup';
import { Tag } from './tag';
import { Filter } from './filter';
import { Batch } from './batch';
import { Vertical } from './vertical';
import { File } from './file';
import { Corporation } from './corporation';
import { Ballot } from './ballot';

export enum CollectionType {
  CUSTOM = 1,
  SELECTION_DAY,
  TOP_LIST,
  BATCH,
}

export interface CollectionPurpose {
  id?: number;
  description: string;
}
export class Collection {
  ballot: Ballot;
  batch: Batch;
  collaborators: User[] | number;
  collection_file: File;
  collection_name: string;
  collection_type: CollectionType;
  created: Date;
  description: string;
  filters: Filter[];
  id: number;
  is_ballot: boolean;
  is_private: boolean;
  liked: boolean;
  likes_count: number;
  owner: User;
  permission: string[];
  selection_vertical: Vertical;
  startup_count: number;
  startups: Startup[];
  tags: Tag[];
  updated: Date;
  purposes: CollectionPurpose[];
  verticals: Vertical[];
  company_id: number;
  company_object: Corporation | any;
  votes_count: number;
}
