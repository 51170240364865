import { Component, Input, HostListener, EventEmitter, Output, OnInit } from '@angular/core';
import { Dealflow } from '../../../models/dealflow';
import { DealflowPreviewService } from '../../../modules/dealflow/dealflow-preview.service';

@Component({
  selector: 'playbook-dealflow-card',
  styleUrls: ['./dealflow-card.component.scss'],
  templateUrl: './dealflow-card.component.html',
})
export class DealflowCardComponent implements OnInit {
  verticals: any;
  @Input() dealflow: Dealflow = null;
  @Input() hasPreview: Boolean = true;
  @Input() isMobile: Boolean = false;
  @Input() loading: Boolean = false;
  @Output() preview = new EventEmitter<Dealflow>();

  showPreview = false;

  constructor(private dealflowPreviewService: DealflowPreviewService) {}

  ngOnInit() {
    if (this.dealflow) {
      this.verticals = this.dealflow.vertical;
    }
  }

  openPreview(e) {
    if (this.hasPreview === false) {
      return true;
    }

    this.dealflowPreviewService.openPreview(this.dealflow);
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  @HostListener('mouseover')
  makePreviewVisible() {
    this.showPreview = true;
  }

  @HostListener('mouseout')
  makePreviewInvisible() {
    this.showPreview = false;
  }
}
