import { Injectable, Injector } from '@angular/core';
import { UserService } from '@playbook/modules/user/services/user.service';
import { environment } from 'environments/environment';
import 'firebase/auth';
import 'firebase/performance';
import { CookieService } from 'ngx-cookie-service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AppInitializerService {
  constructor(private injector: Injector, private cookieService: CookieService) {}

  async loadUser() {
    const userService = this.injector.get(UserService);
    return new Promise((resolve, reject) => {
      return userService
        .checkSession()
        .pipe(switchMap(() => userService.getUser()))
        .subscribe(
          () => {
            resolve();
          },
          () => {
            const { url, redirectUri, clientId } = environment.authentication;
            const state = window.location.pathname + window.location.search;
            const stateParam = state ? `&state=${btoa(state)}` : '';
            window.location.replace(`${url}?clientId=${clientId}&redirectUri=${redirectUri}${stateParam}`);
            reject();
          }
        );
    });
  }
}
