import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'playbook-onboarding',
  styleUrls: ['./onboarding.component.scss'],
  templateUrl: './onboarding.component.html',
})
export class OnboardingComponent {
  currentStep = 0;
  hidden = false;
  steps = [
    { title: 'Feed', description: 'Browse updates related to your company and the wider ecosystem.' },
    { title: 'Discover', description: 'Search and explore the startups disrupting and improving your industry.' },
    {
      title: 'Collections',
      description:
        'Create, explore, and collaborate on playlists of startups to streamline processes and find new companies.',
    },
    {
      title: 'Dealflow',
      description: 'Organize matchmaking sessions tailored to your focus areas and technology interests.',
    },
    { title: 'Dashboard', description: 'Get real-time insights into the ecosystem and trends in your industry.' },
  ];

  @Output() onboardingClose = new EventEmitter<string>();

  nextPage() {
    this.currentStep++;

    if (this.currentStep === this.steps.length) {
      this.hidden = true;
      this.onboardingClose.emit();
    }
  }

  hide(e: Event) {
    if (e.currentTarget === e.srcElement) {
      this.hidden = true;
      this.onboardingClose.emit();
    }
  }
}
