import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResultSet } from '@playbook/models';
import { environment } from 'environments/environment';
import { Notification } from '@playbook/models/notification';

@Injectable({ providedIn: 'root' })
export class NotificationRemote {
  constructor(private http: HttpClient) {}

  fetch(page: number, limit: number) {
    const params = new HttpParams()
      .set('limit', `${limit}`)
      .set('offset', `${page * limit}`)
      .set('ordering', '-date');
    return this.http.get<ResultSet<Notification>>(`${environment.apiBaseUrl}notifications/`, {
      headers: { ignoreLoadingBar: '' },
      params,
    });
  }

  seen() {
    return this.http.post(environment.apiBaseUrl + 'notifications/seen/', {});
  }

  markAsRead(id: number) {
    return this.http.post(environment.apiBaseUrl + 'notifications/' + id + '/read/', {});
  }

  markAllAsRead() {
    return this.http.post(environment.apiBaseUrl + 'notifications/all_read/', {});
  }
}
