export enum NotificationType {
  BALLOT_CREATED = 'BC',
  BALLOT_VOTER_ADDED = 'BV',
  COLLECTION_COLLABORATOR_ADDED = 'CC',
  CORPORATION_ACCOUNT_MANAGER_REQUEST = 'CM',
  CORPORATION_TEAM_ADDED = 'CT',
  CORPORATION_UPDATED = 'CU',
  DEALFLOW_ATTENDEE_ADDED = 'DA',
  DEALFLOW_INVITATION = 'DI',
  DEALFLOW_REVIEW = 'DR',
  DEALFLOW_STATE_UPDATED = 'DS',
  EVENT_CREATED = 'EC',
  EVENT_INVITED = 'EI',
  NOTE = 'NT',
  NOTE_TAG_CORPORATION = 'TC',
  NOTE_TAG_STARTUP = 'TS',
  OFFICE_HOUR_CREATED = 'OC',
  OFFICE_HOUR_INVITED = 'OI',
  SMART_ALERT_STARTUP_TRIGGER = 'SS',
  SMART_ALERT_TRIGGER = 'SA',
  SMART_COLLECTION_ADDED = 'SA',
  STARTUP_ACCOUNT_MANAGER_REQUEST = 'SM',
  STARTUP_CONFIRM = 'SC',
  STARTUP_DECLINE = 'SD',
  STARTUP_PORTFOLIO_MANAGER_ADDED = 'PM',
  STARTUP_TEAM_ADDED = 'ST',
  STARTUP_UNCONFIRM = 'SN',
  STARTUP_UPDATED = 'SU',
  STARTUPS_REVIEWED = 'SR',
  USER_CREATED = 'UC',
  USER_FOLLOWED = 'UF',
}

export interface Notification {
  id: number;
  content_object: any;
  date: Date;
  extra: string;
  notification_type: NotificationType;
  object: unknown;
  object_type: string;
  read: boolean;
  seen: boolean;
}

export interface NotificationItem {
  id: number;
  object_id: number;
  object_type: string;
  date: Date;
  read: boolean;
  seen: boolean;
  notification_type: NotificationType;
  htmlText: string;
  url: string;
  iconPaths: string[];
}
