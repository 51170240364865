import { Injectable } from '@angular/core';
import { Dealflow } from '../../models/dealflow';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DealflowPreviewService {
  dealflow: Observable<Dealflow>;
  private _dealflow: Observer<Dealflow>;

  constructor(private http: HttpClient) {
    this.dealflow = new Observable<Dealflow>((observer) => {
      this._dealflow = observer;
    });
  }

  openPreview(dealflow: Dealflow) {
    this._dealflow.next(dealflow);
  }

  get(id: number) {
    return this.http.get<Dealflow>(environment.apiBaseUrl + 'dealflow/' + id + '/');
  }
}
