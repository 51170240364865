import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import * as Stickyfill from 'stickyfilljs';

@Directive({
  selector: '[playbookSticky]',
})
export class StickyPolyFillDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    Stickyfill.addOne(this.elementRef.nativeElement);
  }
}
