import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Media } from '../../models/media';
import { ResultSet } from '../../models/result-set';
import { Startup, ProfileStrengthValue, StartupStatus } from '../../models/startup';
import { Cached, InvalidateCache } from '../cache/cache.module';
import { StartupInvitation } from './models/startup-invitation';
import { CheckListItem } from './models/checklist-item';
import { ProfileStrengthChecklistGroup } from './models/profile-strength-checklist-group';
import { ProfileStrengthItem } from './models/profile-strength-item';
import { User } from '@playbook/models';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { UserQuery } from '../user/state/user.query';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class StartupService {
  constructor(private http: HttpClient, private analytics: AngularFireAnalytics, private userQuery: UserQuery) {}

  @Cached('startups')
  getAll(page?: number, limit = 40, order?: string): Observable<ResultSet> {
    let params = new HttpParams().set('limit', `${limit}`).set('offset', `${page * limit}`);

    if (order) {
      params = params.set('ordering', order);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/', { params });
  }

  getAwaitingCompletion(page = 0, limit = 40): Observable<ResultSet> {
    const params = new HttpParams()
      .set('limit', `${limit}`)
      .set('offset', `${page * limit}`)
      .set('awaiting_completion', '1');
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/', { params });
  }

  get(id: number): Observable<Startup> {
    return this.http.get<Startup>(environment.apiBaseUrl + 'startups/' + id + '/');
  }

  delete(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'startups/' + id + '/');
  }

  create(startup: Startup): Observable<Startup> {
    return this.http.post<Startup>(environment.apiBaseUrl + 'startups/', startup);
  }

  inviteStartup(startup: StartupInvitation): Observable<Startup> {
    const formData = new FormData();
    if (startup.startup_details.logo) {
      formData.set('logo', startup.startup_details.logo, startup.startup_details.logo.name);
    }
    formData.set('startup', JSON.stringify(startup));
    return this.http.post<Startup>(environment.apiBaseUrl + 'startup-invites/invite/', formData);
  }

  edit(originalStartup: Startup, startup: any) {
    return this.http.patch<Startup>(environment.apiBaseUrl + 'startups/' + startup.id + '/', startup);
  }

  searchFilter(predicates: object, sort: string, page?: number, dealflow?) {
    let params = new HttpParams();

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];
      if (value && value.length > 0) {
        params = params.set(key, `${value}`);
      }
    });

    if (sort && sort.length > 0) {
      params = params.set('ordering', sort);
    }

    if (page) {
      const limit = 40;
      params = params
        .set('limit', `${limit}`)
        .set('offset', `${page * limit}`)
        .set('page', `${page}`);
    }

    if (dealflow) {
      params = params.set('corporation_id', `${dealflow.corporation.id}`);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/', { params });
  }

  follow(id: number) {
    return this.http.put(environment.apiBaseUrl + 'startups/' + id + '/follow/', {});
  }

  unfollow(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'startups/' + id + '/follow/', {});
  }

  doesFollow(id: number) {
    return this.http.get(environment.apiBaseUrl + 'startups/' + id + '/follows/', {});
  }

  @InvalidateCache('collections')
  @InvalidateCache('collection:startups')
  addCollections(startupId: number, collectionIds: number[]) {
    return this.http.post(environment.apiBaseUrl + 'startups/' + startupId + '/add_collections/', {
      collections: collectionIds,
    });
  }

  getTeamMembers(startupId: number): Observable<ResultSet> {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/' + startupId + '/team/');
  }

  getPortfolioManagers(startupId: number): Observable<ResultSet> {
    return this.http.get<ResultSet>(`${environment.apiBaseUrl}startups/${startupId}/portfolio-managers/`);
  }

  addPortfolioManager(startupId: number, userId: number) {
    return this.http.post<any>(`${environment.apiBaseUrl}startups/${startupId}/portfolio-managers/`, { user: userId });
  }

  removePortfolioManager(startupId: number, portfolioManagerId: number) {
    return this.http.delete<any>(
      `${environment.apiBaseUrl}startups/${startupId}/portfolio-managers/${portfolioManagerId}/`
    );
  }

  setPortfolioManagers(startupId: number, userIds: number[]) {
    return this.http.post<any>(
      `${environment.apiBaseUrl}startups/${startupId}/portfolio-managers/`,
      userIds.map((userId) => ({ user: userId }))
    );
  }

  getCollections(startupId: number, page?: number, limit = 40): Observable<ResultSet> {
    let params = new HttpParams().set('limit', `${limit}`);
    if (page) {
      params = params.set('offset', `${page * limit}`);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/' + startupId + '/collections/', { params });
  }

  getDealflows(startupId: number, page?: number, limit = 40) {
    let params = new HttpParams();
    if (page) {
      params = params.set('offset', `${page * limit}`);
    }

    if (limit !== 0) {
      params = params.set('limit', `${limit}`);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/' + startupId + '/dealflows/', { params });
  }

  getFiles(startupId: number): Observable<ResultSet> {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/' + startupId + '/files/');
  }

  uploadFile(startupId: number, formData: FormData) {
    return this.http.post(environment.apiBaseUrl + 'startups/' + startupId + '/files/', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateFileName(startupId: number, fileId: number, filename: string) {
    const url = environment.apiBaseUrl + 'startups/' + startupId + '/files/' + fileId + '/';
    return this.http.patch(url, { name: filename });
  }

  deleteFile(startupId: number, fileId: number) {
    return this.http.delete(environment.apiBaseUrl + 'startups/' + startupId + '/files/' + fileId + '/');
  }

  advancedSearch(query, ordering?, page?: number, limit = 40, dealflow?): Observable<ResultSet> {
    let params = new HttpParams();

    query.terms.forEach((term) => {
      if (term.term.trim().length === 0) {
        return;
      }
      params = params.set('search', term.field + ':' + term.term);
    });

    Object.keys(query.filters).forEach((key) => {
      if (query.filters[key] === null) {
        return;
      }

      let rawKey = key;
      const operators = ['eq', 'ne', 'gt', 'lt'];

      operators.forEach((operator) => {
        if (key.includes(`__${operator}`)) {
          rawKey = key.replace(`__${operator}`, '');
        }
      });

      if (!!query.filters[key].forEach) {
        if (rawKey === 'countries') {
          query.filters.countries.forEach((country) => {
            params = params.set('country', country.country_code.toLowerCase());
          });
        } else {
          query.filters[key].forEach((el) => {
            params = params.set(`${key}`, `${el.id}`);
          });
        }
      } else if (rawKey === 'portfolio' && query.filters['portfolio'] !== null) {
        params = params.set(`${key}`, `${Boolean(query.filters[key])}`);
      } else if (query.filters[key] !== null) {
        params = params.set(`${key}`, `${query.filters[key]}`);
      }

      if (rawKey === 'time_period') {
        const updatedDate = new Date();
        updatedDate.setDate(updatedDate.getDate() - query.filters.time_period.days);
        let month: any = updatedDate.getMonth() + 1;

        if (month < 10) {
          month = '0' + month;
        }

        let date: any = updatedDate.getDate() + 1;

        if (date < 10) {
          date = '0' + date;
        }

        params = params.set('updated', updatedDate.getFullYear() + '-' + month + '-' + date);
      }
    });

    if (query.location) {
      params = params.set('location', query.location);
    }

    if (query.dist) {
      params = params.set('dist', query.dist);
    }

    if (ordering) {
      params = params.set('ordering', ordering);
    }

    let offset = 0;

    if (page) {
      offset = page * limit;
    }

    if (dealflow) {
      params = params.set('corporation_id', dealflow.corporation.id);
    }
    params = params.set('limit', `${limit}`).set('offset', `${offset}`);

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/search/', { params });
  }

  batches(startupId: number): Observable<ResultSet> {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/' + startupId + '/batches/');
  }

  setClosed(startupId: number, closed: boolean): Observable<Startup> {
    return this.http.patch<Startup>(environment.apiBaseUrl + 'startups/' + startupId + '/', {
      is_closed: closed,
    });
  }

  searchDomain(predicates: object, sort: string) {
    let params = new HttpParams();

    Object.keys(predicates).forEach((key) => {
      const value = predicates[key];

      if (value == null || value.length === 0) {
        return;
      }

      params = params.set(key, predicates[key]);
    });

    if (sort && sort.length > 0) {
      params = params.set('ordering', sort);
    }

    return this.http.get<any>(environment.apiBaseUrl + 'domains/search/', { params });
  }

  getStartupDetails(domain: string): Observable<ResultSet> {
    return this.http.post<ResultSet>(environment.apiBaseUrl + 'enrichment/clearbit/', { domain: domain });
  }

  getCrunchbaseData(domain: string) {
    const params = new HttpParams().set('domain', domain);
    return this.http.get(environment.apiBaseUrl + 'enrichment/crunchbase/', { params });
  }

  getCrunchbaseLogo(domain: string): Observable<Media> {
    const params = new HttpParams().set('domain', domain);
    return this.http.get<Media>(environment.apiBaseUrl + 'enrichment/crunchbase_logo/', { params });
  }

  resendInvite(startupId: number): Observable<ResultSet> {
    return this.http.post<any>(environment.apiBaseUrl + 'startups/' + startupId + '/resend_invite/', {});
  }

  getUserRelations(startupId: number): Observable<ResultSet> {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/' + startupId + '/user-relations/');
  }

  getFunding(startupId: number) {
    return this.http.get<any>(environment.apiBaseUrl + 'startups/' + startupId + '/funding/');
  }

  getEvents(startupId: number): Observable<ResultSet> {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/' + startupId + '/events/');
  }

  getOfficeHours(startupId: number, page?: number, limit = 40): Observable<ResultSet> {
    let params = new HttpParams().set('limit', `${limit}`);
    if (page) {
      params = params.set('offset', `${page * limit}`);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'startups/' + startupId + '/office-hours/', { params });
  }

  getOpportunities(startupId: number, page?: number): Observable<ResultSet> {
    const limit = 40;
    const offset = page ? page * limit : 0;

    const params = new HttpParams().set('limit', `${limit}`).set('offset', `${offset}`);
    return this.http.get<ResultSet>(`${environment.apiBaseUrl}startups/${startupId}/opportunities/`, { params });
  }

  getStartups(id: number, searchText?: string, page?: number, limit?: number): Observable<ResultSet> {
    let params = new HttpParams();

    if (searchText) {
      params = params.set('search', searchText);
    }

    if (!page) {
      page = 0;
    }

    if (limit) {
      const offset = page * limit;
      params = params.set('offset', `${offset}`).set('limit', `${limit}`);
    }

    return this.http.get<ResultSet>(environment.apiBaseUrl + 'tags/' + id + '/startups/', { params });
  }

  search(query: string): Observable<ResultSet<Startup>> {
    const params = new HttpParams().set('q', query);
    return this.http.get<ResultSet<Startup>>(`${environment.apiBaseUrl}startups/lookup/`, { params });
  }

  getStartupProfileStrengthChecklist(startup: Startup): CheckListItem[] {
    if (!startup) {
      return [];
    }

    const checklistItems = [];
    // Name
    checklistItems.push({ label: 'Name', valid: !!startup.company_name });
    // Website
    checklistItems.push({ label: 'Website', valid: !!startup.website });
    // Logo
    checklistItems.push({ label: 'Logo', valid: !!startup.logo && !!startup.logo.id });
    // City
    checklistItems.push({ label: 'City', valid: !!startup.hq_city });
    // Country
    checklistItems.push({ label: 'Country', valid: !!startup.hq_country });
    // Team Members
    checklistItems.push({
      label: 'Team Members',
      valid: startup.team_members && startup.team_members.length > 0,
    });
    // Founded Date
    checklistItems.push({ label: 'Founded Date', valid: !!startup.founded });
    // Stage
    checklistItems.push({ label: 'Stage', valid: !!startup.stage });
    // Investors
    checklistItems.push({ label: 'Investors', valid: !!startup.investors });
    // Last Funding Round
    checklistItems.push({ label: 'Last Funding Round', valid: !!startup.latest_investment });
    // Pitch Deck or Demo
    checklistItems.push({ label: 'Pitch Deck or Demo', valid: !!startup.youtube_video_url });
    // Competitive Advantage
    checklistItems.push({ label: 'Competitive Advantage', valid: !!startup.advantage });
    // Use Cases
    checklistItems.push({ label: 'Use Cases', valid: !!startup.case_study });
    // Competitors
    checklistItems.push({ label: 'Competitors', valid: !!startup.competitors });
    // Founders Background
    checklistItems.push({ label: 'Founders Background', valid: !!startup.background });
    // Updated within the last 3 months
    const millisecondsSinceLastUpdate = new Date().getTime() - new Date(startup.updated).getTime();
    // there are 7776000000 ms in 90 days (1000 * 60 * 60 * 24 * 90)
    checklistItems.push({
      label: 'Updated within the last 3 months',
      valid: millisecondsSinceLastUpdate < 7776000000,
    });

    return checklistItems;
  }

  updateStartupStatus(startup: Startup, status: number): Observable<void> {
    return this.http
      .put<void>(`${environment.apiBaseUrl}startups/${startup.id}/update_status/`, { status })
      .pipe(
        tap(() => {
          const analyticsParams = {
            user_id: this.userQuery.currentUser.id,
            startup_id: startup.id,
          };
          switch (status) {
            case StartupStatus.CLAIMED:
              this.analytics.logEvent('startup_onboarding_claim', analyticsParams);
              break;
            case StartupStatus.PENDING_VERIFICATION:
              this.analytics.logEvent('startup_onboarding_verification_request', analyticsParams);
              break;
            case StartupStatus.VERIFIED:
              this.analytics.logEvent('startup_onboarding_verification', analyticsParams);
              break;
          }
        })
      );
  }

  getProfileStrengthGroups(profileValues: ProfileStrengthValue[]): ProfileStrengthChecklistGroup[] {
    const groupsMap = new Map<string, ProfileStrengthItem[]>();
    for (const value of profileValues) {
      if (!groupsMap.has(value.group)) {
        groupsMap.set(value.group, []);
      }
      groupsMap.get(value.group).push({
        name: value.title,
        valid: value.valid,
      });
    }
    const groups = [];
    groupsMap.forEach((items, name) => {
      groups.push({
        name,
        complete: items.every((i) => i.valid),
        items,
      });
    });
    return groups;
  }

  getProfileStrengthStatus(
    groups: ProfileStrengthChecklistGroup[]
  ): 'Incomplete' | 'Beginner' | 'Intermediate' | 'Advanced' | 'Professional' {
    let status: 'Incomplete' | 'Beginner' | 'Intermediate' | 'Advanced' | 'Professional' = 'Incomplete';
    for (const group of groups) {
      if (group.complete) {
        status = group.name;
      } else {
        break;
      }
    }
    return status;
  }

  getStartupLogo(logoUrl: string, fileName = 'logo.png'): Observable<File> {
    return this.http
      .post(
        environment.apiBaseUrl + 'startup-invites/get_logo/',
        {
          url: logoUrl,
        },
        { responseType: 'arraybuffer' }
      )
      .pipe(
        map((arraybuffer: ArrayBuffer) => {
          const blob = new Blob([arraybuffer], { type: 'image/png' });
          return new File([blob], fileName, { type: blob.type });
        })
      );
  }
}
