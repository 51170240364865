import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Office } from '@playbook/models';

export interface OfficeState extends EntityState<Office, number> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'offices' })
export class OfficeStore extends EntityStore<OfficeState, Office, number> {
  constructor() {
    super();
  }
}
